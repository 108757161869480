.thule-container,
.thule-container--full-width.skip-mobile {
  padding-left: 2rem;
  padding-right: 2rem;

  .row {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .row > * {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (max-width: @screen-md-max) {
    &.full-width-in-mobile {
      width: 100vw;
      max-width: none;
      padding-left: 0;
      padding-right: 0;
      .row {
        margin-left: 0rem;
        margin-right: 0rem;
        & > * {
          padding-left: 0rem;
          padding-right: 0rem;
        }
      }
    }
  }
}

.thule-container--full-width {
  width: 100%;
  @media screen and (max-width: @screen-md-max) {
    &:not(.skip-mobile) {
      .row {
        margin-left: -2rem;
        margin-right: -2rem;
      }

      .row > * {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
}

@media screen and (min-width: @screen-lg) {
  .thule-container {
    padding-left: calc(3%);
    padding-right: calc(3%);

    .row {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    .row > * {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    &--full-width {
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      .row {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }

      .row > * {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
