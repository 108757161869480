////////////////////////////////////////////////////////////////////////////////////
// Background colors
////////////////////////////////////////////////////////////////////////////////////

//LEGACY background colors
.bg-color--white {
  background-color: #fff;
}

.bg-color--black {
  background-color: @Black;

  a {
    color: @White;
  }

  .breadcrumbs-fade--left {
    background: linear-gradient(to right, rgba(24, 24, 24, 1), rgba(24, 24, 24, 0.5));
  }

  .breadcrumbs-fade--right {
    background: linear-gradient(to left, rgba(24, 24, 24, 1), rgba(24, 24, 24, 0.5));
  }
}

.bg-color--thule-light-grey {
  background-color: @Border;
}

.bg-color--thule-lightest-grey {
  background-color: @Background;
}

//legacy end

//UX COLORS
.bg-color--gray10 {
  background-color: @Gray10;

  p,
  button,
  a {
    color: @Black;
  }

  a:hover,
  button:hover {
    text-decoration: none;
    color: @Gray80;
  }

  i[class*='le-icon-'] {
    background: @Black;
  }
}

.bg-color--gray90 {
  background-color: @Gray90;

  li,
  p,
  button,
  a,
  span {
    color: @White;
  }

  a:hover,
  button:hover {
    text-decoration: none;
    color: @Gray20;
  }

  i[class*='le-icon-'] {
    background: @White;
  }
}

.bg-color--success100 {
  background-color: @Success100 !important;
}

.bg-color--family-life {
  background-color: @FamilyLife;

  .breadcrumbs-fade--left {
    background: linear-gradient(to right, rgba(189, 197, 193, 1), rgba(189, 197, 193, 0.5));
  }

  .breadcrumbs-fade--right {
    background: linear-gradient(to left, rgba(189, 197, 193, 1), rgba(189, 197, 193, 0.5));
  }
}

.bg-color--bike {
  background-color: @Bike;

  .breadcrumbs-fade--left {
    background: linear-gradient(to right, rgba(203, 200, 189, 1), rgba(203, 200, 189, 0.5));
  }

  .breadcrumbs-fade--right {
    background: linear-gradient(to left, rgba(203, 200, 189, 1), rgba(203, 200, 189, 0.5));
  }
}

.bg-color--hike {
  background-color: @Hike;

  .breadcrumbs-fade--left {
    background: linear-gradient(to right, rgba(206, 195, 182, 1), rgba(206, 195, 182, 0.5));
  }

  .breadcrumbs-fade--right {
    background: linear-gradient(to left, rgba(206, 195, 182, 1), rgba(206, 195, 182, 0.5));
  }
}

.bg-color--active-with-dog {
  background-color: @ActiveWithDog;

  .breadcrumbs-fade--left {
    background: linear-gradient(to right, rgba(173, 186, 179, 1), rgba(173, 186, 179, 0.5));
  }

  .breadcrumbs-fade--right {
    background: linear-gradient(to left, rgba(173, 186, 179, 1), rgba(173, 186, 179, 0.5));
  }
}

.bg-color--travel {
  background-color: @Travel;

  .breadcrumbs-fade--left {
    background: linear-gradient(to right, rgba(198, 192, 182, 1), rgba(198, 192, 182, 0.5));
  }

  .breadcrumbs-fade--right {
    background: linear-gradient(to left, rgba(198, 192, 182, 1), rgba(198, 192, 182, 0.5));
  }
}

.bg-color--ski-and-snowboard {
  background-color: @SkiAndSnowboard;

  .breadcrumbs-fade--left {
    background: linear-gradient(to right, rgba(206, 221, 223, 1), rgba(206, 221, 223, 0.5));
  }

  .breadcrumbs-fade--right {
    background: linear-gradient(to left, rgba(206, 221, 223, 1), rgba(206, 221, 223, 0.5));
  }
}

.bg-color--watersports {
  background-color: @Watersports;

  .breadcrumbs-fade--left {
    background: linear-gradient(to right, rgba(174, 197, 202, 1), rgba(174, 197, 202, 0.5));
  }

  .breadcrumbs-fade--right {
    background: linear-gradient(to left, rgba(174, 197, 202, 1), rgba(174, 197, 202, 0.5));
  }
}

.bg-color--camping {
  background-color: @Camping;

  .breadcrumbs-fade--left {
    background: linear-gradient(to right, rgba(189, 194, 186, 1), rgba(189, 194, 186, 0.5));
  }

  .breadcrumbs-fade--right {
    background: linear-gradient(to left, rgba(189, 194, 186, 1), rgba(189, 194, 186, 0.5));
  }
}

////////////////////////////////////////////////////////////////////////////////////
// Text colors
////////////////////////////////////////////////////////////////////////////////////
.text-color--white {
  color: @White !important;
}

.text-color--gray10 {
  color: @Gray10 !important;
}

.text-color--gray20 {
  color: @Gray20 !important;
}

.text-color--gray30 {
  color: @Gray30 !important;
}

.text-color--gray40 {
  color: @Gray40 !important;
}

.text-color--gray50 {
  color: @Gray50 !important;
}

.text-color--gray60 {
  color: @Gray60 !important;
}

.text-color--gray70 {
  color: @Gray70 !important;
}

.text-color--gray80 {
  color: @Gray80 !important;
}

.text-color--gray90 {
  color: @Gray90 !important;
}

.text-color--black {
  color: @Black !important;
}

.text-color--pitchBlack {
  color: @PitchBlack !important;
}

////////////////////////////////////////////////////////////////////////////////////
// Link colors
////////////////////////////////////////////////////////////////////////////////////
a.text-color--gray60 {
  &:hover {
    color: @Gray40 !important;
  }
}

a.text-color--gray80 {
  &:hover {
    color: @Gray60 !important;
  }
}

a.text-color--black {
  color: @Black !important;

  &:hover {
    color: @Gray80 !important;
  }
}
