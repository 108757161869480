// SPACERS. i=1-5 overrides bootstrap standard spacer classes
body {
  //Generates spacer classes (ps-*, pe-*, pt-*, pb-*, p-*, m-* etc with value based on spacer variables in variables.less
  .createClass(@max, @class-prefix, @prop,  @i:0, @minwidth, @size: -) when (@i <= @max) {
    @spacer: 'spacer@{i}';
    .@{class-prefix}s,
    .@{class-prefix}x,
    .@{class-prefix} {
      &@{size}@{i} {
        @media (min-width: @minwidth) {
          @{prop}-left: @@spacer !important;
        }
      }
    }

    .@{class-prefix}e,
    .@{class-prefix}x,
    .@{class-prefix} {
      &@{size}@{i} {
        @media (min-width: @minwidth) {
          @{prop}-right: @@spacer !important;
        }
      }
    }

    .@{class-prefix}t,
    .@{class-prefix}y,
    .@{class-prefix} {
      &@{size}@{i} {
        @media (min-width: @minwidth) {
          @{prop}-top: @@spacer !important;
        }
      }
    }

    .@{class-prefix}b,
    .@{class-prefix}y,
    .@{class-prefix} {
      &@{size}@{i} {
        @media (min-width: @minwidth) {
          @{prop}-bottom: @@spacer !important;
        }
      }
    }

    .createClass(@max, @class-prefix, @prop, @i:(@i+1), @minwidth, @size);
  }
  .createClass(19, p, padding, 0, 0);
  .createClass(19, p, padding, 0, @screen-sm, -sm-);
  .createClass(19, p, padding, 0, @screen-md, -md-);
  .createClass(19, p, padding, 0, @screen-lg, -lg-,);
  .createClass(19, p, padding, 0, @screen-xl, -xl-);
  .createClass(19, p, padding, 0, @screen-xxl, -xxl-);

  .createClass(19, m, margin, 0, 0);
  .createClass(19, m, margin, 0, @screen-sm, -sm-);
  .createClass(19, m, margin, 0, @screen-md, -md-);
  .createClass(19, m, margin, 0, @screen-lg, -lg-,);
  .createClass(19, m, margin, 0, @screen-xl, -xl-);
  .createClass(19, m, margin, 0, @screen-xxl, -xxl-);
}
