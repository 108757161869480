/* LOGOTYPE */
/* MAIN NAVIGATION */
/* PROMOTION SLIDER */
/* FOOTER */
/* BUTTON CLASS STYLES */
/* PRODUCT PROMOTION */
/* PRODUCT DETAIL */
/* CHECKOUT AND ACCOUNT */
/* SEARCH PAGE */
/* GRID */
@font-face {
  font-family: 'frg-icons';
  src: url('../thule/global/fonts/frg-icons.ttf') format('truetype'), url('../thule/global/fonts/frg-icons.woff') format('woff'), url('../thule/global/fonts/frg-icons.svg#frg-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^='frg-icon-'],
[class*=' frg-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'frg-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.frg-icon-Bar-Profiles-Thule-WingBar-Evo:before {
  content: '\e900';
}
.frg-icon-backpack-bring-my-kid:before {
  content: '\e600';
}
.frg-icon-backpack-day-hike:before {
  content: '\e601';
}
.frg-icon-backpack-extended-trip:before {
  content: '\e602';
}
.frg-icon-backpack-over-night:before {
  content: '\e603';
}
.frg-icon-basket:before {
  content: '\e604';
}
.frg-icon-bike:before {
  content: '\e605';
}
.frg-icon-bike-fittingpoint-fork:before {
  content: '\e606';
}
.frg-icon-bike-fittingpoint-frame:before {
  content: '\e607';
}
.frg-icon-bike-fittingpoint-wheel:before {
  content: '\e608';
}
.frg-icon-bike-hangon:before {
  content: '\e609';
}
.frg-icon-bike-platform:before {
  content: '\e60a';
}
.frg-icon-bike-tiltable-1:before {
  content: '\e60b';
}
.frg-icon-bike-tiltable-2:before {
  content: '\e60c';
}
.frg-icon-awk-bike:before {
  content: '\e60d';
}
.frg-icon-awk-double:before {
  content: '\e60e';
}
.frg-icon-awk-hike:before {
  content: '\e60f';
}
.frg-icon-awk-jog:before {
  content: '\e610';
}
.frg-icon-awk-single:before {
  content: '\e611';
}
.frg-icon-awk-ski:before {
  content: '\e612';
}
.frg-icon-awk-walk:before {
  content: '\e613';
}
.frg-icon-bike-accessorytype-bag:before {
  content: '\e614';
}
.frg-icon-bike-accessorytype-electronics:before {
  content: '\e615';
}
.frg-icon-bike-aircraft:before {
  content: '\e616';
}
.frg-icon-bike-bag:before {
  content: '\e617';
}
.frg-icon-bike-car:before {
  content: '\e618';
}
.frg-icon-bike-case:before {
  content: '\e619';
}
.frg-icon-bike-commuting:before {
  content: '\e61a';
}
.frg-icon-bike-everyday-urban:before {
  content: '\e61b';
}
.frg-icon-carrier-type-horizontal:before {
  content: '\e61c';
}
.frg-icon-carrier-type-load-assist:before {
  content: '\e61d';
}
.frg-icon-carrier-type-vertical:before {
  content: '\e61e';
}
.frg-icon-cat-bike-bags-racks:before {
  content: '\e620';
}
.frg-icon-cat-bike-carrier:before {
  content: '\e621';
}
.frg-icon-cat-boxes:before {
  content: '\e622';
}
.frg-icon-cat-camera-bags-cases:before {
  content: '\e623';
}
.frg-icon-cat-child-bike-seats:before {
  content: '\e624';
}
.frg-icon-cat-daypack-messengers:before {
  content: '\e625';
}
.frg-icon-cat-laptop-sleeve-cases:before {
  content: '\e626';
}
.frg-icon-cat-motorhomes:before {
  content: '\e628';
}
.frg-icon-cat-multifunctional-child-carriers:before {
  content: '\e629';
}
.frg-icon-cat-phone-tablet-cases:before {
  content: '\e62a';
}
.frg-icon-cat-roof-rack:before {
  content: '\e62b';
}
.frg-icon-cat-snowchains:before {
  content: '\e62c';
}
.frg-icon-cat-sport-transport-bag1:before {
  content: '\e62e';
}
.frg-icon-cat-sport-transport-bag2:before {
  content: '\e62f';
}
.frg-icon-cat-technical-backpacks:before {
  content: '\e630';
}
.frg-icon-cat-water:before {
  content: '\e631';
}
.frg-icon-cat-winter:before {
  content: '\e632';
}
.frg-icon-foldable:before {
  content: '\e633';
}
.frg-icon-gender-female:before {
  content: '\e634';
}
.frg-icon-gender-male:before {
  content: '\e635';
}
.frg-icon-bike-handlebar:before {
  content: '\e636';
}
.frg-icon-hitch-1-25:before {
  content: '\e637';
}
.frg-icon-hitch-2:before {
  content: '\e638';
}
.frg-icon-bike-rack:before {
  content: '\e639';
}
.frg-icon-bike-shipment:before {
  content: '\e63a';
}
.frg-icon-bike-touring:before {
  content: '\e63b';
}
.frg-icon-bike-train:before {
  content: '\e63c';
}
.frg-icon-lockable-bike-to-carrier:before {
  content: '\e63d';
}
.frg-icon-lockable-carrier-to-car:before {
  content: '\e63e';
}
.frg-icon-locks-included:before {
  content: '\e63f';
}
.frg-icon-looks-long:before {
  content: '\e640';
}
.frg-icon-looks-medium:before {
  content: '\e641';
}
.frg-icon-looks-short:before {
  content: '\e642';
}
.frg-icon-mounting-hitch:before {
  content: '\e643';
}
.frg-icon-mounting-rear:before {
  content: '\e644';
}
.frg-icon-mounting-roof:before {
  content: '\e645';
}
.frg-icon-mounting-towbar:before {
  content: '\e646';
}
.frg-icon-mounting-truckbed:before {
  content: '\e647';
}
.frg-icon-number-1:before {
  content: '\e648';
}
.frg-icon-number-2:before {
  content: '\e649';
}
.frg-icon-number-3:before {
  content: '\e64a';
}
.frg-icon-number-4:before {
  content: '\e64b';
}
.frg-icon-number-5:before {
  content: '\e64c';
}
.frg-icon-number-6:before {
  content: '\e64d';
}
.frg-icon-number-7:before {
  content: '\e64e';
}
.frg-icon-profile-aerobar:before {
  content: '\e64f';
}
.frg-icon-profile-probar:before {
  content: '\e650';
}
.frg-icon-profile-slidebar:before {
  content: '\e651';
}
.frg-icon-profile-squarebar:before {
  content: '\e652';
}
.frg-icon-profile-wingbar:before {
  content: '\e653';
}
.frg-icon-roof-box:before {
  content: '\e654';
}
.frg-icon-size-alpine:before {
  content: '\e655';
}
.frg-icon-size-l:before {
  content: '\e656';
}
.frg-icon-size-m:before {
  content: '\e657';
}
.frg-icon-size-s:before {
  content: '\e658';
}
.frg-icon-size-sport:before {
  content: '\e659';
}
.frg-icon-size-xl:before {
  content: '\e65a';
}
.frg-icon-size-xxl:before {
  content: '\e65b';
}
.frg-icon-smartphone:before {
  content: '\e65c';
}
.frg-icon-snow-chain-external:before {
  content: '\e65d';
}
.frg-icon-snow-chain-inovative:before {
  content: '\e65e';
}
.frg-icon-snow-chain-onestopfit:before {
  content: '\e65f';
}
.frg-icon-snow-chain-traditional:before {
  content: '\e660';
}
.frg-icon-tablet-7-9:before {
  content: '\e661';
}
.frg-icon-tablet-9-10:before {
  content: '\e662';
}
.frg-icon-water-canoe:before {
  content: '\e663';
}
.frg-icon-water-kayak:before {
  content: '\e664';
}
.frg-icon-water-sup:before {
  content: '\e665';
}
.frg-icon-water-surf:before {
  content: '\e666';
}
.frg-icon-water-windsurf:before {
  content: '\e667';
}
.frg-icon-winter-alpine:before {
  content: '\e668';
}
.frg-icon-winter-cross-country:before {
  content: '\e669';
}
.frg-icon-winter-snowboard:before {
  content: '\e66a';
}
.frg-icon-camera-action:before {
  content: '\e66b';
}
.frg-icon-camera-backpack:before {
  content: '\e66c';
}
.frg-icon-camera-cable-charger:before {
  content: '\e66d';
}
.frg-icon-camera-case:before {
  content: '\e66e';
}
.frg-icon-camera-csc:before {
  content: '\e66f';
}
.frg-icon-camera-flash:before {
  content: '\e670';
}
.frg-icon-camera-ipad-tablet:before {
  content: '\e671';
}
.frg-icon-camera-laptop:before {
  content: '\e672';
}
.frg-icon-camera-lens:before {
  content: '\e673';
}
.frg-icon-camera-model:before {
  content: '\e674';
}
.frg-icon-camera-pro-dslr:before {
  content: '\e675';
}
.frg-icon-camera-profile:before {
  content: '\e676';
}
.frg-icon-camera-prosumer-dslr:before {
  content: '\e677';
}
.frg-icon-camera-prosumer-dslr-battery:before {
  content: '\e678';
}
.frg-icon-camera-shoulder-bag:before {
  content: '\e679';
}
.frg-icon-camera-sling:before {
  content: '\e67a';
}
.frg-icon-camera-standard-dslr:before {
  content: '\e67b';
}
.frg-icon-camera-standard-dslr-battery:before {
  content: '\e67c';
}
.frg-icon-camera-toploader:before {
  content: '\e67d';
}
.frg-icon-camera-tripod:before {
  content: '\e67e';
}
.frg-icon-dam-daypack:before {
  content: '\e67f';
}
.frg-icon-dam-messenger-bag:before {
  content: '\e680';
}
.frg-icon-dam-sling-pack:before {
  content: '\e681';
}
.frg-icon-lsc-bag:before {
  content: '\e682';
}
.frg-icon-lsc-hard-case:before {
  content: '\e683';
}
.frg-icon-lsc-macbook:before {
  content: '\e684';
}
.frg-icon-lsc-pc:before {
  content: '\e685';
}
.frg-icon-lsc-sleeve:before {
  content: '\e686';
}
.frg-icon-lsc-soft-case:before {
  content: '\e687';
}
.frg-icon-table-cases-bag:before {
  content: '\e688';
}
.frg-icon-table-cases-hard-case:before {
  content: '\e689';
}
.frg-icon-table-cases-sleeve:before {
  content: '\e68a';
}
.frg-icon-table-cases-soft-case:before {
  content: '\e68b';
}
.frg-icon-cat-smartphone:before {
  content: '\e68d';
}
.frg-icon-cat-tablet:before {
  content: '\e68e';
}
.frg-icon-warning:before {
  content: '\e901';
}
.frg-icon-b:before {
  content: '\e902';
}
.frg-icon-bike-backpack:before {
  content: '\e903';
}
.frg-icon-ski-backpack:before {
  content: '\e904';
}
.frg-icon-stroller-glide:before {
  content: '\e905';
}
.frg-icon-stroller:before {
  content: '\e906';
}
.frg-icon-cat-luggage-3:before {
  content: '\e907';
}
.frg-icon-cat-luggage-2:before {
  content: '\e908';
}
.frg-icon-cat-luggage-2-side:before {
  content: '\e909';
}
.frg-icon-cat-bicycle-trailers:before {
  content: '\e90a';
}
.frg-icon-cat-child-bike-seats-back1:before {
  content: '\e90b';
}
.frg-icon-cat-child-bike-seats-back2:before {
  content: '\e90c';
}
.frg-icon-cat-child-bike-seats-front:before {
  content: '\e90d';
}
.frg-icon-e-bike:before {
  content: '\e90e';
}
.frg-icon-child-bike-seats-rack-mounted:before {
  content: '\e90f';
}
.frg-icon-child-bike-seats-frame-mounted:before {
  content: '\e910';
}
.frg-icon-child-bike-seats-16-22kg:before {
  content: '\e911';
}
.frg-icon-child-bike-seats-9-15kg:before {
  content: '\e912';
}
.frg-icon-cat-boxes2:before {
  content: '\e913';
}
.frg-icon-cat-laptop-phone:before {
  content: '\e914';
}
.frg-icon-cat-luggage-duffels:before {
  content: '\e915';
}
.frg-icon-cat-sport-strollers:before {
  content: '\e916';
}
.frg-icon-section-awk:before {
  content: '\e917';
}
.frg-icon-section-caravans:before {
  content: '\e918';
}
.frg-icon-section-carriers-racks:before {
  content: '\e919';
}
.frg-icon-section-luggage:before {
  content: '\e91a';
}
.frg-icon-section-van:before {
  content: '\e91b';
}
.frg-icon-drone:before {
  content: '\e91c';
}
.frg-icon-cat-child-carrier-backpacks:before {
  content: '\e91d';
}
.frg-icon-cart-complete:before {
  content: '\e91e';
}
.frg-icon-cart-error:before {
  content: '\e91f';
}
.frg-icon-cart-notice:before {
  content: '\e920';
}
.frg-icon-shipping-notice:before {
  content: '\e921';
}
.frg-icon-thule-logo-cutout:before {
  content: '\e922';
}
.frg-icon-thule:before {
  content: '\e923';
}
.frg-icon-thule-logo-only-text:before {
  content: '\e924';
}
.frg-icon-Awning:before {
  content: '\e925';
}
.frg-icon-Edit-account:before {
  content: '\e926';
}
.frg-icon-thule_transport_icon200525:before {
  content: '\e927';
}
.frg-icon-Activity_Explore:before {
  content: '\e928';
}
.frg-icon-Activity_Instashop:before {
  content: '\e929';
}
.frg-icon-Activity_News:before {
  content: '\e92a';
}
.frg-icon-Activity_PathosPassion:before {
  content: '\e92b';
}
.frg-icon-Activity_Sustainability:before {
  content: '\e92c';
}
.frg-icon-Activity_ThuleTestCenter:before {
  content: '\e92d';
}
.frg-icon-Activity_TipsStories:before {
  content: '\e92e';
}
.frg-icon-bs:before {
  content: '\e92f';
}
.frg-icon-b-ss:before {
  content: '\e930';
}
.frg-icon-cc:before {
  content: '\e931';
}
.frg-icon-cs:before {
  content: '\e932';
}
.frg-icon-c-ss:before {
  content: '\e933';
}
.frg-icon-kids:before {
  content: '\e934';
}
.frg-icon-sc:before {
  content: '\e935';
}
.frg-icon-ss:before {
  content: '\e936';
}
.frg-icon-180:before {
  content: '\e937';
}
.frg-icon-L:before {
  content: '\e938';
}
.frg-icon-R:before {
  content: '\e939';
}
.frg-icon-360:before {
  content: '\e93a';
}
.frg-icon-b2:before {
  content: '\e93b';
}
.frg-icon-c:before {
  content: '\e93c';
}
.frg-icon-kid:before {
  content: '\e93d';
}
.frg-icon-s:before {
  content: '\e93e';
}
.frg-icon-stroller-BG:before {
  content: '\e93f';
}
.frg-icon-bc:before {
  content: '\e940';
}
.frg-icon-as:before {
  content: '\e941';
}
.frg-icon-360arrow:before {
  content: '\e942';
}
.frg-icon-hydration-pack-water:before {
  content: '\e943';
}
.frg-icon-lug1:before {
  content: '\e944';
}
.frg-icon-lug2:before {
  content: '\e945';
}
.frg-icon-lug3:before {
  content: '\e946';
}
.frg-icon-lug4:before {
  content: '\e947';
}
.frg-icon-section-toptent:before {
  content: '\e948';
}
.frg-icon-section-RV-awning:before {
  content: '\e949';
}
.frg-icon-lock-system:before {
  content: '\e94a';
}
.frg-icon-Chrash-nose:before {
  content: '\e94b';
}
.frg-icon-DualSide-opening:before {
  content: '\e94c';
}
.frg-icon-FastClick:before {
  content: '\e94d';
}
.frg-icon-Arrow:before {
  content: '\e94e';
}
.frg-icon-Load-net:before {
  content: '\e94f';
}
.frg-icon-Outside-Handle-bar:before {
  content: '\e950';
}
.frg-icon-PowerClick:before {
  content: '\e951';
}
.frg-icon-Syncronizedl-id-lifters:before {
  content: '\e952';
}
.frg-icon-Plus:before {
  content: '\e953';
}
.frg-icon-Central-lock-system:before {
  content: '\e954';
}
.frg-icon-DualSide-opening1:before {
  content: '\e955';
}
.frg-icon-FastClick1:before {
  content: '\e956';
}
.frg-icon-Handlebar:before {
  content: '\e957';
}
.frg-icon-Interior:before {
  content: '\e958';
}
.frg-icon-Light:before {
  content: '\e959';
}
.frg-icon-LockKnob:before {
  content: '\e95a';
}
.frg-icon-PowerClick1:before {
  content: '\e95b';
}
.frg-icon-SlideLock-system:before {
  content: '\e95c';
}
.frg-icon-FullTrunk-opening:before {
  content: '\e95d';
}
.frg-icon-youtube:before {
  content: '\e95e';
}
.frg-icon-quote:before {
  content: '\e95f';
}
.frg-icon-Arrow-long:before {
  content: '\e960';
}
.frg-icon-Out-of-stock-Triangle:before {
  content: '\e961';
}
.frg-icon-Thule-Glide-Stroller-Car-seat:before {
  content: '\e962';
}
.frg-icon-Thule-Glide-Stroller:before {
  content: '\e963';
}
.frg-icon-Thule-Urban-Glide-Double-Stroller-Basinett:before {
  content: '\e964';
}
.frg-icon-Thule-Urban-Glide-Double-Stroller-Car-seat:before {
  content: '\e965';
}
.frg-icon-Thule-Urban-Glide-Double-Stroller:before {
  content: '\e966';
}
.frg-icon-Thule-Urban-Glide-Stroller-Basinett:before {
  content: '\e967';
}
.frg-icon-Thule-Urban-Glide-Stroller-Car-seat:before {
  content: '\e968';
}
.frg-icon-Thule-Urban-Glide-Stroller:before {
  content: '\e969';
}
.frg-icon-flyfishing:before {
  content: '\e96a';
}
.frg-icon-Activity_Skiing:before {
  content: '\e96b';
}
.frg-icon-Backpacks_Icon_2020:before {
  content: '\e96c';
}
.frg-icon-Bike_Icon_2020:before {
  content: '\e96d';
}
.frg-icon-Bike_Packs_Bags__Racks_Icon_2020:before {
  content: '\e96e';
}
.frg-icon-Bike_Racks_Icon_2020:before {
  content: '\e96f';
}
.frg-icon-City_Adventure_Icon_2021:before {
  content: '\e970';
}
.frg-icon-Activity_Bike:before {
  content: '\e971';
}
.frg-icon-Activity_Camp:before {
  content: '\e972';
}
.frg-icon-Activity_Family:before {
  content: '\e973';
}
.frg-icon-Activity_FriendsofThule:before {
  content: '\e974';
}
.frg-icon-Marker:before {
  content: '\e975';
}
.frg-icon-Activity_Hike:before {
  content: '\e976';
}
.frg-icon-Activity_History:before {
  content: '\e977';
}
.frg-icon-Activity_OutdoorAdventure:before {
  content: '\e978';
}
.frg-icon-Activity_ThisisThule:before {
  content: '\e979';
}
.frg-icon-Activity_Travel:before {
  content: '\e97a';
}
.frg-icon-Car-sedan:before {
  content: '\e97b';
}
.frg-icon-Check:before {
  content: '\e97c';
}
.frg-icon-Cross:before {
  content: '\e97d';
}
.frg-icon-Extra-information:before {
  content: '\e97e';
}
.frg-icon-Filter:before {
  content: '\e97f';
}
.frg-icon-Bike_Trailers_Icon_2020:before {
  content: '\e980';
}
.frg-icon-Bring_Your_Life_Icon_2020:before {
  content: '\e981';
}
.frg-icon-Caravan_Accessories_Icon_2020:before {
  content: '\e982';
}
.frg-icon-Cargo_Carriers_and_Baskets_Icon_2020:before {
  content: '\e983';
}
.frg-icon-Minus:before {
  content: '\e984';
}
.frg-icon-My-account:before {
  content: '\e985';
}
.frg-icon-Plus-1:before {
  content: '\e986';
}
.frg-icon-Plus1:before {
  content: '\e987';
}
.frg-icon-Shipping-warning:before {
  content: '\e988';
}
.frg-icon-Child_Bike_Seats_Icon_2020:before {
  content: '\e989';
}
.frg-icon-hydration-pack:before {
  content: '\e98a';
}
.frg-icon-Child_Carrier_Backpacks_Icon_2020:before {
  content: '\e98b';
}
.frg-icon-Explore_Icon_2020:before {
  content: '\e98c';
}
.frg-icon-arrow-long-Down:before {
  content: '\e98d';
}
.frg-icon-arrow-long-Left:before {
  content: '\e98e';
}
.frg-icon-Arrows-up-and-down:before {
  content: '\e98f';
}
.frg-icon-bell-regular:before {
  content: '\e990';
}
.frg-icon-Calendar:before {
  content: '\e991';
}
.frg-icon-Hike_Trek_Icon_2020:before {
  content: '\e992';
}
.frg-icon-Instashop_Icon_2020:before {
  content: '\e993';
}
.frg-icon-Laptop_Cases_Sleeves__Cases_Icon_2020:before {
  content: '\e994';
}
.frg-icon-Luggage_Icon_2020:before {
  content: '\e995';
}
.frg-icon-Motorhome_Accessories_Icon_2020:before {
  content: '\e996';
}
.frg-icon-Car-front:before {
  content: '\e997';
}
.frg-icon-Chat:before {
  content: '\e998';
}
.frg-icon-Export:before {
  content: '\e999';
}
.frg-icon-Eye-slash-menu:before {
  content: '\e99a';
}
.frg-icon-Fast-truck:before {
  content: '\e99b';
}
.frg-icon-minus-1:before {
  content: '\e99c';
}
.frg-icon-News_Icon_2020:before {
  content: '\e99d';
}
.frg-icon-Order-history:before {
  content: '\e99e';
}
.frg-icon-plus-1:before {
  content: '\e99f';
}
.frg-icon-Preseason:before {
  content: '\e9a0';
}
.frg-icon-Shipping:before {
  content: '\e9a1';
}
.frg-icon-Thule-group-Cart:before {
  content: '\e9a2';
}
.frg-icon-Thule-group-Filter:before {
  content: '\e9a3';
}
.frg-icon-Our_History_Icon_2020:before {
  content: '\e9a4';
}
.frg-icon-Pathos__Passion_Icon_2020:before {
  content: '\e9a5';
}
.frg-icon-Roof_Racks_Icon_2020:before {
  content: '\e9a6';
}
.frg-icon-Thule-Group-Logo:before {
  content: '\e9a7';
}
.frg-icon-Thule-group-My-Account:before {
  content: '\e9a8';
}
.frg-icon-Car-icon:before {
  content: '\e9a9';
}
.frg-icon-Filter-icon:before {
  content: '\e9aa';
}
.frg-icon-Fit-or-no-fit:before {
  content: '\e9ab';
}
.frg-icon-Information-icon:before {
  content: '\e9ac';
}
.frg-icon-Roof_Top_Tent_and_Accessories_Icon_2020:before {
  content: '\e9ad';
}
.frg-icon-Sport_Travel_Bags_and_Cases_Icon_2020:before {
  content: '\e9ae';
}
.frg-icon-Strollers_Icon_2020:before {
  content: '\e9af';
}
.frg-icon-Sustainability_Icon_2020:before {
  content: '\e9b0';
}
.frg-icon-Thule_Crew_Icon_2020:before {
  content: '\e9b1';
}
.frg-icon-Thule_Text_Center_Icon_2020:before {
  content: '\e9b2';
}
.frg-icon-Bar-Profiles-Thule-ProBar:before {
  content: '\e9b3';
}
.frg-icon-Bar-Profiles-Thule-SlideBar:before {
  content: '\e9b4';
}
.frg-icon-Bar-Profiles-Thule-SquareBar:before {
  content: '\e9b5';
}
.frg-icon-Bar-Profiles-Thule-WingBar:before {
  content: '\e9b6';
}
.frg-icon-Tips_Stories_Icon_2020:before {
  content: '\e9b9';
}
.frg-icon-Travel_Icon_2020:before {
  content: '\e9bb';
}
.frg-icon-Van_Accessories_Icon_2020:before {
  content: '\e9bc';
}
.frg-icon-Van_Life_Icon_2020:before {
  content: '\e9bd';
}
.frg-icon-Water_Sport_Icon_2020:before {
  content: '\e9c4';
}
.frg-icon-Winter__Water_Sports_Racks_Icon_2020:before {
  content: '\e9c5';
}
.frg-icon-Winter_Sport_Icon_2020:before {
  content: '\e9cd';
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-Ultralight-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-Ultralight-WebM.woff') format('woff');
  font-weight: 250;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-UltralightItalic-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-UltralightItalic-WebM.woff') format('woff');
  font-weight: 250;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-Thin-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-Thin-WebM.woff') format('woff');
  font-weight: 275;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-ThinItalic-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-ThinItalic-WebM.woff') format('woff');
  font-weight: 275;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-Light-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-Light-WebM.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-LightItalic-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-LightItalic-WebM.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-Regular-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-Regular-WebM.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-RegularItalic-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-RegularItalic-WebM.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-Book-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-Book-WebM.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-BookItalic-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-BookItalic-WebM.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-Medium-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-Medium-WebM.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-MediumItalic-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-MediumItalic-WebM.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-SemiBold-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-SemiBold-WebM.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-SemiBoldItalic-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-SemiBoldItalic-WebM.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-Bold-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-Bold-WebM.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-BoldItalic-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-BoldItalic-WebM.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-Black-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-Black-WebM.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisse';
  src: url('../thule/global/fonts/SuisseIntl-BlackItalic-WebM.woff2') format('woff2'), url('../thule/global/fonts/SuisseIntl-BlackItalic-WebM.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisseWorks';
  src: url('../thule/global/fonts/SuisseWorks/SuisseWorks-Regular-WebXL.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisseWorks';
  src: url('../thule/global/fonts/SuisseWorks/SuisseWorks-RegularItalic-WebXL.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisseWorks';
  src: url('../thule/global/fonts/SuisseWorks/SuisseWorks-Book-WebXL.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisseWorks';
  src: url('../thule/global/fonts/SuisseWorks/SuisseWorks-BookItalic-WebXL.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisseWorks';
  src: url('../thule/global/fonts/SuisseWorks/SuisseWorks-Medium-WebXL.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisseWorks';
  src: url('../thule/global/fonts/SuisseWorks/SuisseWorks-MediumItalic-WebXL.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'suisseWorks';
  src: url('../thule/global/fonts/SuisseWorks/SuisseWorks-Bold-WebXL.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'suisseWorks';
  src: url('../thule/global/fonts/SuisseWorks/SuisseWorks-BoldItalic-WebXL.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  word-wrap: break-word;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none;
  hyphens: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.heading1,
.heading2,
.heading3,
.heading4,
.heading5,
.heading6,
.heading7,
.display1,
.display2,
.display3,
.underline .heading-textsize,
.mega-heading,
.badge--Informative,
.badge--Commercial {
  font-weight: 500;
  line-height: 1.5;
}
h1 p,
h2 p,
h3 p,
h4 p,
h5 p,
h6 p,
.heading1 p,
.heading2 p,
.heading3 p,
.heading4 p,
.heading5 p,
.heading6 p,
.heading7 p,
.display1 p,
.display2 p,
.display3 p,
.underline .heading-textsize p,
.mega-heading p {
  font-size: inherit;
  color: inherit;
  margin-top: 0;
  margin-bottom: 0;
}
.display1 {
  font-size: 4.8rem;
}
@media (min-width: 992px) {
  .display1 {
    font-size: 8rem;
  }
}
.display2 {
  font-size: 4rem;
}
@media (min-width: 992px) {
  .display2 {
    font-size: 6.4rem;
  }
}
.display3 {
  font-size: 3.6rem;
}
@media (min-width: 992px) {
  .display3 {
    font-size: 5.2rem;
  }
}
h1,
.heading1 {
  font-size: 3.2rem;
}
@media (min-width: 992px) {
  h1,
  .heading1 {
    font-size: 4.8rem;
  }
}
h2,
.heading2 {
  font-size: 2.8rem;
}
@media (min-width: 992px) {
  h2,
  .heading2 {
    font-size: 4rem;
  }
}
h3,
.heading3 {
  font-size: 2.4rem;
}
@media (min-width: 992px) {
  h3,
  .heading3 {
    font-size: 3.2rem;
  }
}
h4,
.heading4 {
  font-size: 2rem;
}
@media (min-width: 992px) {
  h4,
  .heading4 {
    font-size: 2.4rem;
  }
}
h5,
.heading5 {
  font-size: 1.6rem;
  font-weight: 600;
}
h6,
.heading6,
.badge--Informative,
.badge--Commercial {
  font-size: 1.4rem;
  font-weight: 600;
}
.heading7 {
  font-size: 1.4rem;
}
@media (min-width: 992px) {
  .heading7 {
    font-size: 1.6rem;
  }
}
.underline {
  font-size: 1.6rem;
}
p,
.paragraph--lead,
.paragraph--s,
.paragraph--xs,
.paragraph--7,
.paragraph,
.badge--Features {
  font-weight: 400;
}
.paragraph--lead {
  font-size: 2rem;
}
p,
.paragraph {
  font-size: 1.6rem;
}
.paragraph--s,
.badge--Features {
  font-size: 1.4rem;
}
.paragraph--xs {
  font-size: 1.2rem;
}
.paragraph--7 {
  font-size: 1.4rem;
}
@media (min-width: 992px) {
  .paragraph--7 {
    font-size: 1.6rem;
  }
}
.heading-textsize {
  font-size: 1.4rem;
}
.mega-heading {
  font-size: 3.2rem;
}
@media (min-width: 992px) {
  .mega-heading {
    font-size: 6rem;
  }
}
.small-text {
  font-size: 1.2rem;
}
.small-text p {
  font-size: inherit;
  margin-bottom: 0;
}
p {
  font-size: 1.6rem;
  color: #181818;
}
p a {
  text-decoration: underline;
}
a {
  text-decoration: none;
  color: #181818;
}
a:hover {
  text-decoration: none;
  color: #000000;
}
.image-text {
  font-size: 1.3rem;
}
@media (min-width: 992px) {
  .image-text {
    font-size: 1.4rem;
  }
}
.darker-link {
  color: #fff;
}
.darker-link:hover,
.darker-link:focus {
  color: #d9d9d9;
}
.black-link {
  color: black;
}
.black-link:hover,
.black-link:focus {
  color: rgba(0, 0, 0, 0.77);
}
.thuledarkgrey-link {
  color: #575757;
}
.thuledarkgrey-link:hover,
.thuledarkgrey-link:focus {
  color: #3e3e3e;
}
.line-through-text {
  text-decoration: line-through;
}
.text--truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.suisseWorks {
  font-family: 'SuisseWorks';
}
.thuleForm {
  max-height: 100%;
  background-color: #fff;
  position: relative;
}
.thuleForm .input-row {
  display: flex;
  column-gap: 2rem;
  flex-wrap: wrap;
  flex-direction: column;
}
.thuleForm .input-row div {
  flex: 1 0 0;
}
.thuleForm .input-row:has(> .state) .city {
  flex: unset;
  width: 100%;
}
.thuleForm .thule-input {
  margin-bottom: 1.6rem;
}
.thuleForm .thule-input::placeholder {
  color: #a3a3a3;
}
.thuleForm label {
  color: #474747;
}
.thuleForm__checkbox input[type="checkbox"] {
  display: none;
}
.thuleForm__checkbox input[type="checkbox"]:checked + .custom-checkbox::after {
  opacity: 1;
}
.thuleForm__checkbox label {
  display: flex;
}
.thuleForm__checkbox label .custom-checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid #474747;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  pointer-events: none;
  margin-right: 0.8rem;
  flex-shrink: 0;
}
.thuleForm__checkbox label .custom-checkbox::after {
  content: '';
  display: block;
  width: 6px;
  height: 12px;
  border: solid #474747;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 1px;
  left: 5px;
  transform: rotate(45deg);
  opacity: 0;
}
.thuleForm__radio input[type="radio"] {
  display: none;
}
.thuleForm__radio input[type="radio"]:checked + .custom-radio::after {
  opacity: 1;
}
.thuleForm__radio .custom-radio {
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 2px solid black;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.thuleForm__radio .custom-radio:focus {
  box-shadow: 0 0 3px 3px rgba(21, 156, 228, 0.4);
}
.thuleForm__radio .custom-radio::after {
  content: '';
  position: absolute;
  top: 8px;
  left: 50%;
  width: 20%;
  height: 42%;
  border: solid #181818;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
}
.bg-color--white {
  background-color: #fff;
}
.bg-color--black {
  background-color: #181818;
}
.bg-color--black a {
  color: #fff;
}
.bg-color--black .breadcrumbs-fade--left {
  background: linear-gradient(to right, #181818, rgba(24, 24, 24, 0.5));
}
.bg-color--black .breadcrumbs-fade--right {
  background: linear-gradient(to left, #181818, rgba(24, 24, 24, 0.5));
}
.bg-color--thule-light-grey {
  background-color: #eeeeee;
}
.bg-color--thule-lightest-grey {
  background-color: #f7f7f7;
}
.bg-color--gray10 {
  background-color: #f5f5f5;
}
.bg-color--gray10 p,
.bg-color--gray10 button,
.bg-color--gray10 a {
  color: #181818;
}
.bg-color--gray10 a:hover,
.bg-color--gray10 button:hover {
  text-decoration: none;
  color: #474747;
}
.bg-color--gray10 i[class*='le-icon-'] {
  background: #181818;
}
.bg-color--gray90 {
  background-color: #303030;
}
.bg-color--gray90 li,
.bg-color--gray90 p,
.bg-color--gray90 button,
.bg-color--gray90 a,
.bg-color--gray90 span {
  color: #fff;
}
.bg-color--gray90 a:hover,
.bg-color--gray90 button:hover {
  text-decoration: none;
  color: #e0e0e0;
}
.bg-color--gray90 i[class*='le-icon-'] {
  background: #fff;
}
.bg-color--success100 {
  background-color: #148914 !important;
}
.bg-color--family-life {
  background-color: #bdc5c1;
}
.bg-color--family-life .breadcrumbs-fade--left {
  background: linear-gradient(to right, #bdc5c1, rgba(189, 197, 193, 0.5));
}
.bg-color--family-life .breadcrumbs-fade--right {
  background: linear-gradient(to left, #bdc5c1, rgba(189, 197, 193, 0.5));
}
.bg-color--bike {
  background-color: #cbc8bd;
}
.bg-color--bike .breadcrumbs-fade--left {
  background: linear-gradient(to right, #cbc8bd, rgba(203, 200, 189, 0.5));
}
.bg-color--bike .breadcrumbs-fade--right {
  background: linear-gradient(to left, #cbc8bd, rgba(203, 200, 189, 0.5));
}
.bg-color--hike {
  background-color: #cec3b6;
}
.bg-color--hike .breadcrumbs-fade--left {
  background: linear-gradient(to right, #cec3b6, rgba(206, 195, 182, 0.5));
}
.bg-color--hike .breadcrumbs-fade--right {
  background: linear-gradient(to left, #cec3b6, rgba(206, 195, 182, 0.5));
}
.bg-color--active-with-dog {
  background-color: #adbab3;
}
.bg-color--active-with-dog .breadcrumbs-fade--left {
  background: linear-gradient(to right, #adbab3, rgba(173, 186, 179, 0.5));
}
.bg-color--active-with-dog .breadcrumbs-fade--right {
  background: linear-gradient(to left, #adbab3, rgba(173, 186, 179, 0.5));
}
.bg-color--travel {
  background-color: #c6c0b6;
}
.bg-color--travel .breadcrumbs-fade--left {
  background: linear-gradient(to right, #c6c0b6, rgba(198, 192, 182, 0.5));
}
.bg-color--travel .breadcrumbs-fade--right {
  background: linear-gradient(to left, #c6c0b6, rgba(198, 192, 182, 0.5));
}
.bg-color--ski-and-snowboard {
  background-color: #cedddf;
}
.bg-color--ski-and-snowboard .breadcrumbs-fade--left {
  background: linear-gradient(to right, #cedddf, rgba(206, 221, 223, 0.5));
}
.bg-color--ski-and-snowboard .breadcrumbs-fade--right {
  background: linear-gradient(to left, #cedddf, rgba(206, 221, 223, 0.5));
}
.bg-color--watersports {
  background-color: #aec5ca;
}
.bg-color--watersports .breadcrumbs-fade--left {
  background: linear-gradient(to right, #aec5ca, rgba(174, 197, 202, 0.5));
}
.bg-color--watersports .breadcrumbs-fade--right {
  background: linear-gradient(to left, #aec5ca, rgba(174, 197, 202, 0.5));
}
.bg-color--camping {
  background-color: #bdc2ba;
}
.bg-color--camping .breadcrumbs-fade--left {
  background: linear-gradient(to right, #bdc2ba, rgba(189, 194, 186, 0.5));
}
.bg-color--camping .breadcrumbs-fade--right {
  background: linear-gradient(to left, #bdc2ba, rgba(189, 194, 186, 0.5));
}
.text-color--white {
  color: #fff !important;
}
.text-color--gray10 {
  color: #f5f5f5 !important;
}
.text-color--gray20 {
  color: #e0e0e0 !important;
}
.text-color--gray30 {
  color: #bababa !important;
}
.text-color--gray40 {
  color: #a3a3a3 !important;
}
.text-color--gray50 {
  color: #8c8c8c !important;
}
.text-color--gray60 {
  color: #757575 !important;
}
.text-color--gray70 {
  color: #5e5e5e !important;
}
.text-color--gray80 {
  color: #474747 !important;
}
.text-color--gray90 {
  color: #303030 !important;
}
.text-color--black {
  color: #181818 !important;
}
.text-color--pitchBlack {
  color: #000 !important;
}
a.text-color--gray60:hover {
  color: #a3a3a3 !important;
}
a.text-color--gray80:hover {
  color: #757575 !important;
}
a.text-color--black {
  color: #181818 !important;
}
a.text-color--black:hover {
  color: #474747 !important;
}
html {
  font-size: 62.5%;
  min-width: 320px;
}
body {
  font-family: suisse, helvetica neue, helvetica, arial, frutiger, univers, sans-serif;
  font-size: 1.4rem;
  color: #181818;
  line-height: 1.5;
  letter-spacing: normal;
}
body[class*='viewMode_'] {
  overflow: hidden;
  -webkit-transform-origin-x: 0;
  -webkit-transform-origin-y: 0;
  width: 1536px;
  height: 862px;
}
body.viewMode_baseTemplate {
  transform: scale(0.127);
  width: 1440px;
  height: 810px;
  transform-origin: top left;
}
body.viewMode_thumbnail {
  transform: scale(0.2);
  transform-origin: top left;
}
body.viewMode_previewMain {
  transform: scale(0.8);
  transform-origin: top left;
}
hr {
  margin: 3rem 0;
}
.thule-custom-dropdown button {
  text-align: left;
  padding-left: 0.8rem;
}
.thule-custom-dropdown li {
  cursor: default;
}
.btn {
  font-weight: 500;
}
.btn[class*='thule-btn-'] {
  min-height: 5.6rem;
  min-width: 16rem;
  font-size: 16px;
}
.btn[class*='thule-btn-'].btn-sm,
.btn[class*='thule-btn-'] .btn-group-sm > .btn {
  min-height: 4rem;
  min-width: 10rem;
  font-size: 14px;
}
.btn[class*='thule-btn-'].btn-md,
.btn[class*='thule-btn-'] .btn-group-md > .btn {
  min-height: 4.8rem;
  min-width: 10rem;
  font-size: 16px;
}
.btn[class*='thule-btn-'].btn-lg,
.btn[class*='thule-btn-'] .btn-group-lg > .btn {
  min-height: 5.6rem;
  min-width: 20rem;
  font-size: 16px;
}
.btn.thule-btn-default,
.btn.thule-btn-link,
.btn.thule-btn-shop,
.btn.thule-btn-shop-action,
.btn.thule-btn-super-action {
  color: #fff;
  background-color: #181818;
  border: 1px solid #181818;
}
.btn.thule-btn-default i,
.btn.thule-btn-link i,
.btn.thule-btn-shop i,
.btn.thule-btn-shop-action i,
.btn.thule-btn-super-action i {
  background-color: #fff;
}
.btn.thule-btn-default:hover,
.btn.thule-btn-link:hover,
.btn.thule-btn-shop:hover,
.btn.thule-btn-shop-action:hover,
.btn.thule-btn-super-action:hover {
  color: #fff !important;
  background-color: #303030 !important;
  border: 1px solid #303030 !important;
}
.btn.thule-btn-default:hover i,
.btn.thule-btn-link:hover i,
.btn.thule-btn-shop:hover i,
.btn.thule-btn-shop-action:hover i,
.btn.thule-btn-super-action:hover i {
  background-color: #fff !important;
}
.btn.thule-btn-default:focus,
.btn.thule-btn-link:focus,
.btn.thule-btn-shop:focus,
.btn.thule-btn-shop-action:focus,
.btn.thule-btn-super-action:focus,
.btn.thule-btn-default.focus,
.btn.thule-btn-link.focus,
.btn.thule-btn-shop.focus,
.btn.thule-btn-shop-action.focus,
.btn.thule-btn-super-action.focus {
  color: #fff;
  border: 2px solid #6694b5;
  background-color: #181818;
}
.btn.thule-btn-default:active,
.btn.thule-btn-link:active,
.btn.thule-btn-shop:active,
.btn.thule-btn-shop-action:active,
.btn.thule-btn-super-action:active,
.btn.thule-btn-default.active,
.btn.thule-btn-link.active,
.btn.thule-btn-shop.active,
.btn.thule-btn-shop-action.active,
.btn.thule-btn-super-action.active {
  color: #474747 !important;
  background-color: #e0e0e0 !important;
  border: 1px solid #e0e0e0 !important;
}
.btn.thule-btn-default:active i,
.btn.thule-btn-link:active i,
.btn.thule-btn-shop:active i,
.btn.thule-btn-shop-action:active i,
.btn.thule-btn-super-action:active i,
.btn.thule-btn-default.active i,
.btn.thule-btn-link.active i,
.btn.thule-btn-shop.active i,
.btn.thule-btn-shop-action.active i,
.btn.thule-btn-super-action.active i {
  background-color: #474747 !important;
}
.btn.thule-btn-default:active,
.btn.thule-btn-link:active,
.btn.thule-btn-shop:active,
.btn.thule-btn-shop-action:active,
.btn.thule-btn-super-action:active,
.btn.thule-btn-default.active,
.btn.thule-btn-link.active,
.btn.thule-btn-shop.active,
.btn.thule-btn-shop-action.active,
.btn.thule-btn-super-action.active,
.open > .dropdown-toggle.btn.thule-btn-default,
.open > .dropdown-toggle.btn.thule-btn-link,
.open > .dropdown-toggle.btn.thule-btn-shop,
.open > .dropdown-toggle.btn.thule-btn-shop-action,
.open > .dropdown-toggle.btn.thule-btn-super-action {
  background-image: none;
}
.btn.thule-btn-default.disabled,
.btn.thule-btn-link.disabled,
.btn.thule-btn-shop.disabled,
.btn.thule-btn-shop-action.disabled,
.btn.thule-btn-super-action.disabled,
.btn.thule-btn-default[disabled],
.btn.thule-btn-link[disabled],
.btn.thule-btn-shop[disabled],
.btn.thule-btn-shop-action[disabled],
.btn.thule-btn-super-action[disabled],
fieldset[disabled] .btn.thule-btn-default,
fieldset[disabled] .btn.thule-btn-link,
fieldset[disabled] .btn.thule-btn-shop,
fieldset[disabled] .btn.thule-btn-shop-action,
fieldset[disabled] .btn.thule-btn-super-action,
.btn.thule-btn-default.disabled:hover,
.btn.thule-btn-link.disabled:hover,
.btn.thule-btn-shop.disabled:hover,
.btn.thule-btn-shop-action.disabled:hover,
.btn.thule-btn-super-action.disabled:hover,
.btn.thule-btn-default[disabled]:hover,
.btn.thule-btn-link[disabled]:hover,
.btn.thule-btn-shop[disabled]:hover,
.btn.thule-btn-shop-action[disabled]:hover,
.btn.thule-btn-super-action[disabled]:hover,
fieldset[disabled] .btn.thule-btn-default:hover,
fieldset[disabled] .btn.thule-btn-link:hover,
fieldset[disabled] .btn.thule-btn-shop:hover,
fieldset[disabled] .btn.thule-btn-shop-action:hover,
fieldset[disabled] .btn.thule-btn-super-action:hover,
.btn.thule-btn-default.disabled:focus,
.btn.thule-btn-link.disabled:focus,
.btn.thule-btn-shop.disabled:focus,
.btn.thule-btn-shop-action.disabled:focus,
.btn.thule-btn-super-action.disabled:focus,
.btn.thule-btn-default[disabled]:focus,
.btn.thule-btn-link[disabled]:focus,
.btn.thule-btn-shop[disabled]:focus,
.btn.thule-btn-shop-action[disabled]:focus,
.btn.thule-btn-super-action[disabled]:focus,
fieldset[disabled] .btn.thule-btn-default:focus,
fieldset[disabled] .btn.thule-btn-link:focus,
fieldset[disabled] .btn.thule-btn-shop:focus,
fieldset[disabled] .btn.thule-btn-shop-action:focus,
fieldset[disabled] .btn.thule-btn-super-action:focus,
.btn.thule-btn-default.disabled.focus,
.btn.thule-btn-link.disabled.focus,
.btn.thule-btn-shop.disabled.focus,
.btn.thule-btn-shop-action.disabled.focus,
.btn.thule-btn-super-action.disabled.focus,
.btn.thule-btn-default[disabled].focus,
.btn.thule-btn-link[disabled].focus,
.btn.thule-btn-shop[disabled].focus,
.btn.thule-btn-shop-action[disabled].focus,
.btn.thule-btn-super-action[disabled].focus,
fieldset[disabled] .btn.thule-btn-default.focus,
fieldset[disabled] .btn.thule-btn-link.focus,
fieldset[disabled] .btn.thule-btn-shop.focus,
fieldset[disabled] .btn.thule-btn-shop-action.focus,
fieldset[disabled] .btn.thule-btn-super-action.focus,
.btn.thule-btn-default.disabled:active,
.btn.thule-btn-link.disabled:active,
.btn.thule-btn-shop.disabled:active,
.btn.thule-btn-shop-action.disabled:active,
.btn.thule-btn-super-action.disabled:active,
.btn.thule-btn-default[disabled]:active,
.btn.thule-btn-link[disabled]:active,
.btn.thule-btn-shop[disabled]:active,
.btn.thule-btn-shop-action[disabled]:active,
.btn.thule-btn-super-action[disabled]:active,
fieldset[disabled] .btn.thule-btn-default:active,
fieldset[disabled] .btn.thule-btn-link:active,
fieldset[disabled] .btn.thule-btn-shop:active,
fieldset[disabled] .btn.thule-btn-shop-action:active,
fieldset[disabled] .btn.thule-btn-super-action:active,
.btn.thule-btn-default.disabled.active,
.btn.thule-btn-link.disabled.active,
.btn.thule-btn-shop.disabled.active,
.btn.thule-btn-shop-action.disabled.active,
.btn.thule-btn-super-action.disabled.active,
.btn.thule-btn-default[disabled].active,
.btn.thule-btn-link[disabled].active,
.btn.thule-btn-shop[disabled].active,
.btn.thule-btn-shop-action[disabled].active,
.btn.thule-btn-super-action[disabled].active,
fieldset[disabled] .btn.thule-btn-default.active,
fieldset[disabled] .btn.thule-btn-link.active,
fieldset[disabled] .btn.thule-btn-shop.active,
fieldset[disabled] .btn.thule-btn-shop-action.active,
fieldset[disabled] .btn.thule-btn-super-action.active {
  color: #757575;
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
}
.btn.thule-btn-default.disabled i,
.btn.thule-btn-link.disabled i,
.btn.thule-btn-shop.disabled i,
.btn.thule-btn-shop-action.disabled i,
.btn.thule-btn-super-action.disabled i,
.btn.thule-btn-default[disabled] i,
.btn.thule-btn-link[disabled] i,
.btn.thule-btn-shop[disabled] i,
.btn.thule-btn-shop-action[disabled] i,
.btn.thule-btn-super-action[disabled] i,
fieldset[disabled] .btn.thule-btn-default i,
fieldset[disabled] .btn.thule-btn-link i,
fieldset[disabled] .btn.thule-btn-shop i,
fieldset[disabled] .btn.thule-btn-shop-action i,
fieldset[disabled] .btn.thule-btn-super-action i,
.btn.thule-btn-default.disabled:hover i,
.btn.thule-btn-link.disabled:hover i,
.btn.thule-btn-shop.disabled:hover i,
.btn.thule-btn-shop-action.disabled:hover i,
.btn.thule-btn-super-action.disabled:hover i,
.btn.thule-btn-default[disabled]:hover i,
.btn.thule-btn-link[disabled]:hover i,
.btn.thule-btn-shop[disabled]:hover i,
.btn.thule-btn-shop-action[disabled]:hover i,
.btn.thule-btn-super-action[disabled]:hover i,
fieldset[disabled] .btn.thule-btn-default:hover i,
fieldset[disabled] .btn.thule-btn-link:hover i,
fieldset[disabled] .btn.thule-btn-shop:hover i,
fieldset[disabled] .btn.thule-btn-shop-action:hover i,
fieldset[disabled] .btn.thule-btn-super-action:hover i,
.btn.thule-btn-default.disabled:focus i,
.btn.thule-btn-link.disabled:focus i,
.btn.thule-btn-shop.disabled:focus i,
.btn.thule-btn-shop-action.disabled:focus i,
.btn.thule-btn-super-action.disabled:focus i,
.btn.thule-btn-default[disabled]:focus i,
.btn.thule-btn-link[disabled]:focus i,
.btn.thule-btn-shop[disabled]:focus i,
.btn.thule-btn-shop-action[disabled]:focus i,
.btn.thule-btn-super-action[disabled]:focus i,
fieldset[disabled] .btn.thule-btn-default:focus i,
fieldset[disabled] .btn.thule-btn-link:focus i,
fieldset[disabled] .btn.thule-btn-shop:focus i,
fieldset[disabled] .btn.thule-btn-shop-action:focus i,
fieldset[disabled] .btn.thule-btn-super-action:focus i,
.btn.thule-btn-default.disabled.focus i,
.btn.thule-btn-link.disabled.focus i,
.btn.thule-btn-shop.disabled.focus i,
.btn.thule-btn-shop-action.disabled.focus i,
.btn.thule-btn-super-action.disabled.focus i,
.btn.thule-btn-default[disabled].focus i,
.btn.thule-btn-link[disabled].focus i,
.btn.thule-btn-shop[disabled].focus i,
.btn.thule-btn-shop-action[disabled].focus i,
.btn.thule-btn-super-action[disabled].focus i,
fieldset[disabled] .btn.thule-btn-default.focus i,
fieldset[disabled] .btn.thule-btn-link.focus i,
fieldset[disabled] .btn.thule-btn-shop.focus i,
fieldset[disabled] .btn.thule-btn-shop-action.focus i,
fieldset[disabled] .btn.thule-btn-super-action.focus i,
.btn.thule-btn-default.disabled:active i,
.btn.thule-btn-link.disabled:active i,
.btn.thule-btn-shop.disabled:active i,
.btn.thule-btn-shop-action.disabled:active i,
.btn.thule-btn-super-action.disabled:active i,
.btn.thule-btn-default[disabled]:active i,
.btn.thule-btn-link[disabled]:active i,
.btn.thule-btn-shop[disabled]:active i,
.btn.thule-btn-shop-action[disabled]:active i,
.btn.thule-btn-super-action[disabled]:active i,
fieldset[disabled] .btn.thule-btn-default:active i,
fieldset[disabled] .btn.thule-btn-link:active i,
fieldset[disabled] .btn.thule-btn-shop:active i,
fieldset[disabled] .btn.thule-btn-shop-action:active i,
fieldset[disabled] .btn.thule-btn-super-action:active i,
.btn.thule-btn-default.disabled.active i,
.btn.thule-btn-link.disabled.active i,
.btn.thule-btn-shop.disabled.active i,
.btn.thule-btn-shop-action.disabled.active i,
.btn.thule-btn-super-action.disabled.active i,
.btn.thule-btn-default[disabled].active i,
.btn.thule-btn-link[disabled].active i,
.btn.thule-btn-shop[disabled].active i,
.btn.thule-btn-shop-action[disabled].active i,
.btn.thule-btn-super-action[disabled].active i,
fieldset[disabled] .btn.thule-btn-default.active i,
fieldset[disabled] .btn.thule-btn-link.active i,
fieldset[disabled] .btn.thule-btn-shop.active i,
fieldset[disabled] .btn.thule-btn-shop-action.active i,
fieldset[disabled] .btn.thule-btn-super-action.active i {
  background-color: #757575;
}
.btn.thule-btn-default .badge,
.btn.thule-btn-link .badge,
.btn.thule-btn-shop .badge,
.btn.thule-btn-shop-action .badge,
.btn.thule-btn-super-action .badge {
  color: #181818;
  background-color: #fff;
}
.btn.thule-btn-default-bg-dark,
.btn.thule-btn-link-bg-dark,
.btn.thule-btn-shop-action-bg-dark {
  color: #181818;
  background-color: #fff;
  border: 1px solid #fff;
}
.btn.thule-btn-default-bg-dark i,
.btn.thule-btn-link-bg-dark i,
.btn.thule-btn-shop-action-bg-dark i {
  background-color: #181818;
}
.btn.thule-btn-default-bg-dark:hover,
.btn.thule-btn-link-bg-dark:hover,
.btn.thule-btn-shop-action-bg-dark:hover {
  color: #181818 !important;
  background-color: #e0e0e0 !important;
  border: 1px solid #e0e0e0 !important;
}
.btn.thule-btn-default-bg-dark:hover i,
.btn.thule-btn-link-bg-dark:hover i,
.btn.thule-btn-shop-action-bg-dark:hover i {
  background-color: #181818 !important;
}
.btn.thule-btn-default-bg-dark:focus,
.btn.thule-btn-link-bg-dark:focus,
.btn.thule-btn-shop-action-bg-dark:focus,
.btn.thule-btn-default-bg-dark.focus,
.btn.thule-btn-link-bg-dark.focus,
.btn.thule-btn-shop-action-bg-dark.focus {
  color: #181818;
  border: 2px solid #6694b5;
  background-color: #fff;
}
.btn.thule-btn-default-bg-dark:active,
.btn.thule-btn-link-bg-dark:active,
.btn.thule-btn-shop-action-bg-dark:active,
.btn.thule-btn-default-bg-dark.active,
.btn.thule-btn-link-bg-dark.active,
.btn.thule-btn-shop-action-bg-dark.active {
  color: #474747 !important;
  background-color: #8c8c8c !important;
  border: 1px solid #8c8c8c !important;
}
.btn.thule-btn-default-bg-dark:active i,
.btn.thule-btn-link-bg-dark:active i,
.btn.thule-btn-shop-action-bg-dark:active i,
.btn.thule-btn-default-bg-dark.active i,
.btn.thule-btn-link-bg-dark.active i,
.btn.thule-btn-shop-action-bg-dark.active i {
  background-color: #474747 !important;
}
.btn.thule-btn-default-bg-dark:active,
.btn.thule-btn-link-bg-dark:active,
.btn.thule-btn-shop-action-bg-dark:active,
.btn.thule-btn-default-bg-dark.active,
.btn.thule-btn-link-bg-dark.active,
.btn.thule-btn-shop-action-bg-dark.active,
.open > .dropdown-toggle.btn.thule-btn-default-bg-dark,
.open > .dropdown-toggle.btn.thule-btn-link-bg-dark,
.open > .dropdown-toggle.btn.thule-btn-shop-action-bg-dark {
  background-image: none;
}
.btn.thule-btn-default-bg-dark.disabled,
.btn.thule-btn-link-bg-dark.disabled,
.btn.thule-btn-shop-action-bg-dark.disabled,
.btn.thule-btn-default-bg-dark[disabled],
.btn.thule-btn-link-bg-dark[disabled],
.btn.thule-btn-shop-action-bg-dark[disabled],
fieldset[disabled] .btn.thule-btn-default-bg-dark,
fieldset[disabled] .btn.thule-btn-link-bg-dark,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark,
.btn.thule-btn-default-bg-dark.disabled:hover,
.btn.thule-btn-link-bg-dark.disabled:hover,
.btn.thule-btn-shop-action-bg-dark.disabled:hover,
.btn.thule-btn-default-bg-dark[disabled]:hover,
.btn.thule-btn-link-bg-dark[disabled]:hover,
.btn.thule-btn-shop-action-bg-dark[disabled]:hover,
fieldset[disabled] .btn.thule-btn-default-bg-dark:hover,
fieldset[disabled] .btn.thule-btn-link-bg-dark:hover,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark:hover,
.btn.thule-btn-default-bg-dark.disabled:focus,
.btn.thule-btn-link-bg-dark.disabled:focus,
.btn.thule-btn-shop-action-bg-dark.disabled:focus,
.btn.thule-btn-default-bg-dark[disabled]:focus,
.btn.thule-btn-link-bg-dark[disabled]:focus,
.btn.thule-btn-shop-action-bg-dark[disabled]:focus,
fieldset[disabled] .btn.thule-btn-default-bg-dark:focus,
fieldset[disabled] .btn.thule-btn-link-bg-dark:focus,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark:focus,
.btn.thule-btn-default-bg-dark.disabled.focus,
.btn.thule-btn-link-bg-dark.disabled.focus,
.btn.thule-btn-shop-action-bg-dark.disabled.focus,
.btn.thule-btn-default-bg-dark[disabled].focus,
.btn.thule-btn-link-bg-dark[disabled].focus,
.btn.thule-btn-shop-action-bg-dark[disabled].focus,
fieldset[disabled] .btn.thule-btn-default-bg-dark.focus,
fieldset[disabled] .btn.thule-btn-link-bg-dark.focus,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark.focus,
.btn.thule-btn-default-bg-dark.disabled:active,
.btn.thule-btn-link-bg-dark.disabled:active,
.btn.thule-btn-shop-action-bg-dark.disabled:active,
.btn.thule-btn-default-bg-dark[disabled]:active,
.btn.thule-btn-link-bg-dark[disabled]:active,
.btn.thule-btn-shop-action-bg-dark[disabled]:active,
fieldset[disabled] .btn.thule-btn-default-bg-dark:active,
fieldset[disabled] .btn.thule-btn-link-bg-dark:active,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark:active,
.btn.thule-btn-default-bg-dark.disabled.active,
.btn.thule-btn-link-bg-dark.disabled.active,
.btn.thule-btn-shop-action-bg-dark.disabled.active,
.btn.thule-btn-default-bg-dark[disabled].active,
.btn.thule-btn-link-bg-dark[disabled].active,
.btn.thule-btn-shop-action-bg-dark[disabled].active,
fieldset[disabled] .btn.thule-btn-default-bg-dark.active,
fieldset[disabled] .btn.thule-btn-link-bg-dark.active,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark.active {
  color: #757575;
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
}
.btn.thule-btn-default-bg-dark.disabled i,
.btn.thule-btn-link-bg-dark.disabled i,
.btn.thule-btn-shop-action-bg-dark.disabled i,
.btn.thule-btn-default-bg-dark[disabled] i,
.btn.thule-btn-link-bg-dark[disabled] i,
.btn.thule-btn-shop-action-bg-dark[disabled] i,
fieldset[disabled] .btn.thule-btn-default-bg-dark i,
fieldset[disabled] .btn.thule-btn-link-bg-dark i,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark i,
.btn.thule-btn-default-bg-dark.disabled:hover i,
.btn.thule-btn-link-bg-dark.disabled:hover i,
.btn.thule-btn-shop-action-bg-dark.disabled:hover i,
.btn.thule-btn-default-bg-dark[disabled]:hover i,
.btn.thule-btn-link-bg-dark[disabled]:hover i,
.btn.thule-btn-shop-action-bg-dark[disabled]:hover i,
fieldset[disabled] .btn.thule-btn-default-bg-dark:hover i,
fieldset[disabled] .btn.thule-btn-link-bg-dark:hover i,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark:hover i,
.btn.thule-btn-default-bg-dark.disabled:focus i,
.btn.thule-btn-link-bg-dark.disabled:focus i,
.btn.thule-btn-shop-action-bg-dark.disabled:focus i,
.btn.thule-btn-default-bg-dark[disabled]:focus i,
.btn.thule-btn-link-bg-dark[disabled]:focus i,
.btn.thule-btn-shop-action-bg-dark[disabled]:focus i,
fieldset[disabled] .btn.thule-btn-default-bg-dark:focus i,
fieldset[disabled] .btn.thule-btn-link-bg-dark:focus i,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark:focus i,
.btn.thule-btn-default-bg-dark.disabled.focus i,
.btn.thule-btn-link-bg-dark.disabled.focus i,
.btn.thule-btn-shop-action-bg-dark.disabled.focus i,
.btn.thule-btn-default-bg-dark[disabled].focus i,
.btn.thule-btn-link-bg-dark[disabled].focus i,
.btn.thule-btn-shop-action-bg-dark[disabled].focus i,
fieldset[disabled] .btn.thule-btn-default-bg-dark.focus i,
fieldset[disabled] .btn.thule-btn-link-bg-dark.focus i,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark.focus i,
.btn.thule-btn-default-bg-dark.disabled:active i,
.btn.thule-btn-link-bg-dark.disabled:active i,
.btn.thule-btn-shop-action-bg-dark.disabled:active i,
.btn.thule-btn-default-bg-dark[disabled]:active i,
.btn.thule-btn-link-bg-dark[disabled]:active i,
.btn.thule-btn-shop-action-bg-dark[disabled]:active i,
fieldset[disabled] .btn.thule-btn-default-bg-dark:active i,
fieldset[disabled] .btn.thule-btn-link-bg-dark:active i,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark:active i,
.btn.thule-btn-default-bg-dark.disabled.active i,
.btn.thule-btn-link-bg-dark.disabled.active i,
.btn.thule-btn-shop-action-bg-dark.disabled.active i,
.btn.thule-btn-default-bg-dark[disabled].active i,
.btn.thule-btn-link-bg-dark[disabled].active i,
.btn.thule-btn-shop-action-bg-dark[disabled].active i,
fieldset[disabled] .btn.thule-btn-default-bg-dark.active i,
fieldset[disabled] .btn.thule-btn-link-bg-dark.active i,
fieldset[disabled] .btn.thule-btn-shop-action-bg-dark.active i {
  background-color: #757575;
}
.btn.thule-btn-default-bg-dark .badge,
.btn.thule-btn-link-bg-dark .badge,
.btn.thule-btn-shop-action-bg-dark .badge {
  color: #fff;
  background-color: #181818;
}
.btn.thule-btn-secondary,
.btn.thule-btn-link-secondary {
  color: #181818;
  background-color: transparent;
  border: 1px solid #757575;
}
.btn.thule-btn-secondary i,
.btn.thule-btn-link-secondary i {
  background-color: #181818;
}
.btn.thule-btn-secondary:hover,
.btn.thule-btn-link-secondary:hover {
  color: #fff !important;
  background-color: #303030 !important;
  border: 1px solid #303030 !important;
}
.btn.thule-btn-secondary:hover i,
.btn.thule-btn-link-secondary:hover i {
  background-color: #fff !important;
}
.btn.thule-btn-secondary:focus,
.btn.thule-btn-link-secondary:focus,
.btn.thule-btn-secondary.focus,
.btn.thule-btn-link-secondary.focus {
  color: #181818;
  border: 2px solid #6694b5;
  background-color: transparent;
}
.btn.thule-btn-secondary:active,
.btn.thule-btn-link-secondary:active,
.btn.thule-btn-secondary.active,
.btn.thule-btn-link-secondary.active {
  color: #474747 !important;
  background-color: #e0e0e0 !important;
  border: 1px solid #e0e0e0 !important;
}
.btn.thule-btn-secondary:active i,
.btn.thule-btn-link-secondary:active i,
.btn.thule-btn-secondary.active i,
.btn.thule-btn-link-secondary.active i {
  background-color: #474747 !important;
}
.btn.thule-btn-secondary:active,
.btn.thule-btn-link-secondary:active,
.btn.thule-btn-secondary.active,
.btn.thule-btn-link-secondary.active,
.open > .dropdown-toggle.btn.thule-btn-secondary,
.open > .dropdown-toggle.btn.thule-btn-link-secondary {
  background-image: none;
}
.btn.thule-btn-secondary.disabled,
.btn.thule-btn-link-secondary.disabled,
.btn.thule-btn-secondary[disabled],
.btn.thule-btn-link-secondary[disabled],
fieldset[disabled] .btn.thule-btn-secondary,
fieldset[disabled] .btn.thule-btn-link-secondary,
.btn.thule-btn-secondary.disabled:hover,
.btn.thule-btn-link-secondary.disabled:hover,
.btn.thule-btn-secondary[disabled]:hover,
.btn.thule-btn-link-secondary[disabled]:hover,
fieldset[disabled] .btn.thule-btn-secondary:hover,
fieldset[disabled] .btn.thule-btn-link-secondary:hover,
.btn.thule-btn-secondary.disabled:focus,
.btn.thule-btn-link-secondary.disabled:focus,
.btn.thule-btn-secondary[disabled]:focus,
.btn.thule-btn-link-secondary[disabled]:focus,
fieldset[disabled] .btn.thule-btn-secondary:focus,
fieldset[disabled] .btn.thule-btn-link-secondary:focus,
.btn.thule-btn-secondary.disabled.focus,
.btn.thule-btn-link-secondary.disabled.focus,
.btn.thule-btn-secondary[disabled].focus,
.btn.thule-btn-link-secondary[disabled].focus,
fieldset[disabled] .btn.thule-btn-secondary.focus,
fieldset[disabled] .btn.thule-btn-link-secondary.focus,
.btn.thule-btn-secondary.disabled:active,
.btn.thule-btn-link-secondary.disabled:active,
.btn.thule-btn-secondary[disabled]:active,
.btn.thule-btn-link-secondary[disabled]:active,
fieldset[disabled] .btn.thule-btn-secondary:active,
fieldset[disabled] .btn.thule-btn-link-secondary:active,
.btn.thule-btn-secondary.disabled.active,
.btn.thule-btn-link-secondary.disabled.active,
.btn.thule-btn-secondary[disabled].active,
.btn.thule-btn-link-secondary[disabled].active,
fieldset[disabled] .btn.thule-btn-secondary.active,
fieldset[disabled] .btn.thule-btn-link-secondary.active {
  color: #757575;
  background-color: transparent;
  border: 1px solid #e0e0e0;
}
.btn.thule-btn-secondary.disabled i,
.btn.thule-btn-link-secondary.disabled i,
.btn.thule-btn-secondary[disabled] i,
.btn.thule-btn-link-secondary[disabled] i,
fieldset[disabled] .btn.thule-btn-secondary i,
fieldset[disabled] .btn.thule-btn-link-secondary i,
.btn.thule-btn-secondary.disabled:hover i,
.btn.thule-btn-link-secondary.disabled:hover i,
.btn.thule-btn-secondary[disabled]:hover i,
.btn.thule-btn-link-secondary[disabled]:hover i,
fieldset[disabled] .btn.thule-btn-secondary:hover i,
fieldset[disabled] .btn.thule-btn-link-secondary:hover i,
.btn.thule-btn-secondary.disabled:focus i,
.btn.thule-btn-link-secondary.disabled:focus i,
.btn.thule-btn-secondary[disabled]:focus i,
.btn.thule-btn-link-secondary[disabled]:focus i,
fieldset[disabled] .btn.thule-btn-secondary:focus i,
fieldset[disabled] .btn.thule-btn-link-secondary:focus i,
.btn.thule-btn-secondary.disabled.focus i,
.btn.thule-btn-link-secondary.disabled.focus i,
.btn.thule-btn-secondary[disabled].focus i,
.btn.thule-btn-link-secondary[disabled].focus i,
fieldset[disabled] .btn.thule-btn-secondary.focus i,
fieldset[disabled] .btn.thule-btn-link-secondary.focus i,
.btn.thule-btn-secondary.disabled:active i,
.btn.thule-btn-link-secondary.disabled:active i,
.btn.thule-btn-secondary[disabled]:active i,
.btn.thule-btn-link-secondary[disabled]:active i,
fieldset[disabled] .btn.thule-btn-secondary:active i,
fieldset[disabled] .btn.thule-btn-link-secondary:active i,
.btn.thule-btn-secondary.disabled.active i,
.btn.thule-btn-link-secondary.disabled.active i,
.btn.thule-btn-secondary[disabled].active i,
.btn.thule-btn-link-secondary[disabled].active i,
fieldset[disabled] .btn.thule-btn-secondary.active i,
fieldset[disabled] .btn.thule-btn-link-secondary.active i {
  background-color: #757575;
}
.btn.thule-btn-secondary .badge,
.btn.thule-btn-link-secondary .badge {
  color: transparent;
  background-color: #181818;
}
.btn.thule-btn-secondary-bg-dark,
.btn.thule-btn-link-secondary-bg-dark {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
}
.btn.thule-btn-secondary-bg-dark i,
.btn.thule-btn-link-secondary-bg-dark i {
  background-color: #fff;
}
.btn.thule-btn-secondary-bg-dark:hover,
.btn.thule-btn-link-secondary-bg-dark:hover {
  color: #181818 !important;
  background-color: #e0e0e0 !important;
  border: 1px solid #e0e0e0 !important;
}
.btn.thule-btn-secondary-bg-dark:hover i,
.btn.thule-btn-link-secondary-bg-dark:hover i {
  background-color: #181818 !important;
}
.btn.thule-btn-secondary-bg-dark:focus,
.btn.thule-btn-link-secondary-bg-dark:focus,
.btn.thule-btn-secondary-bg-dark.focus,
.btn.thule-btn-link-secondary-bg-dark.focus {
  color: #fff;
  border: 2px solid #6694b5;
  background-color: transparent;
}
.btn.thule-btn-secondary-bg-dark:active,
.btn.thule-btn-link-secondary-bg-dark:active,
.btn.thule-btn-secondary-bg-dark.active,
.btn.thule-btn-link-secondary-bg-dark.active {
  color: #474747 !important;
  background-color: #8c8c8c !important;
  border: 1px solid #8c8c8c !important;
}
.btn.thule-btn-secondary-bg-dark:active i,
.btn.thule-btn-link-secondary-bg-dark:active i,
.btn.thule-btn-secondary-bg-dark.active i,
.btn.thule-btn-link-secondary-bg-dark.active i {
  background-color: #474747 !important;
}
.btn.thule-btn-secondary-bg-dark:active,
.btn.thule-btn-link-secondary-bg-dark:active,
.btn.thule-btn-secondary-bg-dark.active,
.btn.thule-btn-link-secondary-bg-dark.active,
.open > .dropdown-toggle.btn.thule-btn-secondary-bg-dark,
.open > .dropdown-toggle.btn.thule-btn-link-secondary-bg-dark {
  background-image: none;
}
.btn.thule-btn-secondary-bg-dark.disabled,
.btn.thule-btn-link-secondary-bg-dark.disabled,
.btn.thule-btn-secondary-bg-dark[disabled],
.btn.thule-btn-link-secondary-bg-dark[disabled],
fieldset[disabled] .btn.thule-btn-secondary-bg-dark,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark,
.btn.thule-btn-secondary-bg-dark.disabled:hover,
.btn.thule-btn-link-secondary-bg-dark.disabled:hover,
.btn.thule-btn-secondary-bg-dark[disabled]:hover,
.btn.thule-btn-link-secondary-bg-dark[disabled]:hover,
fieldset[disabled] .btn.thule-btn-secondary-bg-dark:hover,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark:hover,
.btn.thule-btn-secondary-bg-dark.disabled:focus,
.btn.thule-btn-link-secondary-bg-dark.disabled:focus,
.btn.thule-btn-secondary-bg-dark[disabled]:focus,
.btn.thule-btn-link-secondary-bg-dark[disabled]:focus,
fieldset[disabled] .btn.thule-btn-secondary-bg-dark:focus,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark:focus,
.btn.thule-btn-secondary-bg-dark.disabled.focus,
.btn.thule-btn-link-secondary-bg-dark.disabled.focus,
.btn.thule-btn-secondary-bg-dark[disabled].focus,
.btn.thule-btn-link-secondary-bg-dark[disabled].focus,
fieldset[disabled] .btn.thule-btn-secondary-bg-dark.focus,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark.focus,
.btn.thule-btn-secondary-bg-dark.disabled:active,
.btn.thule-btn-link-secondary-bg-dark.disabled:active,
.btn.thule-btn-secondary-bg-dark[disabled]:active,
.btn.thule-btn-link-secondary-bg-dark[disabled]:active,
fieldset[disabled] .btn.thule-btn-secondary-bg-dark:active,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark:active,
.btn.thule-btn-secondary-bg-dark.disabled.active,
.btn.thule-btn-link-secondary-bg-dark.disabled.active,
.btn.thule-btn-secondary-bg-dark[disabled].active,
.btn.thule-btn-link-secondary-bg-dark[disabled].active,
fieldset[disabled] .btn.thule-btn-secondary-bg-dark.active,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark.active {
  color: #757575;
  background-color: transparent;
  border: 1px solid #474747;
}
.btn.thule-btn-secondary-bg-dark.disabled i,
.btn.thule-btn-link-secondary-bg-dark.disabled i,
.btn.thule-btn-secondary-bg-dark[disabled] i,
.btn.thule-btn-link-secondary-bg-dark[disabled] i,
fieldset[disabled] .btn.thule-btn-secondary-bg-dark i,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark i,
.btn.thule-btn-secondary-bg-dark.disabled:hover i,
.btn.thule-btn-link-secondary-bg-dark.disabled:hover i,
.btn.thule-btn-secondary-bg-dark[disabled]:hover i,
.btn.thule-btn-link-secondary-bg-dark[disabled]:hover i,
fieldset[disabled] .btn.thule-btn-secondary-bg-dark:hover i,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark:hover i,
.btn.thule-btn-secondary-bg-dark.disabled:focus i,
.btn.thule-btn-link-secondary-bg-dark.disabled:focus i,
.btn.thule-btn-secondary-bg-dark[disabled]:focus i,
.btn.thule-btn-link-secondary-bg-dark[disabled]:focus i,
fieldset[disabled] .btn.thule-btn-secondary-bg-dark:focus i,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark:focus i,
.btn.thule-btn-secondary-bg-dark.disabled.focus i,
.btn.thule-btn-link-secondary-bg-dark.disabled.focus i,
.btn.thule-btn-secondary-bg-dark[disabled].focus i,
.btn.thule-btn-link-secondary-bg-dark[disabled].focus i,
fieldset[disabled] .btn.thule-btn-secondary-bg-dark.focus i,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark.focus i,
.btn.thule-btn-secondary-bg-dark.disabled:active i,
.btn.thule-btn-link-secondary-bg-dark.disabled:active i,
.btn.thule-btn-secondary-bg-dark[disabled]:active i,
.btn.thule-btn-link-secondary-bg-dark[disabled]:active i,
fieldset[disabled] .btn.thule-btn-secondary-bg-dark:active i,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark:active i,
.btn.thule-btn-secondary-bg-dark.disabled.active i,
.btn.thule-btn-link-secondary-bg-dark.disabled.active i,
.btn.thule-btn-secondary-bg-dark[disabled].active i,
.btn.thule-btn-link-secondary-bg-dark[disabled].active i,
fieldset[disabled] .btn.thule-btn-secondary-bg-dark.active i,
fieldset[disabled] .btn.thule-btn-link-secondary-bg-dark.active i {
  background-color: #757575;
}
.btn.thule-btn-secondary-bg-dark .badge,
.btn.thule-btn-link-secondary-bg-dark .badge {
  color: transparent;
  background-color: #fff;
}
.btn.thule-btn-tertiary {
  color: #181818;
  background-color: transparent;
  border: 1px solid transparent;
  text-decoration: underline;
  font-weight: 400 !important;
  text-underline-offset: 4px;
}
.btn.thule-btn-tertiary i {
  background-color: #181818;
}
.btn.thule-btn-tertiary:hover {
  color: #181818 !important;
  background-color: #f5f5f5 !important;
  border: 1px solid #f5f5f5 !important;
}
.btn.thule-btn-tertiary:hover i {
  background-color: #181818 !important;
}
.btn.thule-btn-tertiary:focus,
.btn.thule-btn-tertiary.focus {
  color: #181818;
  border: 2px solid #6694b5;
  background-color: transparent;
}
.btn.thule-btn-tertiary:active,
.btn.thule-btn-tertiary.active {
  color: #5e5e5e !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.btn.thule-btn-tertiary:active i,
.btn.thule-btn-tertiary.active i {
  background-color: #5e5e5e !important;
}
.btn.thule-btn-tertiary:active,
.btn.thule-btn-tertiary.active,
.open > .dropdown-toggle.btn.thule-btn-tertiary {
  background-image: none;
}
.btn.thule-btn-tertiary.disabled,
.btn.thule-btn-tertiary[disabled],
fieldset[disabled] .btn.thule-btn-tertiary,
.btn.thule-btn-tertiary.disabled:hover,
.btn.thule-btn-tertiary[disabled]:hover,
fieldset[disabled] .btn.thule-btn-tertiary:hover,
.btn.thule-btn-tertiary.disabled:focus,
.btn.thule-btn-tertiary[disabled]:focus,
fieldset[disabled] .btn.thule-btn-tertiary:focus,
.btn.thule-btn-tertiary.disabled.focus,
.btn.thule-btn-tertiary[disabled].focus,
fieldset[disabled] .btn.thule-btn-tertiary.focus,
.btn.thule-btn-tertiary.disabled:active,
.btn.thule-btn-tertiary[disabled]:active,
fieldset[disabled] .btn.thule-btn-tertiary:active,
.btn.thule-btn-tertiary.disabled.active,
.btn.thule-btn-tertiary[disabled].active,
fieldset[disabled] .btn.thule-btn-tertiary.active {
  color: #757575;
  background-color: transparent;
  border: 1px solid transparent;
}
.btn.thule-btn-tertiary.disabled i,
.btn.thule-btn-tertiary[disabled] i,
fieldset[disabled] .btn.thule-btn-tertiary i,
.btn.thule-btn-tertiary.disabled:hover i,
.btn.thule-btn-tertiary[disabled]:hover i,
fieldset[disabled] .btn.thule-btn-tertiary:hover i,
.btn.thule-btn-tertiary.disabled:focus i,
.btn.thule-btn-tertiary[disabled]:focus i,
fieldset[disabled] .btn.thule-btn-tertiary:focus i,
.btn.thule-btn-tertiary.disabled.focus i,
.btn.thule-btn-tertiary[disabled].focus i,
fieldset[disabled] .btn.thule-btn-tertiary.focus i,
.btn.thule-btn-tertiary.disabled:active i,
.btn.thule-btn-tertiary[disabled]:active i,
fieldset[disabled] .btn.thule-btn-tertiary:active i,
.btn.thule-btn-tertiary.disabled.active i,
.btn.thule-btn-tertiary[disabled].active i,
fieldset[disabled] .btn.thule-btn-tertiary.active i {
  background-color: #757575;
}
.btn.thule-btn-tertiary .badge {
  color: transparent;
  background-color: #181818;
}
.btn.thule-btn-tertiary-bg-dark,
.btn.thule-btn-link-tertiary-bg-dark {
  color: #fff;
  background-color: transparent;
  border: 1px solid transparent;
}
.btn.thule-btn-tertiary-bg-dark i,
.btn.thule-btn-link-tertiary-bg-dark i {
  background-color: #fff;
}
.btn.thule-btn-tertiary-bg-dark:hover,
.btn.thule-btn-link-tertiary-bg-dark:hover {
  color: #fff !important;
  background-color: #303030 !important;
  border: 1px solid #303030 !important;
}
.btn.thule-btn-tertiary-bg-dark:hover i,
.btn.thule-btn-link-tertiary-bg-dark:hover i {
  background-color: #fff !important;
}
.btn.thule-btn-tertiary-bg-dark:focus,
.btn.thule-btn-link-tertiary-bg-dark:focus,
.btn.thule-btn-tertiary-bg-dark.focus,
.btn.thule-btn-link-tertiary-bg-dark.focus {
  color: #fff;
  border: 2px solid #6694b5;
  background-color: transparent;
}
.btn.thule-btn-tertiary-bg-dark:active,
.btn.thule-btn-link-tertiary-bg-dark:active,
.btn.thule-btn-tertiary-bg-dark.active,
.btn.thule-btn-link-tertiary-bg-dark.active {
  color: #bababa !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.btn.thule-btn-tertiary-bg-dark:active i,
.btn.thule-btn-link-tertiary-bg-dark:active i,
.btn.thule-btn-tertiary-bg-dark.active i,
.btn.thule-btn-link-tertiary-bg-dark.active i {
  background-color: #bababa !important;
}
.btn.thule-btn-tertiary-bg-dark:active,
.btn.thule-btn-link-tertiary-bg-dark:active,
.btn.thule-btn-tertiary-bg-dark.active,
.btn.thule-btn-link-tertiary-bg-dark.active,
.open > .dropdown-toggle.btn.thule-btn-tertiary-bg-dark,
.open > .dropdown-toggle.btn.thule-btn-link-tertiary-bg-dark {
  background-image: none;
}
.btn.thule-btn-tertiary-bg-dark.disabled,
.btn.thule-btn-link-tertiary-bg-dark.disabled,
.btn.thule-btn-tertiary-bg-dark[disabled],
.btn.thule-btn-link-tertiary-bg-dark[disabled],
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark,
.btn.thule-btn-tertiary-bg-dark.disabled:hover,
.btn.thule-btn-link-tertiary-bg-dark.disabled:hover,
.btn.thule-btn-tertiary-bg-dark[disabled]:hover,
.btn.thule-btn-link-tertiary-bg-dark[disabled]:hover,
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark:hover,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark:hover,
.btn.thule-btn-tertiary-bg-dark.disabled:focus,
.btn.thule-btn-link-tertiary-bg-dark.disabled:focus,
.btn.thule-btn-tertiary-bg-dark[disabled]:focus,
.btn.thule-btn-link-tertiary-bg-dark[disabled]:focus,
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark:focus,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark:focus,
.btn.thule-btn-tertiary-bg-dark.disabled.focus,
.btn.thule-btn-link-tertiary-bg-dark.disabled.focus,
.btn.thule-btn-tertiary-bg-dark[disabled].focus,
.btn.thule-btn-link-tertiary-bg-dark[disabled].focus,
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark.focus,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark.focus,
.btn.thule-btn-tertiary-bg-dark.disabled:active,
.btn.thule-btn-link-tertiary-bg-dark.disabled:active,
.btn.thule-btn-tertiary-bg-dark[disabled]:active,
.btn.thule-btn-link-tertiary-bg-dark[disabled]:active,
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark:active,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark:active,
.btn.thule-btn-tertiary-bg-dark.disabled.active,
.btn.thule-btn-link-tertiary-bg-dark.disabled.active,
.btn.thule-btn-tertiary-bg-dark[disabled].active,
.btn.thule-btn-link-tertiary-bg-dark[disabled].active,
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark.active,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark.active {
  color: #757575;
  background-color: transparent;
  border: 1px solid transparent;
}
.btn.thule-btn-tertiary-bg-dark.disabled i,
.btn.thule-btn-link-tertiary-bg-dark.disabled i,
.btn.thule-btn-tertiary-bg-dark[disabled] i,
.btn.thule-btn-link-tertiary-bg-dark[disabled] i,
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark i,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark i,
.btn.thule-btn-tertiary-bg-dark.disabled:hover i,
.btn.thule-btn-link-tertiary-bg-dark.disabled:hover i,
.btn.thule-btn-tertiary-bg-dark[disabled]:hover i,
.btn.thule-btn-link-tertiary-bg-dark[disabled]:hover i,
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark:hover i,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark:hover i,
.btn.thule-btn-tertiary-bg-dark.disabled:focus i,
.btn.thule-btn-link-tertiary-bg-dark.disabled:focus i,
.btn.thule-btn-tertiary-bg-dark[disabled]:focus i,
.btn.thule-btn-link-tertiary-bg-dark[disabled]:focus i,
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark:focus i,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark:focus i,
.btn.thule-btn-tertiary-bg-dark.disabled.focus i,
.btn.thule-btn-link-tertiary-bg-dark.disabled.focus i,
.btn.thule-btn-tertiary-bg-dark[disabled].focus i,
.btn.thule-btn-link-tertiary-bg-dark[disabled].focus i,
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark.focus i,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark.focus i,
.btn.thule-btn-tertiary-bg-dark.disabled:active i,
.btn.thule-btn-link-tertiary-bg-dark.disabled:active i,
.btn.thule-btn-tertiary-bg-dark[disabled]:active i,
.btn.thule-btn-link-tertiary-bg-dark[disabled]:active i,
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark:active i,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark:active i,
.btn.thule-btn-tertiary-bg-dark.disabled.active i,
.btn.thule-btn-link-tertiary-bg-dark.disabled.active i,
.btn.thule-btn-tertiary-bg-dark[disabled].active i,
.btn.thule-btn-link-tertiary-bg-dark[disabled].active i,
fieldset[disabled] .btn.thule-btn-tertiary-bg-dark.active i,
fieldset[disabled] .btn.thule-btn-link-tertiary-bg-dark.active i {
  background-color: #757575;
}
.btn.thule-btn-tertiary-bg-dark .badge,
.btn.thule-btn-link-tertiary-bg-dark .badge {
  color: transparent;
  background-color: #fff;
}
.btn.thule-btn-tertiary-bg-dark {
  text-decoration: underline;
  font-weight: 400 !important;
}
.btn.thule-btn-tertiary,
.btn.thule-btn-tertiary-bg-dark {
  min-width: unset;
}
.btn.thule-btn-link:focus,
.btn.thule-btn-link.focus {
  border: 1px solid #181818;
}
.btn.thule-btn-link-bg-dark:focus,
.btn.thule-btn-link-bg-dark.focus {
  border: 1px solid #fff;
}
.btn.thule-btn-link-secondary:focus,
.btn.thule-btn-link-secondary.focus {
  border: 1px solid #757575;
}
.btn.thule-btn-link-secondary-bg-dark:focus,
.btn.thule-btn-link-secondary-bg-dark.focus {
  border: 1px solid #fff;
}
.btn.thule-btn-link-tertiary,
.btn.thule-btn-link-tertiary-bg-dark {
  background-color: transparent;
  min-width: unset;
}
.btn.thule-btn-link-tertiary {
  padding-left: 0;
  padding-right: 0;
  color: #181818;
}
.btn.thule-btn-link-tertiary i {
  background-color: #181818;
}
.btn.thule-btn-link-tertiary-bg-dark {
  color: #fff;
}
.btn.thule-btn-link-tertiary-bg-dark i {
  background-color: #fff;
  margin-left: 0.8rem;
}
.btn.thule-btn-link,
.btn.thule-btn-link-bg-dark,
.btn.thule-btn-link-secondary,
.btn.thule-btn-link-secondary-bg-dark,
.btn.thule-btn-link-tertiary-bg-dark {
  justify-content: space-between;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
.btn.thule-btn-link,
.btn.thule-btn-link-bg-dark,
.btn.thule-btn-link-secondary,
.btn.thule-btn-link-secondary-bg-dark,
.btn.thule-btn-link-tertiary {
  display: flex;
  align-items: center;
}
.btn.thule-btn-link i,
.btn.thule-btn-link-bg-dark i,
.btn.thule-btn-link-secondary i,
.btn.thule-btn-link-secondary-bg-dark i,
.btn.thule-btn-link-tertiary i {
  margin-left: 1.6rem;
}
.btn.thule-btn-account {
  color: white;
  background-color: #575757;
  border: 1px solid #575757;
}
.btn.thule-btn-account i {
  background-color: white;
}
.btn.thule-btn-account:hover {
  color: white !important;
  background-color: #575757 !important;
  border: 1px solid #575757 !important;
}
.btn.thule-btn-account:hover i {
  background-color: white !important;
}
.btn.thule-btn-account:focus,
.btn.thule-btn-account.focus {
  color: white;
  border: 2px solid #6694b5;
  background-color: #575757;
}
.btn.thule-btn-account:active,
.btn.thule-btn-account.active {
  color: white !important;
  background-color: #575757 !important;
  border: 1px solid #575757 !important;
}
.btn.thule-btn-account:active i,
.btn.thule-btn-account.active i {
  background-color: white !important;
}
.btn.thule-btn-account:active,
.btn.thule-btn-account.active,
.open > .dropdown-toggle.btn.thule-btn-account {
  background-image: none;
}
.btn.thule-btn-account.disabled,
.btn.thule-btn-account[disabled],
fieldset[disabled] .btn.thule-btn-account,
.btn.thule-btn-account.disabled:hover,
.btn.thule-btn-account[disabled]:hover,
fieldset[disabled] .btn.thule-btn-account:hover,
.btn.thule-btn-account.disabled:focus,
.btn.thule-btn-account[disabled]:focus,
fieldset[disabled] .btn.thule-btn-account:focus,
.btn.thule-btn-account.disabled.focus,
.btn.thule-btn-account[disabled].focus,
fieldset[disabled] .btn.thule-btn-account.focus,
.btn.thule-btn-account.disabled:active,
.btn.thule-btn-account[disabled]:active,
fieldset[disabled] .btn.thule-btn-account:active,
.btn.thule-btn-account.disabled.active,
.btn.thule-btn-account[disabled].active,
fieldset[disabled] .btn.thule-btn-account.active {
  color: white;
  background-color: #575757;
  border: 1px solid #575757;
}
.btn.thule-btn-account.disabled i,
.btn.thule-btn-account[disabled] i,
fieldset[disabled] .btn.thule-btn-account i,
.btn.thule-btn-account.disabled:hover i,
.btn.thule-btn-account[disabled]:hover i,
fieldset[disabled] .btn.thule-btn-account:hover i,
.btn.thule-btn-account.disabled:focus i,
.btn.thule-btn-account[disabled]:focus i,
fieldset[disabled] .btn.thule-btn-account:focus i,
.btn.thule-btn-account.disabled.focus i,
.btn.thule-btn-account[disabled].focus i,
fieldset[disabled] .btn.thule-btn-account.focus i,
.btn.thule-btn-account.disabled:active i,
.btn.thule-btn-account[disabled]:active i,
fieldset[disabled] .btn.thule-btn-account:active i,
.btn.thule-btn-account.disabled.active i,
.btn.thule-btn-account[disabled].active i,
fieldset[disabled] .btn.thule-btn-account.active i {
  background-color: white;
}
.btn.thule-btn-account .badge {
  color: #575757;
  background-color: white;
}
.btn.thule-btn-account:hover,
.btn.thule-btn-account:focus,
.btn.thule-btn-account.focus,
.btn.thule-btn-account:active,
.btn.thule-btn-account.active,
.open > .dropdown-toggle.btn.thule-btn-account {
  background-color: #383838;
}
.btn.thule-btn-account.disabled {
  border: 1px solid #575757;
  opacity: 1;
}
.btn.thule-btn-variation {
  color: #575757;
  background-color: white;
  border: 1px solid white;
  font-size: 1.4rem;
  padding: 0.1rem 2.4rem;
  margin-bottom: 0.5rem;
  min-width: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}
.btn.thule-btn-variation i {
  background-color: #575757;
}
.btn.thule-btn-variation:hover {
  color: #575757 !important;
  background-color: white !important;
  border: 1px solid white !important;
}
.btn.thule-btn-variation:hover i {
  background-color: #575757 !important;
}
.btn.thule-btn-variation:focus,
.btn.thule-btn-variation.focus {
  color: #575757;
  border: 2px solid #6694b5;
  background-color: white;
}
.btn.thule-btn-variation:active,
.btn.thule-btn-variation.active {
  color: #575757 !important;
  background-color: white !important;
  border: 1px solid white !important;
}
.btn.thule-btn-variation:active i,
.btn.thule-btn-variation.active i {
  background-color: #575757 !important;
}
.btn.thule-btn-variation:active,
.btn.thule-btn-variation.active,
.open > .dropdown-toggle.btn.thule-btn-variation {
  background-image: none;
}
.btn.thule-btn-variation.disabled,
.btn.thule-btn-variation[disabled],
fieldset[disabled] .btn.thule-btn-variation,
.btn.thule-btn-variation.disabled:hover,
.btn.thule-btn-variation[disabled]:hover,
fieldset[disabled] .btn.thule-btn-variation:hover,
.btn.thule-btn-variation.disabled:focus,
.btn.thule-btn-variation[disabled]:focus,
fieldset[disabled] .btn.thule-btn-variation:focus,
.btn.thule-btn-variation.disabled.focus,
.btn.thule-btn-variation[disabled].focus,
fieldset[disabled] .btn.thule-btn-variation.focus,
.btn.thule-btn-variation.disabled:active,
.btn.thule-btn-variation[disabled]:active,
fieldset[disabled] .btn.thule-btn-variation:active,
.btn.thule-btn-variation.disabled.active,
.btn.thule-btn-variation[disabled].active,
fieldset[disabled] .btn.thule-btn-variation.active {
  color: #575757;
  background-color: white;
  border: 1px solid white;
}
.btn.thule-btn-variation.disabled i,
.btn.thule-btn-variation[disabled] i,
fieldset[disabled] .btn.thule-btn-variation i,
.btn.thule-btn-variation.disabled:hover i,
.btn.thule-btn-variation[disabled]:hover i,
fieldset[disabled] .btn.thule-btn-variation:hover i,
.btn.thule-btn-variation.disabled:focus i,
.btn.thule-btn-variation[disabled]:focus i,
fieldset[disabled] .btn.thule-btn-variation:focus i,
.btn.thule-btn-variation.disabled.focus i,
.btn.thule-btn-variation[disabled].focus i,
fieldset[disabled] .btn.thule-btn-variation.focus i,
.btn.thule-btn-variation.disabled:active i,
.btn.thule-btn-variation[disabled]:active i,
fieldset[disabled] .btn.thule-btn-variation:active i,
.btn.thule-btn-variation.disabled.active i,
.btn.thule-btn-variation[disabled].active i,
fieldset[disabled] .btn.thule-btn-variation.active i {
  background-color: #575757;
}
.btn.thule-btn-variation .badge {
  color: white;
  background-color: #575757;
}
.btn.thule-btn-variation.active {
  border: 1px #181818 solid !important;
  cursor: default;
  background-color: #f5f5f5 !important;
}
.btn.thule-btn-variation:hover:not(.active) {
  background-color: #f5f5f5 !important;
}
.btn.toggle-button {
  background: #fef5e7;
  color: #574f4f;
  border: 1px solid #8a7d7d;
  border-radius: 4px;
  font-size: 1.4rem;
  padding: 0.5rem 1.3rem;
}
.btn.toggle-button.selected {
  font-weight: bold;
  border-color: #597b5a;
  box-shadow: 0 0 2px 1px #c1cebd;
}
.btn.toggle-button:not(:disabled):hover {
  background-color: #f2ebe0;
}
.btn.toggle-button:not(:disabled):active {
  box-shadow: 0 0 2px 1px #c1cebd;
  border-color: #574f4f;
}
.btn.toggle-button:disabled {
  cursor: not-allowed;
}
@media screen and (max-width: 576px) {
  .btn.wrap {
    white-space: normal;
  }
}
.btn.thule-btn-classic-link {
  padding: 0;
  min-height: 0;
  min-width: 0;
  text-decoration: underline;
}
.badge--base,
.badge--Informative,
.badge--Commercial {
  padding: 0.2rem 0.6rem;
  width: max-content;
}
@media screen and (min-width: 768px) {
  .badge--base,
  .badge--Informative,
  .badge--Commercial {
    padding: 0.2rem 0.8rem;
  }
}
.badge--Informative,
.badge--Commercial {
  position: absolute;
}
.badge--Informative {
  background-color: #fff;
  color: #181818;
  border: 0.05rem #e0e0e0 solid;
}
.badge--Commercial {
  background-color: #474747;
  color: #fff;
}
.badge--Features {
  color: #7a663f;
}
.btn {
  border-radius: 0;
  font-size: 1.4rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border: 0;
}
.btn.btn-sm,
.btn .btn-group-sm > .btn {
  font-size: 1.2rem;
}
.btn.btn-lg,
.btn .btn-group-lg > .btn {
  font-size: 1.6rem;
}
.btn.disabled,
.btn:disabled {
  opacity: 1;
}
/* Bootstrap overrides are placed here; */
@media (min-width: 0) {
  body .ps-0,
  body .px-0,
  body .p-0 {
    padding-left: 0rem !important;
  }
}
@media (min-width: 0) {
  body .pe-0,
  body .px-0,
  body .p-0 {
    padding-right: 0rem !important;
  }
}
@media (min-width: 0) {
  body .pt-0,
  body .py-0,
  body .p-0 {
    padding-top: 0rem !important;
  }
}
@media (min-width: 0) {
  body .pb-0,
  body .py-0,
  body .p-0 {
    padding-bottom: 0rem !important;
  }
}
@media (min-width: 0) {
  body .ps-1,
  body .px-1,
  body .p-1 {
    padding-left: 0.2rem !important;
  }
}
@media (min-width: 0) {
  body .pe-1,
  body .px-1,
  body .p-1 {
    padding-right: 0.2rem !important;
  }
}
@media (min-width: 0) {
  body .pt-1,
  body .py-1,
  body .p-1 {
    padding-top: 0.2rem !important;
  }
}
@media (min-width: 0) {
  body .pb-1,
  body .py-1,
  body .p-1 {
    padding-bottom: 0.2rem !important;
  }
}
@media (min-width: 0) {
  body .ps-2,
  body .px-2,
  body .p-2 {
    padding-left: 0.4rem !important;
  }
}
@media (min-width: 0) {
  body .pe-2,
  body .px-2,
  body .p-2 {
    padding-right: 0.4rem !important;
  }
}
@media (min-width: 0) {
  body .pt-2,
  body .py-2,
  body .p-2 {
    padding-top: 0.4rem !important;
  }
}
@media (min-width: 0) {
  body .pb-2,
  body .py-2,
  body .p-2 {
    padding-bottom: 0.4rem !important;
  }
}
@media (min-width: 0) {
  body .ps-3,
  body .px-3,
  body .p-3 {
    padding-left: 0.8rem !important;
  }
}
@media (min-width: 0) {
  body .pe-3,
  body .px-3,
  body .p-3 {
    padding-right: 0.8rem !important;
  }
}
@media (min-width: 0) {
  body .pt-3,
  body .py-3,
  body .p-3 {
    padding-top: 0.8rem !important;
  }
}
@media (min-width: 0) {
  body .pb-3,
  body .py-3,
  body .p-3 {
    padding-bottom: 0.8rem !important;
  }
}
@media (min-width: 0) {
  body .ps-4,
  body .px-4,
  body .p-4 {
    padding-left: 1.2rem !important;
  }
}
@media (min-width: 0) {
  body .pe-4,
  body .px-4,
  body .p-4 {
    padding-right: 1.2rem !important;
  }
}
@media (min-width: 0) {
  body .pt-4,
  body .py-4,
  body .p-4 {
    padding-top: 1.2rem !important;
  }
}
@media (min-width: 0) {
  body .pb-4,
  body .py-4,
  body .p-4 {
    padding-bottom: 1.2rem !important;
  }
}
@media (min-width: 0) {
  body .ps-5,
  body .px-5,
  body .p-5 {
    padding-left: 1.6rem !important;
  }
}
@media (min-width: 0) {
  body .pe-5,
  body .px-5,
  body .p-5 {
    padding-right: 1.6rem !important;
  }
}
@media (min-width: 0) {
  body .pt-5,
  body .py-5,
  body .p-5 {
    padding-top: 1.6rem !important;
  }
}
@media (min-width: 0) {
  body .pb-5,
  body .py-5,
  body .p-5 {
    padding-bottom: 1.6rem !important;
  }
}
@media (min-width: 0) {
  body .ps-6,
  body .px-6,
  body .p-6 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 0) {
  body .pe-6,
  body .px-6,
  body .p-6 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 0) {
  body .pt-6,
  body .py-6,
  body .p-6 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 0) {
  body .pb-6,
  body .py-6,
  body .p-6 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 0) {
  body .ps-7,
  body .px-7,
  body .p-7 {
    padding-left: 2.4rem !important;
  }
}
@media (min-width: 0) {
  body .pe-7,
  body .px-7,
  body .p-7 {
    padding-right: 2.4rem !important;
  }
}
@media (min-width: 0) {
  body .pt-7,
  body .py-7,
  body .p-7 {
    padding-top: 2.4rem !important;
  }
}
@media (min-width: 0) {
  body .pb-7,
  body .py-7,
  body .p-7 {
    padding-bottom: 2.4rem !important;
  }
}
@media (min-width: 0) {
  body .ps-8,
  body .px-8,
  body .p-8 {
    padding-left: 3.2rem !important;
  }
}
@media (min-width: 0) {
  body .pe-8,
  body .px-8,
  body .p-8 {
    padding-right: 3.2rem !important;
  }
}
@media (min-width: 0) {
  body .pt-8,
  body .py-8,
  body .p-8 {
    padding-top: 3.2rem !important;
  }
}
@media (min-width: 0) {
  body .pb-8,
  body .py-8,
  body .p-8 {
    padding-bottom: 3.2rem !important;
  }
}
@media (min-width: 0) {
  body .ps-9,
  body .px-9,
  body .p-9 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 0) {
  body .pe-9,
  body .px-9,
  body .p-9 {
    padding-right: 4rem !important;
  }
}
@media (min-width: 0) {
  body .pt-9,
  body .py-9,
  body .p-9 {
    padding-top: 4rem !important;
  }
}
@media (min-width: 0) {
  body .pb-9,
  body .py-9,
  body .p-9 {
    padding-bottom: 4rem !important;
  }
}
@media (min-width: 0) {
  body .ps-10,
  body .px-10,
  body .p-10 {
    padding-left: 4.8rem !important;
  }
}
@media (min-width: 0) {
  body .pe-10,
  body .px-10,
  body .p-10 {
    padding-right: 4.8rem !important;
  }
}
@media (min-width: 0) {
  body .pt-10,
  body .py-10,
  body .p-10 {
    padding-top: 4.8rem !important;
  }
}
@media (min-width: 0) {
  body .pb-10,
  body .py-10,
  body .p-10 {
    padding-bottom: 4.8rem !important;
  }
}
@media (min-width: 0) {
  body .ps-11,
  body .px-11,
  body .p-11 {
    padding-left: 5.6rem !important;
  }
}
@media (min-width: 0) {
  body .pe-11,
  body .px-11,
  body .p-11 {
    padding-right: 5.6rem !important;
  }
}
@media (min-width: 0) {
  body .pt-11,
  body .py-11,
  body .p-11 {
    padding-top: 5.6rem !important;
  }
}
@media (min-width: 0) {
  body .pb-11,
  body .py-11,
  body .p-11 {
    padding-bottom: 5.6rem !important;
  }
}
@media (min-width: 0) {
  body .ps-12,
  body .px-12,
  body .p-12 {
    padding-left: 6.4rem !important;
  }
}
@media (min-width: 0) {
  body .pe-12,
  body .px-12,
  body .p-12 {
    padding-right: 6.4rem !important;
  }
}
@media (min-width: 0) {
  body .pt-12,
  body .py-12,
  body .p-12 {
    padding-top: 6.4rem !important;
  }
}
@media (min-width: 0) {
  body .pb-12,
  body .py-12,
  body .p-12 {
    padding-bottom: 6.4rem !important;
  }
}
@media (min-width: 0) {
  body .ps-13,
  body .px-13,
  body .p-13 {
    padding-left: 7.2rem !important;
  }
}
@media (min-width: 0) {
  body .pe-13,
  body .px-13,
  body .p-13 {
    padding-right: 7.2rem !important;
  }
}
@media (min-width: 0) {
  body .pt-13,
  body .py-13,
  body .p-13 {
    padding-top: 7.2rem !important;
  }
}
@media (min-width: 0) {
  body .pb-13,
  body .py-13,
  body .p-13 {
    padding-bottom: 7.2rem !important;
  }
}
@media (min-width: 0) {
  body .ps-14,
  body .px-14,
  body .p-14 {
    padding-left: 8rem !important;
  }
}
@media (min-width: 0) {
  body .pe-14,
  body .px-14,
  body .p-14 {
    padding-right: 8rem !important;
  }
}
@media (min-width: 0) {
  body .pt-14,
  body .py-14,
  body .p-14 {
    padding-top: 8rem !important;
  }
}
@media (min-width: 0) {
  body .pb-14,
  body .py-14,
  body .p-14 {
    padding-bottom: 8rem !important;
  }
}
@media (min-width: 0) {
  body .ps-15,
  body .px-15,
  body .p-15 {
    padding-left: 8.8rem !important;
  }
}
@media (min-width: 0) {
  body .pe-15,
  body .px-15,
  body .p-15 {
    padding-right: 8.8rem !important;
  }
}
@media (min-width: 0) {
  body .pt-15,
  body .py-15,
  body .p-15 {
    padding-top: 8.8rem !important;
  }
}
@media (min-width: 0) {
  body .pb-15,
  body .py-15,
  body .p-15 {
    padding-bottom: 8.8rem !important;
  }
}
@media (min-width: 0) {
  body .ps-16,
  body .px-16,
  body .p-16 {
    padding-left: 9.6rem !important;
  }
}
@media (min-width: 0) {
  body .pe-16,
  body .px-16,
  body .p-16 {
    padding-right: 9.6rem !important;
  }
}
@media (min-width: 0) {
  body .pt-16,
  body .py-16,
  body .p-16 {
    padding-top: 9.6rem !important;
  }
}
@media (min-width: 0) {
  body .pb-16,
  body .py-16,
  body .p-16 {
    padding-bottom: 9.6rem !important;
  }
}
@media (min-width: 0) {
  body .ps-17,
  body .px-17,
  body .p-17 {
    padding-left: 10.4rem !important;
  }
}
@media (min-width: 0) {
  body .pe-17,
  body .px-17,
  body .p-17 {
    padding-right: 10.4rem !important;
  }
}
@media (min-width: 0) {
  body .pt-17,
  body .py-17,
  body .p-17 {
    padding-top: 10.4rem !important;
  }
}
@media (min-width: 0) {
  body .pb-17,
  body .py-17,
  body .p-17 {
    padding-bottom: 10.4rem !important;
  }
}
@media (min-width: 0) {
  body .ps-18,
  body .px-18,
  body .p-18 {
    padding-left: 11.2rem !important;
  }
}
@media (min-width: 0) {
  body .pe-18,
  body .px-18,
  body .p-18 {
    padding-right: 11.2rem !important;
  }
}
@media (min-width: 0) {
  body .pt-18,
  body .py-18,
  body .p-18 {
    padding-top: 11.2rem !important;
  }
}
@media (min-width: 0) {
  body .pb-18,
  body .py-18,
  body .p-18 {
    padding-bottom: 11.2rem !important;
  }
}
@media (min-width: 0) {
  body .ps-19,
  body .px-19,
  body .p-19 {
    padding-left: 12rem !important;
  }
}
@media (min-width: 0) {
  body .pe-19,
  body .px-19,
  body .p-19 {
    padding-right: 12rem !important;
  }
}
@media (min-width: 0) {
  body .pt-19,
  body .py-19,
  body .p-19 {
    padding-top: 12rem !important;
  }
}
@media (min-width: 0) {
  body .pb-19,
  body .py-19,
  body .p-19 {
    padding-bottom: 12rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-0,
  body .px-sm-0,
  body .p-sm-0 {
    padding-left: 0rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-0,
  body .px-sm-0,
  body .p-sm-0 {
    padding-right: 0rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-0,
  body .py-sm-0,
  body .p-sm-0 {
    padding-top: 0rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-0,
  body .py-sm-0,
  body .p-sm-0 {
    padding-bottom: 0rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-1,
  body .px-sm-1,
  body .p-sm-1 {
    padding-left: 0.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-1,
  body .px-sm-1,
  body .p-sm-1 {
    padding-right: 0.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-1,
  body .py-sm-1,
  body .p-sm-1 {
    padding-top: 0.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-1,
  body .py-sm-1,
  body .p-sm-1 {
    padding-bottom: 0.2rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-2,
  body .px-sm-2,
  body .p-sm-2 {
    padding-left: 0.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-2,
  body .px-sm-2,
  body .p-sm-2 {
    padding-right: 0.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-2,
  body .py-sm-2,
  body .p-sm-2 {
    padding-top: 0.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-2,
  body .py-sm-2,
  body .p-sm-2 {
    padding-bottom: 0.4rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-3,
  body .px-sm-3,
  body .p-sm-3 {
    padding-left: 0.8rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-3,
  body .px-sm-3,
  body .p-sm-3 {
    padding-right: 0.8rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-3,
  body .py-sm-3,
  body .p-sm-3 {
    padding-top: 0.8rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-3,
  body .py-sm-3,
  body .p-sm-3 {
    padding-bottom: 0.8rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-4,
  body .px-sm-4,
  body .p-sm-4 {
    padding-left: 1.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-4,
  body .px-sm-4,
  body .p-sm-4 {
    padding-right: 1.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-4,
  body .py-sm-4,
  body .p-sm-4 {
    padding-top: 1.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-4,
  body .py-sm-4,
  body .p-sm-4 {
    padding-bottom: 1.2rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-5,
  body .px-sm-5,
  body .p-sm-5 {
    padding-left: 1.6rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-5,
  body .px-sm-5,
  body .p-sm-5 {
    padding-right: 1.6rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-5,
  body .py-sm-5,
  body .p-sm-5 {
    padding-top: 1.6rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-5,
  body .py-sm-5,
  body .p-sm-5 {
    padding-bottom: 1.6rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-6,
  body .px-sm-6,
  body .p-sm-6 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-6,
  body .px-sm-6,
  body .p-sm-6 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-6,
  body .py-sm-6,
  body .p-sm-6 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-6,
  body .py-sm-6,
  body .p-sm-6 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-7,
  body .px-sm-7,
  body .p-sm-7 {
    padding-left: 2.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-7,
  body .px-sm-7,
  body .p-sm-7 {
    padding-right: 2.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-7,
  body .py-sm-7,
  body .p-sm-7 {
    padding-top: 2.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-7,
  body .py-sm-7,
  body .p-sm-7 {
    padding-bottom: 2.4rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-8,
  body .px-sm-8,
  body .p-sm-8 {
    padding-left: 3.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-8,
  body .px-sm-8,
  body .p-sm-8 {
    padding-right: 3.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-8,
  body .py-sm-8,
  body .p-sm-8 {
    padding-top: 3.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-8,
  body .py-sm-8,
  body .p-sm-8 {
    padding-bottom: 3.2rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-9,
  body .px-sm-9,
  body .p-sm-9 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-9,
  body .px-sm-9,
  body .p-sm-9 {
    padding-right: 4rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-9,
  body .py-sm-9,
  body .p-sm-9 {
    padding-top: 4rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-9,
  body .py-sm-9,
  body .p-sm-9 {
    padding-bottom: 4rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-10,
  body .px-sm-10,
  body .p-sm-10 {
    padding-left: 4.8rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-10,
  body .px-sm-10,
  body .p-sm-10 {
    padding-right: 4.8rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-10,
  body .py-sm-10,
  body .p-sm-10 {
    padding-top: 4.8rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-10,
  body .py-sm-10,
  body .p-sm-10 {
    padding-bottom: 4.8rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-11,
  body .px-sm-11,
  body .p-sm-11 {
    padding-left: 5.6rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-11,
  body .px-sm-11,
  body .p-sm-11 {
    padding-right: 5.6rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-11,
  body .py-sm-11,
  body .p-sm-11 {
    padding-top: 5.6rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-11,
  body .py-sm-11,
  body .p-sm-11 {
    padding-bottom: 5.6rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-12,
  body .px-sm-12,
  body .p-sm-12 {
    padding-left: 6.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-12,
  body .px-sm-12,
  body .p-sm-12 {
    padding-right: 6.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-12,
  body .py-sm-12,
  body .p-sm-12 {
    padding-top: 6.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-12,
  body .py-sm-12,
  body .p-sm-12 {
    padding-bottom: 6.4rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-13,
  body .px-sm-13,
  body .p-sm-13 {
    padding-left: 7.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-13,
  body .px-sm-13,
  body .p-sm-13 {
    padding-right: 7.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-13,
  body .py-sm-13,
  body .p-sm-13 {
    padding-top: 7.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-13,
  body .py-sm-13,
  body .p-sm-13 {
    padding-bottom: 7.2rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-14,
  body .px-sm-14,
  body .p-sm-14 {
    padding-left: 8rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-14,
  body .px-sm-14,
  body .p-sm-14 {
    padding-right: 8rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-14,
  body .py-sm-14,
  body .p-sm-14 {
    padding-top: 8rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-14,
  body .py-sm-14,
  body .p-sm-14 {
    padding-bottom: 8rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-15,
  body .px-sm-15,
  body .p-sm-15 {
    padding-left: 8.8rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-15,
  body .px-sm-15,
  body .p-sm-15 {
    padding-right: 8.8rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-15,
  body .py-sm-15,
  body .p-sm-15 {
    padding-top: 8.8rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-15,
  body .py-sm-15,
  body .p-sm-15 {
    padding-bottom: 8.8rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-16,
  body .px-sm-16,
  body .p-sm-16 {
    padding-left: 9.6rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-16,
  body .px-sm-16,
  body .p-sm-16 {
    padding-right: 9.6rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-16,
  body .py-sm-16,
  body .p-sm-16 {
    padding-top: 9.6rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-16,
  body .py-sm-16,
  body .p-sm-16 {
    padding-bottom: 9.6rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-17,
  body .px-sm-17,
  body .p-sm-17 {
    padding-left: 10.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-17,
  body .px-sm-17,
  body .p-sm-17 {
    padding-right: 10.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-17,
  body .py-sm-17,
  body .p-sm-17 {
    padding-top: 10.4rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-17,
  body .py-sm-17,
  body .p-sm-17 {
    padding-bottom: 10.4rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-18,
  body .px-sm-18,
  body .p-sm-18 {
    padding-left: 11.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-18,
  body .px-sm-18,
  body .p-sm-18 {
    padding-right: 11.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-18,
  body .py-sm-18,
  body .p-sm-18 {
    padding-top: 11.2rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-18,
  body .py-sm-18,
  body .p-sm-18 {
    padding-bottom: 11.2rem !important;
  }
}
@media (min-width: 576px) {
  body .ps-sm-19,
  body .px-sm-19,
  body .p-sm-19 {
    padding-left: 12rem !important;
  }
}
@media (min-width: 576px) {
  body .pe-sm-19,
  body .px-sm-19,
  body .p-sm-19 {
    padding-right: 12rem !important;
  }
}
@media (min-width: 576px) {
  body .pt-sm-19,
  body .py-sm-19,
  body .p-sm-19 {
    padding-top: 12rem !important;
  }
}
@media (min-width: 576px) {
  body .pb-sm-19,
  body .py-sm-19,
  body .p-sm-19 {
    padding-bottom: 12rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-0,
  body .px-md-0,
  body .p-md-0 {
    padding-left: 0rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-0,
  body .px-md-0,
  body .p-md-0 {
    padding-right: 0rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-0,
  body .py-md-0,
  body .p-md-0 {
    padding-top: 0rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-0,
  body .py-md-0,
  body .p-md-0 {
    padding-bottom: 0rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-1,
  body .px-md-1,
  body .p-md-1 {
    padding-left: 0.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-1,
  body .px-md-1,
  body .p-md-1 {
    padding-right: 0.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-1,
  body .py-md-1,
  body .p-md-1 {
    padding-top: 0.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-1,
  body .py-md-1,
  body .p-md-1 {
    padding-bottom: 0.2rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-2,
  body .px-md-2,
  body .p-md-2 {
    padding-left: 0.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-2,
  body .px-md-2,
  body .p-md-2 {
    padding-right: 0.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-2,
  body .py-md-2,
  body .p-md-2 {
    padding-top: 0.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-2,
  body .py-md-2,
  body .p-md-2 {
    padding-bottom: 0.4rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-3,
  body .px-md-3,
  body .p-md-3 {
    padding-left: 0.8rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-3,
  body .px-md-3,
  body .p-md-3 {
    padding-right: 0.8rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-3,
  body .py-md-3,
  body .p-md-3 {
    padding-top: 0.8rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-3,
  body .py-md-3,
  body .p-md-3 {
    padding-bottom: 0.8rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-4,
  body .px-md-4,
  body .p-md-4 {
    padding-left: 1.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-4,
  body .px-md-4,
  body .p-md-4 {
    padding-right: 1.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-4,
  body .py-md-4,
  body .p-md-4 {
    padding-top: 1.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-4,
  body .py-md-4,
  body .p-md-4 {
    padding-bottom: 1.2rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-5,
  body .px-md-5,
  body .p-md-5 {
    padding-left: 1.6rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-5,
  body .px-md-5,
  body .p-md-5 {
    padding-right: 1.6rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-5,
  body .py-md-5,
  body .p-md-5 {
    padding-top: 1.6rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-5,
  body .py-md-5,
  body .p-md-5 {
    padding-bottom: 1.6rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-6,
  body .px-md-6,
  body .p-md-6 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-6,
  body .px-md-6,
  body .p-md-6 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-6,
  body .py-md-6,
  body .p-md-6 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-6,
  body .py-md-6,
  body .p-md-6 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-7,
  body .px-md-7,
  body .p-md-7 {
    padding-left: 2.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-7,
  body .px-md-7,
  body .p-md-7 {
    padding-right: 2.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-7,
  body .py-md-7,
  body .p-md-7 {
    padding-top: 2.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-7,
  body .py-md-7,
  body .p-md-7 {
    padding-bottom: 2.4rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-8,
  body .px-md-8,
  body .p-md-8 {
    padding-left: 3.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-8,
  body .px-md-8,
  body .p-md-8 {
    padding-right: 3.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-8,
  body .py-md-8,
  body .p-md-8 {
    padding-top: 3.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-8,
  body .py-md-8,
  body .p-md-8 {
    padding-bottom: 3.2rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-9,
  body .px-md-9,
  body .p-md-9 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-9,
  body .px-md-9,
  body .p-md-9 {
    padding-right: 4rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-9,
  body .py-md-9,
  body .p-md-9 {
    padding-top: 4rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-9,
  body .py-md-9,
  body .p-md-9 {
    padding-bottom: 4rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-10,
  body .px-md-10,
  body .p-md-10 {
    padding-left: 4.8rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-10,
  body .px-md-10,
  body .p-md-10 {
    padding-right: 4.8rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-10,
  body .py-md-10,
  body .p-md-10 {
    padding-top: 4.8rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-10,
  body .py-md-10,
  body .p-md-10 {
    padding-bottom: 4.8rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-11,
  body .px-md-11,
  body .p-md-11 {
    padding-left: 5.6rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-11,
  body .px-md-11,
  body .p-md-11 {
    padding-right: 5.6rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-11,
  body .py-md-11,
  body .p-md-11 {
    padding-top: 5.6rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-11,
  body .py-md-11,
  body .p-md-11 {
    padding-bottom: 5.6rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-12,
  body .px-md-12,
  body .p-md-12 {
    padding-left: 6.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-12,
  body .px-md-12,
  body .p-md-12 {
    padding-right: 6.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-12,
  body .py-md-12,
  body .p-md-12 {
    padding-top: 6.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-12,
  body .py-md-12,
  body .p-md-12 {
    padding-bottom: 6.4rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-13,
  body .px-md-13,
  body .p-md-13 {
    padding-left: 7.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-13,
  body .px-md-13,
  body .p-md-13 {
    padding-right: 7.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-13,
  body .py-md-13,
  body .p-md-13 {
    padding-top: 7.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-13,
  body .py-md-13,
  body .p-md-13 {
    padding-bottom: 7.2rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-14,
  body .px-md-14,
  body .p-md-14 {
    padding-left: 8rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-14,
  body .px-md-14,
  body .p-md-14 {
    padding-right: 8rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-14,
  body .py-md-14,
  body .p-md-14 {
    padding-top: 8rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-14,
  body .py-md-14,
  body .p-md-14 {
    padding-bottom: 8rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-15,
  body .px-md-15,
  body .p-md-15 {
    padding-left: 8.8rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-15,
  body .px-md-15,
  body .p-md-15 {
    padding-right: 8.8rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-15,
  body .py-md-15,
  body .p-md-15 {
    padding-top: 8.8rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-15,
  body .py-md-15,
  body .p-md-15 {
    padding-bottom: 8.8rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-16,
  body .px-md-16,
  body .p-md-16 {
    padding-left: 9.6rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-16,
  body .px-md-16,
  body .p-md-16 {
    padding-right: 9.6rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-16,
  body .py-md-16,
  body .p-md-16 {
    padding-top: 9.6rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-16,
  body .py-md-16,
  body .p-md-16 {
    padding-bottom: 9.6rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-17,
  body .px-md-17,
  body .p-md-17 {
    padding-left: 10.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-17,
  body .px-md-17,
  body .p-md-17 {
    padding-right: 10.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-17,
  body .py-md-17,
  body .p-md-17 {
    padding-top: 10.4rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-17,
  body .py-md-17,
  body .p-md-17 {
    padding-bottom: 10.4rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-18,
  body .px-md-18,
  body .p-md-18 {
    padding-left: 11.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-18,
  body .px-md-18,
  body .p-md-18 {
    padding-right: 11.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-18,
  body .py-md-18,
  body .p-md-18 {
    padding-top: 11.2rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-18,
  body .py-md-18,
  body .p-md-18 {
    padding-bottom: 11.2rem !important;
  }
}
@media (min-width: 768px) {
  body .ps-md-19,
  body .px-md-19,
  body .p-md-19 {
    padding-left: 12rem !important;
  }
}
@media (min-width: 768px) {
  body .pe-md-19,
  body .px-md-19,
  body .p-md-19 {
    padding-right: 12rem !important;
  }
}
@media (min-width: 768px) {
  body .pt-md-19,
  body .py-md-19,
  body .p-md-19 {
    padding-top: 12rem !important;
  }
}
@media (min-width: 768px) {
  body .pb-md-19,
  body .py-md-19,
  body .p-md-19 {
    padding-bottom: 12rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-0,
  body .px-lg-0,
  body .p-lg-0 {
    padding-left: 0rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-0,
  body .px-lg-0,
  body .p-lg-0 {
    padding-right: 0rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-0,
  body .py-lg-0,
  body .p-lg-0 {
    padding-top: 0rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-0,
  body .py-lg-0,
  body .p-lg-0 {
    padding-bottom: 0rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-1,
  body .px-lg-1,
  body .p-lg-1 {
    padding-left: 0.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-1,
  body .px-lg-1,
  body .p-lg-1 {
    padding-right: 0.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-1,
  body .py-lg-1,
  body .p-lg-1 {
    padding-top: 0.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-1,
  body .py-lg-1,
  body .p-lg-1 {
    padding-bottom: 0.2rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-2,
  body .px-lg-2,
  body .p-lg-2 {
    padding-left: 0.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-2,
  body .px-lg-2,
  body .p-lg-2 {
    padding-right: 0.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-2,
  body .py-lg-2,
  body .p-lg-2 {
    padding-top: 0.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-2,
  body .py-lg-2,
  body .p-lg-2 {
    padding-bottom: 0.4rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-3,
  body .px-lg-3,
  body .p-lg-3 {
    padding-left: 0.8rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-3,
  body .px-lg-3,
  body .p-lg-3 {
    padding-right: 0.8rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-3,
  body .py-lg-3,
  body .p-lg-3 {
    padding-top: 0.8rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-3,
  body .py-lg-3,
  body .p-lg-3 {
    padding-bottom: 0.8rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-4,
  body .px-lg-4,
  body .p-lg-4 {
    padding-left: 1.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-4,
  body .px-lg-4,
  body .p-lg-4 {
    padding-right: 1.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-4,
  body .py-lg-4,
  body .p-lg-4 {
    padding-top: 1.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-4,
  body .py-lg-4,
  body .p-lg-4 {
    padding-bottom: 1.2rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-5,
  body .px-lg-5,
  body .p-lg-5 {
    padding-left: 1.6rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-5,
  body .px-lg-5,
  body .p-lg-5 {
    padding-right: 1.6rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-5,
  body .py-lg-5,
  body .p-lg-5 {
    padding-top: 1.6rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-5,
  body .py-lg-5,
  body .p-lg-5 {
    padding-bottom: 1.6rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-6,
  body .px-lg-6,
  body .p-lg-6 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-6,
  body .px-lg-6,
  body .p-lg-6 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-6,
  body .py-lg-6,
  body .p-lg-6 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-6,
  body .py-lg-6,
  body .p-lg-6 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-7,
  body .px-lg-7,
  body .p-lg-7 {
    padding-left: 2.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-7,
  body .px-lg-7,
  body .p-lg-7 {
    padding-right: 2.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-7,
  body .py-lg-7,
  body .p-lg-7 {
    padding-top: 2.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-7,
  body .py-lg-7,
  body .p-lg-7 {
    padding-bottom: 2.4rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-8,
  body .px-lg-8,
  body .p-lg-8 {
    padding-left: 3.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-8,
  body .px-lg-8,
  body .p-lg-8 {
    padding-right: 3.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-8,
  body .py-lg-8,
  body .p-lg-8 {
    padding-top: 3.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-8,
  body .py-lg-8,
  body .p-lg-8 {
    padding-bottom: 3.2rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-9,
  body .px-lg-9,
  body .p-lg-9 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-9,
  body .px-lg-9,
  body .p-lg-9 {
    padding-right: 4rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-9,
  body .py-lg-9,
  body .p-lg-9 {
    padding-top: 4rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-9,
  body .py-lg-9,
  body .p-lg-9 {
    padding-bottom: 4rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-10,
  body .px-lg-10,
  body .p-lg-10 {
    padding-left: 4.8rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-10,
  body .px-lg-10,
  body .p-lg-10 {
    padding-right: 4.8rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-10,
  body .py-lg-10,
  body .p-lg-10 {
    padding-top: 4.8rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-10,
  body .py-lg-10,
  body .p-lg-10 {
    padding-bottom: 4.8rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-11,
  body .px-lg-11,
  body .p-lg-11 {
    padding-left: 5.6rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-11,
  body .px-lg-11,
  body .p-lg-11 {
    padding-right: 5.6rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-11,
  body .py-lg-11,
  body .p-lg-11 {
    padding-top: 5.6rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-11,
  body .py-lg-11,
  body .p-lg-11 {
    padding-bottom: 5.6rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-12,
  body .px-lg-12,
  body .p-lg-12 {
    padding-left: 6.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-12,
  body .px-lg-12,
  body .p-lg-12 {
    padding-right: 6.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-12,
  body .py-lg-12,
  body .p-lg-12 {
    padding-top: 6.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-12,
  body .py-lg-12,
  body .p-lg-12 {
    padding-bottom: 6.4rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-13,
  body .px-lg-13,
  body .p-lg-13 {
    padding-left: 7.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-13,
  body .px-lg-13,
  body .p-lg-13 {
    padding-right: 7.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-13,
  body .py-lg-13,
  body .p-lg-13 {
    padding-top: 7.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-13,
  body .py-lg-13,
  body .p-lg-13 {
    padding-bottom: 7.2rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-14,
  body .px-lg-14,
  body .p-lg-14 {
    padding-left: 8rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-14,
  body .px-lg-14,
  body .p-lg-14 {
    padding-right: 8rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-14,
  body .py-lg-14,
  body .p-lg-14 {
    padding-top: 8rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-14,
  body .py-lg-14,
  body .p-lg-14 {
    padding-bottom: 8rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-15,
  body .px-lg-15,
  body .p-lg-15 {
    padding-left: 8.8rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-15,
  body .px-lg-15,
  body .p-lg-15 {
    padding-right: 8.8rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-15,
  body .py-lg-15,
  body .p-lg-15 {
    padding-top: 8.8rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-15,
  body .py-lg-15,
  body .p-lg-15 {
    padding-bottom: 8.8rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-16,
  body .px-lg-16,
  body .p-lg-16 {
    padding-left: 9.6rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-16,
  body .px-lg-16,
  body .p-lg-16 {
    padding-right: 9.6rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-16,
  body .py-lg-16,
  body .p-lg-16 {
    padding-top: 9.6rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-16,
  body .py-lg-16,
  body .p-lg-16 {
    padding-bottom: 9.6rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-17,
  body .px-lg-17,
  body .p-lg-17 {
    padding-left: 10.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-17,
  body .px-lg-17,
  body .p-lg-17 {
    padding-right: 10.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-17,
  body .py-lg-17,
  body .p-lg-17 {
    padding-top: 10.4rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-17,
  body .py-lg-17,
  body .p-lg-17 {
    padding-bottom: 10.4rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-18,
  body .px-lg-18,
  body .p-lg-18 {
    padding-left: 11.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-18,
  body .px-lg-18,
  body .p-lg-18 {
    padding-right: 11.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-18,
  body .py-lg-18,
  body .p-lg-18 {
    padding-top: 11.2rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-18,
  body .py-lg-18,
  body .p-lg-18 {
    padding-bottom: 11.2rem !important;
  }
}
@media (min-width: 992px) {
  body .ps-lg-19,
  body .px-lg-19,
  body .p-lg-19 {
    padding-left: 12rem !important;
  }
}
@media (min-width: 992px) {
  body .pe-lg-19,
  body .px-lg-19,
  body .p-lg-19 {
    padding-right: 12rem !important;
  }
}
@media (min-width: 992px) {
  body .pt-lg-19,
  body .py-lg-19,
  body .p-lg-19 {
    padding-top: 12rem !important;
  }
}
@media (min-width: 992px) {
  body .pb-lg-19,
  body .py-lg-19,
  body .p-lg-19 {
    padding-bottom: 12rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-0,
  body .px-xl-0,
  body .p-xl-0 {
    padding-left: 0rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-0,
  body .px-xl-0,
  body .p-xl-0 {
    padding-right: 0rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-0,
  body .py-xl-0,
  body .p-xl-0 {
    padding-top: 0rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-0,
  body .py-xl-0,
  body .p-xl-0 {
    padding-bottom: 0rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-1,
  body .px-xl-1,
  body .p-xl-1 {
    padding-left: 0.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-1,
  body .px-xl-1,
  body .p-xl-1 {
    padding-right: 0.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-1,
  body .py-xl-1,
  body .p-xl-1 {
    padding-top: 0.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-1,
  body .py-xl-1,
  body .p-xl-1 {
    padding-bottom: 0.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-2,
  body .px-xl-2,
  body .p-xl-2 {
    padding-left: 0.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-2,
  body .px-xl-2,
  body .p-xl-2 {
    padding-right: 0.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-2,
  body .py-xl-2,
  body .p-xl-2 {
    padding-top: 0.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-2,
  body .py-xl-2,
  body .p-xl-2 {
    padding-bottom: 0.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-3,
  body .px-xl-3,
  body .p-xl-3 {
    padding-left: 0.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-3,
  body .px-xl-3,
  body .p-xl-3 {
    padding-right: 0.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-3,
  body .py-xl-3,
  body .p-xl-3 {
    padding-top: 0.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-3,
  body .py-xl-3,
  body .p-xl-3 {
    padding-bottom: 0.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-4,
  body .px-xl-4,
  body .p-xl-4 {
    padding-left: 1.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-4,
  body .px-xl-4,
  body .p-xl-4 {
    padding-right: 1.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-4,
  body .py-xl-4,
  body .p-xl-4 {
    padding-top: 1.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-4,
  body .py-xl-4,
  body .p-xl-4 {
    padding-bottom: 1.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-5,
  body .px-xl-5,
  body .p-xl-5 {
    padding-left: 1.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-5,
  body .px-xl-5,
  body .p-xl-5 {
    padding-right: 1.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-5,
  body .py-xl-5,
  body .p-xl-5 {
    padding-top: 1.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-5,
  body .py-xl-5,
  body .p-xl-5 {
    padding-bottom: 1.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-6,
  body .px-xl-6,
  body .p-xl-6 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-6,
  body .px-xl-6,
  body .p-xl-6 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-6,
  body .py-xl-6,
  body .p-xl-6 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-6,
  body .py-xl-6,
  body .p-xl-6 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-7,
  body .px-xl-7,
  body .p-xl-7 {
    padding-left: 2.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-7,
  body .px-xl-7,
  body .p-xl-7 {
    padding-right: 2.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-7,
  body .py-xl-7,
  body .p-xl-7 {
    padding-top: 2.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-7,
  body .py-xl-7,
  body .p-xl-7 {
    padding-bottom: 2.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-8,
  body .px-xl-8,
  body .p-xl-8 {
    padding-left: 3.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-8,
  body .px-xl-8,
  body .p-xl-8 {
    padding-right: 3.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-8,
  body .py-xl-8,
  body .p-xl-8 {
    padding-top: 3.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-8,
  body .py-xl-8,
  body .p-xl-8 {
    padding-bottom: 3.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-9,
  body .px-xl-9,
  body .p-xl-9 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-9,
  body .px-xl-9,
  body .p-xl-9 {
    padding-right: 4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-9,
  body .py-xl-9,
  body .p-xl-9 {
    padding-top: 4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-9,
  body .py-xl-9,
  body .p-xl-9 {
    padding-bottom: 4rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-10,
  body .px-xl-10,
  body .p-xl-10 {
    padding-left: 4.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-10,
  body .px-xl-10,
  body .p-xl-10 {
    padding-right: 4.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-10,
  body .py-xl-10,
  body .p-xl-10 {
    padding-top: 4.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-10,
  body .py-xl-10,
  body .p-xl-10 {
    padding-bottom: 4.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-11,
  body .px-xl-11,
  body .p-xl-11 {
    padding-left: 5.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-11,
  body .px-xl-11,
  body .p-xl-11 {
    padding-right: 5.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-11,
  body .py-xl-11,
  body .p-xl-11 {
    padding-top: 5.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-11,
  body .py-xl-11,
  body .p-xl-11 {
    padding-bottom: 5.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-12,
  body .px-xl-12,
  body .p-xl-12 {
    padding-left: 6.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-12,
  body .px-xl-12,
  body .p-xl-12 {
    padding-right: 6.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-12,
  body .py-xl-12,
  body .p-xl-12 {
    padding-top: 6.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-12,
  body .py-xl-12,
  body .p-xl-12 {
    padding-bottom: 6.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-13,
  body .px-xl-13,
  body .p-xl-13 {
    padding-left: 7.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-13,
  body .px-xl-13,
  body .p-xl-13 {
    padding-right: 7.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-13,
  body .py-xl-13,
  body .p-xl-13 {
    padding-top: 7.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-13,
  body .py-xl-13,
  body .p-xl-13 {
    padding-bottom: 7.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-14,
  body .px-xl-14,
  body .p-xl-14 {
    padding-left: 8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-14,
  body .px-xl-14,
  body .p-xl-14 {
    padding-right: 8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-14,
  body .py-xl-14,
  body .p-xl-14 {
    padding-top: 8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-14,
  body .py-xl-14,
  body .p-xl-14 {
    padding-bottom: 8rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-15,
  body .px-xl-15,
  body .p-xl-15 {
    padding-left: 8.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-15,
  body .px-xl-15,
  body .p-xl-15 {
    padding-right: 8.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-15,
  body .py-xl-15,
  body .p-xl-15 {
    padding-top: 8.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-15,
  body .py-xl-15,
  body .p-xl-15 {
    padding-bottom: 8.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-16,
  body .px-xl-16,
  body .p-xl-16 {
    padding-left: 9.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-16,
  body .px-xl-16,
  body .p-xl-16 {
    padding-right: 9.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-16,
  body .py-xl-16,
  body .p-xl-16 {
    padding-top: 9.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-16,
  body .py-xl-16,
  body .p-xl-16 {
    padding-bottom: 9.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-17,
  body .px-xl-17,
  body .p-xl-17 {
    padding-left: 10.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-17,
  body .px-xl-17,
  body .p-xl-17 {
    padding-right: 10.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-17,
  body .py-xl-17,
  body .p-xl-17 {
    padding-top: 10.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-17,
  body .py-xl-17,
  body .p-xl-17 {
    padding-bottom: 10.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-18,
  body .px-xl-18,
  body .p-xl-18 {
    padding-left: 11.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-18,
  body .px-xl-18,
  body .p-xl-18 {
    padding-right: 11.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-18,
  body .py-xl-18,
  body .p-xl-18 {
    padding-top: 11.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-18,
  body .py-xl-18,
  body .p-xl-18 {
    padding-bottom: 11.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ps-xl-19,
  body .px-xl-19,
  body .p-xl-19 {
    padding-left: 12rem !important;
  }
}
@media (min-width: 1200px) {
  body .pe-xl-19,
  body .px-xl-19,
  body .p-xl-19 {
    padding-right: 12rem !important;
  }
}
@media (min-width: 1200px) {
  body .pt-xl-19,
  body .py-xl-19,
  body .p-xl-19 {
    padding-top: 12rem !important;
  }
}
@media (min-width: 1200px) {
  body .pb-xl-19,
  body .py-xl-19,
  body .p-xl-19 {
    padding-bottom: 12rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-0,
  body .px-xxl-0,
  body .p-xxl-0 {
    padding-left: 0rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-0,
  body .px-xxl-0,
  body .p-xxl-0 {
    padding-right: 0rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-0,
  body .py-xxl-0,
  body .p-xxl-0 {
    padding-top: 0rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-0,
  body .py-xxl-0,
  body .p-xxl-0 {
    padding-bottom: 0rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-1,
  body .px-xxl-1,
  body .p-xxl-1 {
    padding-left: 0.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-1,
  body .px-xxl-1,
  body .p-xxl-1 {
    padding-right: 0.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-1,
  body .py-xxl-1,
  body .p-xxl-1 {
    padding-top: 0.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-1,
  body .py-xxl-1,
  body .p-xxl-1 {
    padding-bottom: 0.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-2,
  body .px-xxl-2,
  body .p-xxl-2 {
    padding-left: 0.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-2,
  body .px-xxl-2,
  body .p-xxl-2 {
    padding-right: 0.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-2,
  body .py-xxl-2,
  body .p-xxl-2 {
    padding-top: 0.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-2,
  body .py-xxl-2,
  body .p-xxl-2 {
    padding-bottom: 0.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-3,
  body .px-xxl-3,
  body .p-xxl-3 {
    padding-left: 0.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-3,
  body .px-xxl-3,
  body .p-xxl-3 {
    padding-right: 0.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-3,
  body .py-xxl-3,
  body .p-xxl-3 {
    padding-top: 0.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-3,
  body .py-xxl-3,
  body .p-xxl-3 {
    padding-bottom: 0.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-4,
  body .px-xxl-4,
  body .p-xxl-4 {
    padding-left: 1.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-4,
  body .px-xxl-4,
  body .p-xxl-4 {
    padding-right: 1.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-4,
  body .py-xxl-4,
  body .p-xxl-4 {
    padding-top: 1.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-4,
  body .py-xxl-4,
  body .p-xxl-4 {
    padding-bottom: 1.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-5,
  body .px-xxl-5,
  body .p-xxl-5 {
    padding-left: 1.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-5,
  body .px-xxl-5,
  body .p-xxl-5 {
    padding-right: 1.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-5,
  body .py-xxl-5,
  body .p-xxl-5 {
    padding-top: 1.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-5,
  body .py-xxl-5,
  body .p-xxl-5 {
    padding-bottom: 1.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-6,
  body .px-xxl-6,
  body .p-xxl-6 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-6,
  body .px-xxl-6,
  body .p-xxl-6 {
    padding-right: 2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-6,
  body .py-xxl-6,
  body .p-xxl-6 {
    padding-top: 2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-6,
  body .py-xxl-6,
  body .p-xxl-6 {
    padding-bottom: 2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-7,
  body .px-xxl-7,
  body .p-xxl-7 {
    padding-left: 2.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-7,
  body .px-xxl-7,
  body .p-xxl-7 {
    padding-right: 2.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-7,
  body .py-xxl-7,
  body .p-xxl-7 {
    padding-top: 2.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-7,
  body .py-xxl-7,
  body .p-xxl-7 {
    padding-bottom: 2.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-8,
  body .px-xxl-8,
  body .p-xxl-8 {
    padding-left: 3.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-8,
  body .px-xxl-8,
  body .p-xxl-8 {
    padding-right: 3.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-8,
  body .py-xxl-8,
  body .p-xxl-8 {
    padding-top: 3.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-8,
  body .py-xxl-8,
  body .p-xxl-8 {
    padding-bottom: 3.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-9,
  body .px-xxl-9,
  body .p-xxl-9 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-9,
  body .px-xxl-9,
  body .p-xxl-9 {
    padding-right: 4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-9,
  body .py-xxl-9,
  body .p-xxl-9 {
    padding-top: 4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-9,
  body .py-xxl-9,
  body .p-xxl-9 {
    padding-bottom: 4rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-10,
  body .px-xxl-10,
  body .p-xxl-10 {
    padding-left: 4.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-10,
  body .px-xxl-10,
  body .p-xxl-10 {
    padding-right: 4.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-10,
  body .py-xxl-10,
  body .p-xxl-10 {
    padding-top: 4.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-10,
  body .py-xxl-10,
  body .p-xxl-10 {
    padding-bottom: 4.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-11,
  body .px-xxl-11,
  body .p-xxl-11 {
    padding-left: 5.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-11,
  body .px-xxl-11,
  body .p-xxl-11 {
    padding-right: 5.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-11,
  body .py-xxl-11,
  body .p-xxl-11 {
    padding-top: 5.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-11,
  body .py-xxl-11,
  body .p-xxl-11 {
    padding-bottom: 5.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-12,
  body .px-xxl-12,
  body .p-xxl-12 {
    padding-left: 6.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-12,
  body .px-xxl-12,
  body .p-xxl-12 {
    padding-right: 6.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-12,
  body .py-xxl-12,
  body .p-xxl-12 {
    padding-top: 6.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-12,
  body .py-xxl-12,
  body .p-xxl-12 {
    padding-bottom: 6.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-13,
  body .px-xxl-13,
  body .p-xxl-13 {
    padding-left: 7.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-13,
  body .px-xxl-13,
  body .p-xxl-13 {
    padding-right: 7.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-13,
  body .py-xxl-13,
  body .p-xxl-13 {
    padding-top: 7.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-13,
  body .py-xxl-13,
  body .p-xxl-13 {
    padding-bottom: 7.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-14,
  body .px-xxl-14,
  body .p-xxl-14 {
    padding-left: 8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-14,
  body .px-xxl-14,
  body .p-xxl-14 {
    padding-right: 8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-14,
  body .py-xxl-14,
  body .p-xxl-14 {
    padding-top: 8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-14,
  body .py-xxl-14,
  body .p-xxl-14 {
    padding-bottom: 8rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-15,
  body .px-xxl-15,
  body .p-xxl-15 {
    padding-left: 8.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-15,
  body .px-xxl-15,
  body .p-xxl-15 {
    padding-right: 8.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-15,
  body .py-xxl-15,
  body .p-xxl-15 {
    padding-top: 8.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-15,
  body .py-xxl-15,
  body .p-xxl-15 {
    padding-bottom: 8.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-16,
  body .px-xxl-16,
  body .p-xxl-16 {
    padding-left: 9.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-16,
  body .px-xxl-16,
  body .p-xxl-16 {
    padding-right: 9.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-16,
  body .py-xxl-16,
  body .p-xxl-16 {
    padding-top: 9.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-16,
  body .py-xxl-16,
  body .p-xxl-16 {
    padding-bottom: 9.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-17,
  body .px-xxl-17,
  body .p-xxl-17 {
    padding-left: 10.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-17,
  body .px-xxl-17,
  body .p-xxl-17 {
    padding-right: 10.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-17,
  body .py-xxl-17,
  body .p-xxl-17 {
    padding-top: 10.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-17,
  body .py-xxl-17,
  body .p-xxl-17 {
    padding-bottom: 10.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-18,
  body .px-xxl-18,
  body .p-xxl-18 {
    padding-left: 11.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-18,
  body .px-xxl-18,
  body .p-xxl-18 {
    padding-right: 11.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-18,
  body .py-xxl-18,
  body .p-xxl-18 {
    padding-top: 11.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-18,
  body .py-xxl-18,
  body .p-xxl-18 {
    padding-bottom: 11.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ps-xxl-19,
  body .px-xxl-19,
  body .p-xxl-19 {
    padding-left: 12rem !important;
  }
}
@media (min-width: 1400px) {
  body .pe-xxl-19,
  body .px-xxl-19,
  body .p-xxl-19 {
    padding-right: 12rem !important;
  }
}
@media (min-width: 1400px) {
  body .pt-xxl-19,
  body .py-xxl-19,
  body .p-xxl-19 {
    padding-top: 12rem !important;
  }
}
@media (min-width: 1400px) {
  body .pb-xxl-19,
  body .py-xxl-19,
  body .p-xxl-19 {
    padding-bottom: 12rem !important;
  }
}
@media (min-width: 0) {
  body .ms-0,
  body .mx-0,
  body .m-0 {
    margin-left: 0rem !important;
  }
}
@media (min-width: 0) {
  body .me-0,
  body .mx-0,
  body .m-0 {
    margin-right: 0rem !important;
  }
}
@media (min-width: 0) {
  body .mt-0,
  body .my-0,
  body .m-0 {
    margin-top: 0rem !important;
  }
}
@media (min-width: 0) {
  body .mb-0,
  body .my-0,
  body .m-0 {
    margin-bottom: 0rem !important;
  }
}
@media (min-width: 0) {
  body .ms-1,
  body .mx-1,
  body .m-1 {
    margin-left: 0.2rem !important;
  }
}
@media (min-width: 0) {
  body .me-1,
  body .mx-1,
  body .m-1 {
    margin-right: 0.2rem !important;
  }
}
@media (min-width: 0) {
  body .mt-1,
  body .my-1,
  body .m-1 {
    margin-top: 0.2rem !important;
  }
}
@media (min-width: 0) {
  body .mb-1,
  body .my-1,
  body .m-1 {
    margin-bottom: 0.2rem !important;
  }
}
@media (min-width: 0) {
  body .ms-2,
  body .mx-2,
  body .m-2 {
    margin-left: 0.4rem !important;
  }
}
@media (min-width: 0) {
  body .me-2,
  body .mx-2,
  body .m-2 {
    margin-right: 0.4rem !important;
  }
}
@media (min-width: 0) {
  body .mt-2,
  body .my-2,
  body .m-2 {
    margin-top: 0.4rem !important;
  }
}
@media (min-width: 0) {
  body .mb-2,
  body .my-2,
  body .m-2 {
    margin-bottom: 0.4rem !important;
  }
}
@media (min-width: 0) {
  body .ms-3,
  body .mx-3,
  body .m-3 {
    margin-left: 0.8rem !important;
  }
}
@media (min-width: 0) {
  body .me-3,
  body .mx-3,
  body .m-3 {
    margin-right: 0.8rem !important;
  }
}
@media (min-width: 0) {
  body .mt-3,
  body .my-3,
  body .m-3 {
    margin-top: 0.8rem !important;
  }
}
@media (min-width: 0) {
  body .mb-3,
  body .my-3,
  body .m-3 {
    margin-bottom: 0.8rem !important;
  }
}
@media (min-width: 0) {
  body .ms-4,
  body .mx-4,
  body .m-4 {
    margin-left: 1.2rem !important;
  }
}
@media (min-width: 0) {
  body .me-4,
  body .mx-4,
  body .m-4 {
    margin-right: 1.2rem !important;
  }
}
@media (min-width: 0) {
  body .mt-4,
  body .my-4,
  body .m-4 {
    margin-top: 1.2rem !important;
  }
}
@media (min-width: 0) {
  body .mb-4,
  body .my-4,
  body .m-4 {
    margin-bottom: 1.2rem !important;
  }
}
@media (min-width: 0) {
  body .ms-5,
  body .mx-5,
  body .m-5 {
    margin-left: 1.6rem !important;
  }
}
@media (min-width: 0) {
  body .me-5,
  body .mx-5,
  body .m-5 {
    margin-right: 1.6rem !important;
  }
}
@media (min-width: 0) {
  body .mt-5,
  body .my-5,
  body .m-5 {
    margin-top: 1.6rem !important;
  }
}
@media (min-width: 0) {
  body .mb-5,
  body .my-5,
  body .m-5 {
    margin-bottom: 1.6rem !important;
  }
}
@media (min-width: 0) {
  body .ms-6,
  body .mx-6,
  body .m-6 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 0) {
  body .me-6,
  body .mx-6,
  body .m-6 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 0) {
  body .mt-6,
  body .my-6,
  body .m-6 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 0) {
  body .mb-6,
  body .my-6,
  body .m-6 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 0) {
  body .ms-7,
  body .mx-7,
  body .m-7 {
    margin-left: 2.4rem !important;
  }
}
@media (min-width: 0) {
  body .me-7,
  body .mx-7,
  body .m-7 {
    margin-right: 2.4rem !important;
  }
}
@media (min-width: 0) {
  body .mt-7,
  body .my-7,
  body .m-7 {
    margin-top: 2.4rem !important;
  }
}
@media (min-width: 0) {
  body .mb-7,
  body .my-7,
  body .m-7 {
    margin-bottom: 2.4rem !important;
  }
}
@media (min-width: 0) {
  body .ms-8,
  body .mx-8,
  body .m-8 {
    margin-left: 3.2rem !important;
  }
}
@media (min-width: 0) {
  body .me-8,
  body .mx-8,
  body .m-8 {
    margin-right: 3.2rem !important;
  }
}
@media (min-width: 0) {
  body .mt-8,
  body .my-8,
  body .m-8 {
    margin-top: 3.2rem !important;
  }
}
@media (min-width: 0) {
  body .mb-8,
  body .my-8,
  body .m-8 {
    margin-bottom: 3.2rem !important;
  }
}
@media (min-width: 0) {
  body .ms-9,
  body .mx-9,
  body .m-9 {
    margin-left: 4rem !important;
  }
}
@media (min-width: 0) {
  body .me-9,
  body .mx-9,
  body .m-9 {
    margin-right: 4rem !important;
  }
}
@media (min-width: 0) {
  body .mt-9,
  body .my-9,
  body .m-9 {
    margin-top: 4rem !important;
  }
}
@media (min-width: 0) {
  body .mb-9,
  body .my-9,
  body .m-9 {
    margin-bottom: 4rem !important;
  }
}
@media (min-width: 0) {
  body .ms-10,
  body .mx-10,
  body .m-10 {
    margin-left: 4.8rem !important;
  }
}
@media (min-width: 0) {
  body .me-10,
  body .mx-10,
  body .m-10 {
    margin-right: 4.8rem !important;
  }
}
@media (min-width: 0) {
  body .mt-10,
  body .my-10,
  body .m-10 {
    margin-top: 4.8rem !important;
  }
}
@media (min-width: 0) {
  body .mb-10,
  body .my-10,
  body .m-10 {
    margin-bottom: 4.8rem !important;
  }
}
@media (min-width: 0) {
  body .ms-11,
  body .mx-11,
  body .m-11 {
    margin-left: 5.6rem !important;
  }
}
@media (min-width: 0) {
  body .me-11,
  body .mx-11,
  body .m-11 {
    margin-right: 5.6rem !important;
  }
}
@media (min-width: 0) {
  body .mt-11,
  body .my-11,
  body .m-11 {
    margin-top: 5.6rem !important;
  }
}
@media (min-width: 0) {
  body .mb-11,
  body .my-11,
  body .m-11 {
    margin-bottom: 5.6rem !important;
  }
}
@media (min-width: 0) {
  body .ms-12,
  body .mx-12,
  body .m-12 {
    margin-left: 6.4rem !important;
  }
}
@media (min-width: 0) {
  body .me-12,
  body .mx-12,
  body .m-12 {
    margin-right: 6.4rem !important;
  }
}
@media (min-width: 0) {
  body .mt-12,
  body .my-12,
  body .m-12 {
    margin-top: 6.4rem !important;
  }
}
@media (min-width: 0) {
  body .mb-12,
  body .my-12,
  body .m-12 {
    margin-bottom: 6.4rem !important;
  }
}
@media (min-width: 0) {
  body .ms-13,
  body .mx-13,
  body .m-13 {
    margin-left: 7.2rem !important;
  }
}
@media (min-width: 0) {
  body .me-13,
  body .mx-13,
  body .m-13 {
    margin-right: 7.2rem !important;
  }
}
@media (min-width: 0) {
  body .mt-13,
  body .my-13,
  body .m-13 {
    margin-top: 7.2rem !important;
  }
}
@media (min-width: 0) {
  body .mb-13,
  body .my-13,
  body .m-13 {
    margin-bottom: 7.2rem !important;
  }
}
@media (min-width: 0) {
  body .ms-14,
  body .mx-14,
  body .m-14 {
    margin-left: 8rem !important;
  }
}
@media (min-width: 0) {
  body .me-14,
  body .mx-14,
  body .m-14 {
    margin-right: 8rem !important;
  }
}
@media (min-width: 0) {
  body .mt-14,
  body .my-14,
  body .m-14 {
    margin-top: 8rem !important;
  }
}
@media (min-width: 0) {
  body .mb-14,
  body .my-14,
  body .m-14 {
    margin-bottom: 8rem !important;
  }
}
@media (min-width: 0) {
  body .ms-15,
  body .mx-15,
  body .m-15 {
    margin-left: 8.8rem !important;
  }
}
@media (min-width: 0) {
  body .me-15,
  body .mx-15,
  body .m-15 {
    margin-right: 8.8rem !important;
  }
}
@media (min-width: 0) {
  body .mt-15,
  body .my-15,
  body .m-15 {
    margin-top: 8.8rem !important;
  }
}
@media (min-width: 0) {
  body .mb-15,
  body .my-15,
  body .m-15 {
    margin-bottom: 8.8rem !important;
  }
}
@media (min-width: 0) {
  body .ms-16,
  body .mx-16,
  body .m-16 {
    margin-left: 9.6rem !important;
  }
}
@media (min-width: 0) {
  body .me-16,
  body .mx-16,
  body .m-16 {
    margin-right: 9.6rem !important;
  }
}
@media (min-width: 0) {
  body .mt-16,
  body .my-16,
  body .m-16 {
    margin-top: 9.6rem !important;
  }
}
@media (min-width: 0) {
  body .mb-16,
  body .my-16,
  body .m-16 {
    margin-bottom: 9.6rem !important;
  }
}
@media (min-width: 0) {
  body .ms-17,
  body .mx-17,
  body .m-17 {
    margin-left: 10.4rem !important;
  }
}
@media (min-width: 0) {
  body .me-17,
  body .mx-17,
  body .m-17 {
    margin-right: 10.4rem !important;
  }
}
@media (min-width: 0) {
  body .mt-17,
  body .my-17,
  body .m-17 {
    margin-top: 10.4rem !important;
  }
}
@media (min-width: 0) {
  body .mb-17,
  body .my-17,
  body .m-17 {
    margin-bottom: 10.4rem !important;
  }
}
@media (min-width: 0) {
  body .ms-18,
  body .mx-18,
  body .m-18 {
    margin-left: 11.2rem !important;
  }
}
@media (min-width: 0) {
  body .me-18,
  body .mx-18,
  body .m-18 {
    margin-right: 11.2rem !important;
  }
}
@media (min-width: 0) {
  body .mt-18,
  body .my-18,
  body .m-18 {
    margin-top: 11.2rem !important;
  }
}
@media (min-width: 0) {
  body .mb-18,
  body .my-18,
  body .m-18 {
    margin-bottom: 11.2rem !important;
  }
}
@media (min-width: 0) {
  body .ms-19,
  body .mx-19,
  body .m-19 {
    margin-left: 12rem !important;
  }
}
@media (min-width: 0) {
  body .me-19,
  body .mx-19,
  body .m-19 {
    margin-right: 12rem !important;
  }
}
@media (min-width: 0) {
  body .mt-19,
  body .my-19,
  body .m-19 {
    margin-top: 12rem !important;
  }
}
@media (min-width: 0) {
  body .mb-19,
  body .my-19,
  body .m-19 {
    margin-bottom: 12rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-0,
  body .mx-sm-0,
  body .m-sm-0 {
    margin-left: 0rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-0,
  body .mx-sm-0,
  body .m-sm-0 {
    margin-right: 0rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-0,
  body .my-sm-0,
  body .m-sm-0 {
    margin-top: 0rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-0,
  body .my-sm-0,
  body .m-sm-0 {
    margin-bottom: 0rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-1,
  body .mx-sm-1,
  body .m-sm-1 {
    margin-left: 0.2rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-1,
  body .mx-sm-1,
  body .m-sm-1 {
    margin-right: 0.2rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-1,
  body .my-sm-1,
  body .m-sm-1 {
    margin-top: 0.2rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-1,
  body .my-sm-1,
  body .m-sm-1 {
    margin-bottom: 0.2rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-2,
  body .mx-sm-2,
  body .m-sm-2 {
    margin-left: 0.4rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-2,
  body .mx-sm-2,
  body .m-sm-2 {
    margin-right: 0.4rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-2,
  body .my-sm-2,
  body .m-sm-2 {
    margin-top: 0.4rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-2,
  body .my-sm-2,
  body .m-sm-2 {
    margin-bottom: 0.4rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-3,
  body .mx-sm-3,
  body .m-sm-3 {
    margin-left: 0.8rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-3,
  body .mx-sm-3,
  body .m-sm-3 {
    margin-right: 0.8rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-3,
  body .my-sm-3,
  body .m-sm-3 {
    margin-top: 0.8rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-3,
  body .my-sm-3,
  body .m-sm-3 {
    margin-bottom: 0.8rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-4,
  body .mx-sm-4,
  body .m-sm-4 {
    margin-left: 1.2rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-4,
  body .mx-sm-4,
  body .m-sm-4 {
    margin-right: 1.2rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-4,
  body .my-sm-4,
  body .m-sm-4 {
    margin-top: 1.2rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-4,
  body .my-sm-4,
  body .m-sm-4 {
    margin-bottom: 1.2rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-5,
  body .mx-sm-5,
  body .m-sm-5 {
    margin-left: 1.6rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-5,
  body .mx-sm-5,
  body .m-sm-5 {
    margin-right: 1.6rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-5,
  body .my-sm-5,
  body .m-sm-5 {
    margin-top: 1.6rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-5,
  body .my-sm-5,
  body .m-sm-5 {
    margin-bottom: 1.6rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-6,
  body .mx-sm-6,
  body .m-sm-6 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-6,
  body .mx-sm-6,
  body .m-sm-6 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-6,
  body .my-sm-6,
  body .m-sm-6 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-6,
  body .my-sm-6,
  body .m-sm-6 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-7,
  body .mx-sm-7,
  body .m-sm-7 {
    margin-left: 2.4rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-7,
  body .mx-sm-7,
  body .m-sm-7 {
    margin-right: 2.4rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-7,
  body .my-sm-7,
  body .m-sm-7 {
    margin-top: 2.4rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-7,
  body .my-sm-7,
  body .m-sm-7 {
    margin-bottom: 2.4rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-8,
  body .mx-sm-8,
  body .m-sm-8 {
    margin-left: 3.2rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-8,
  body .mx-sm-8,
  body .m-sm-8 {
    margin-right: 3.2rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-8,
  body .my-sm-8,
  body .m-sm-8 {
    margin-top: 3.2rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-8,
  body .my-sm-8,
  body .m-sm-8 {
    margin-bottom: 3.2rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-9,
  body .mx-sm-9,
  body .m-sm-9 {
    margin-left: 4rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-9,
  body .mx-sm-9,
  body .m-sm-9 {
    margin-right: 4rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-9,
  body .my-sm-9,
  body .m-sm-9 {
    margin-top: 4rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-9,
  body .my-sm-9,
  body .m-sm-9 {
    margin-bottom: 4rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-10,
  body .mx-sm-10,
  body .m-sm-10 {
    margin-left: 4.8rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-10,
  body .mx-sm-10,
  body .m-sm-10 {
    margin-right: 4.8rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-10,
  body .my-sm-10,
  body .m-sm-10 {
    margin-top: 4.8rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-10,
  body .my-sm-10,
  body .m-sm-10 {
    margin-bottom: 4.8rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-11,
  body .mx-sm-11,
  body .m-sm-11 {
    margin-left: 5.6rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-11,
  body .mx-sm-11,
  body .m-sm-11 {
    margin-right: 5.6rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-11,
  body .my-sm-11,
  body .m-sm-11 {
    margin-top: 5.6rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-11,
  body .my-sm-11,
  body .m-sm-11 {
    margin-bottom: 5.6rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-12,
  body .mx-sm-12,
  body .m-sm-12 {
    margin-left: 6.4rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-12,
  body .mx-sm-12,
  body .m-sm-12 {
    margin-right: 6.4rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-12,
  body .my-sm-12,
  body .m-sm-12 {
    margin-top: 6.4rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-12,
  body .my-sm-12,
  body .m-sm-12 {
    margin-bottom: 6.4rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-13,
  body .mx-sm-13,
  body .m-sm-13 {
    margin-left: 7.2rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-13,
  body .mx-sm-13,
  body .m-sm-13 {
    margin-right: 7.2rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-13,
  body .my-sm-13,
  body .m-sm-13 {
    margin-top: 7.2rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-13,
  body .my-sm-13,
  body .m-sm-13 {
    margin-bottom: 7.2rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-14,
  body .mx-sm-14,
  body .m-sm-14 {
    margin-left: 8rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-14,
  body .mx-sm-14,
  body .m-sm-14 {
    margin-right: 8rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-14,
  body .my-sm-14,
  body .m-sm-14 {
    margin-top: 8rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-14,
  body .my-sm-14,
  body .m-sm-14 {
    margin-bottom: 8rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-15,
  body .mx-sm-15,
  body .m-sm-15 {
    margin-left: 8.8rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-15,
  body .mx-sm-15,
  body .m-sm-15 {
    margin-right: 8.8rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-15,
  body .my-sm-15,
  body .m-sm-15 {
    margin-top: 8.8rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-15,
  body .my-sm-15,
  body .m-sm-15 {
    margin-bottom: 8.8rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-16,
  body .mx-sm-16,
  body .m-sm-16 {
    margin-left: 9.6rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-16,
  body .mx-sm-16,
  body .m-sm-16 {
    margin-right: 9.6rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-16,
  body .my-sm-16,
  body .m-sm-16 {
    margin-top: 9.6rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-16,
  body .my-sm-16,
  body .m-sm-16 {
    margin-bottom: 9.6rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-17,
  body .mx-sm-17,
  body .m-sm-17 {
    margin-left: 10.4rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-17,
  body .mx-sm-17,
  body .m-sm-17 {
    margin-right: 10.4rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-17,
  body .my-sm-17,
  body .m-sm-17 {
    margin-top: 10.4rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-17,
  body .my-sm-17,
  body .m-sm-17 {
    margin-bottom: 10.4rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-18,
  body .mx-sm-18,
  body .m-sm-18 {
    margin-left: 11.2rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-18,
  body .mx-sm-18,
  body .m-sm-18 {
    margin-right: 11.2rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-18,
  body .my-sm-18,
  body .m-sm-18 {
    margin-top: 11.2rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-18,
  body .my-sm-18,
  body .m-sm-18 {
    margin-bottom: 11.2rem !important;
  }
}
@media (min-width: 576px) {
  body .ms-sm-19,
  body .mx-sm-19,
  body .m-sm-19 {
    margin-left: 12rem !important;
  }
}
@media (min-width: 576px) {
  body .me-sm-19,
  body .mx-sm-19,
  body .m-sm-19 {
    margin-right: 12rem !important;
  }
}
@media (min-width: 576px) {
  body .mt-sm-19,
  body .my-sm-19,
  body .m-sm-19 {
    margin-top: 12rem !important;
  }
}
@media (min-width: 576px) {
  body .mb-sm-19,
  body .my-sm-19,
  body .m-sm-19 {
    margin-bottom: 12rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-0,
  body .mx-md-0,
  body .m-md-0 {
    margin-left: 0rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-0,
  body .mx-md-0,
  body .m-md-0 {
    margin-right: 0rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-0,
  body .my-md-0,
  body .m-md-0 {
    margin-top: 0rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-0,
  body .my-md-0,
  body .m-md-0 {
    margin-bottom: 0rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-1,
  body .mx-md-1,
  body .m-md-1 {
    margin-left: 0.2rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-1,
  body .mx-md-1,
  body .m-md-1 {
    margin-right: 0.2rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-1,
  body .my-md-1,
  body .m-md-1 {
    margin-top: 0.2rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-1,
  body .my-md-1,
  body .m-md-1 {
    margin-bottom: 0.2rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-2,
  body .mx-md-2,
  body .m-md-2 {
    margin-left: 0.4rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-2,
  body .mx-md-2,
  body .m-md-2 {
    margin-right: 0.4rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-2,
  body .my-md-2,
  body .m-md-2 {
    margin-top: 0.4rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-2,
  body .my-md-2,
  body .m-md-2 {
    margin-bottom: 0.4rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-3,
  body .mx-md-3,
  body .m-md-3 {
    margin-left: 0.8rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-3,
  body .mx-md-3,
  body .m-md-3 {
    margin-right: 0.8rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-3,
  body .my-md-3,
  body .m-md-3 {
    margin-top: 0.8rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-3,
  body .my-md-3,
  body .m-md-3 {
    margin-bottom: 0.8rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-4,
  body .mx-md-4,
  body .m-md-4 {
    margin-left: 1.2rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-4,
  body .mx-md-4,
  body .m-md-4 {
    margin-right: 1.2rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-4,
  body .my-md-4,
  body .m-md-4 {
    margin-top: 1.2rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-4,
  body .my-md-4,
  body .m-md-4 {
    margin-bottom: 1.2rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-5,
  body .mx-md-5,
  body .m-md-5 {
    margin-left: 1.6rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-5,
  body .mx-md-5,
  body .m-md-5 {
    margin-right: 1.6rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-5,
  body .my-md-5,
  body .m-md-5 {
    margin-top: 1.6rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-5,
  body .my-md-5,
  body .m-md-5 {
    margin-bottom: 1.6rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-6,
  body .mx-md-6,
  body .m-md-6 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-6,
  body .mx-md-6,
  body .m-md-6 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-6,
  body .my-md-6,
  body .m-md-6 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-6,
  body .my-md-6,
  body .m-md-6 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-7,
  body .mx-md-7,
  body .m-md-7 {
    margin-left: 2.4rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-7,
  body .mx-md-7,
  body .m-md-7 {
    margin-right: 2.4rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-7,
  body .my-md-7,
  body .m-md-7 {
    margin-top: 2.4rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-7,
  body .my-md-7,
  body .m-md-7 {
    margin-bottom: 2.4rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-8,
  body .mx-md-8,
  body .m-md-8 {
    margin-left: 3.2rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-8,
  body .mx-md-8,
  body .m-md-8 {
    margin-right: 3.2rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-8,
  body .my-md-8,
  body .m-md-8 {
    margin-top: 3.2rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-8,
  body .my-md-8,
  body .m-md-8 {
    margin-bottom: 3.2rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-9,
  body .mx-md-9,
  body .m-md-9 {
    margin-left: 4rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-9,
  body .mx-md-9,
  body .m-md-9 {
    margin-right: 4rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-9,
  body .my-md-9,
  body .m-md-9 {
    margin-top: 4rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-9,
  body .my-md-9,
  body .m-md-9 {
    margin-bottom: 4rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-10,
  body .mx-md-10,
  body .m-md-10 {
    margin-left: 4.8rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-10,
  body .mx-md-10,
  body .m-md-10 {
    margin-right: 4.8rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-10,
  body .my-md-10,
  body .m-md-10 {
    margin-top: 4.8rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-10,
  body .my-md-10,
  body .m-md-10 {
    margin-bottom: 4.8rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-11,
  body .mx-md-11,
  body .m-md-11 {
    margin-left: 5.6rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-11,
  body .mx-md-11,
  body .m-md-11 {
    margin-right: 5.6rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-11,
  body .my-md-11,
  body .m-md-11 {
    margin-top: 5.6rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-11,
  body .my-md-11,
  body .m-md-11 {
    margin-bottom: 5.6rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-12,
  body .mx-md-12,
  body .m-md-12 {
    margin-left: 6.4rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-12,
  body .mx-md-12,
  body .m-md-12 {
    margin-right: 6.4rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-12,
  body .my-md-12,
  body .m-md-12 {
    margin-top: 6.4rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-12,
  body .my-md-12,
  body .m-md-12 {
    margin-bottom: 6.4rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-13,
  body .mx-md-13,
  body .m-md-13 {
    margin-left: 7.2rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-13,
  body .mx-md-13,
  body .m-md-13 {
    margin-right: 7.2rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-13,
  body .my-md-13,
  body .m-md-13 {
    margin-top: 7.2rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-13,
  body .my-md-13,
  body .m-md-13 {
    margin-bottom: 7.2rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-14,
  body .mx-md-14,
  body .m-md-14 {
    margin-left: 8rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-14,
  body .mx-md-14,
  body .m-md-14 {
    margin-right: 8rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-14,
  body .my-md-14,
  body .m-md-14 {
    margin-top: 8rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-14,
  body .my-md-14,
  body .m-md-14 {
    margin-bottom: 8rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-15,
  body .mx-md-15,
  body .m-md-15 {
    margin-left: 8.8rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-15,
  body .mx-md-15,
  body .m-md-15 {
    margin-right: 8.8rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-15,
  body .my-md-15,
  body .m-md-15 {
    margin-top: 8.8rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-15,
  body .my-md-15,
  body .m-md-15 {
    margin-bottom: 8.8rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-16,
  body .mx-md-16,
  body .m-md-16 {
    margin-left: 9.6rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-16,
  body .mx-md-16,
  body .m-md-16 {
    margin-right: 9.6rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-16,
  body .my-md-16,
  body .m-md-16 {
    margin-top: 9.6rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-16,
  body .my-md-16,
  body .m-md-16 {
    margin-bottom: 9.6rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-17,
  body .mx-md-17,
  body .m-md-17 {
    margin-left: 10.4rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-17,
  body .mx-md-17,
  body .m-md-17 {
    margin-right: 10.4rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-17,
  body .my-md-17,
  body .m-md-17 {
    margin-top: 10.4rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-17,
  body .my-md-17,
  body .m-md-17 {
    margin-bottom: 10.4rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-18,
  body .mx-md-18,
  body .m-md-18 {
    margin-left: 11.2rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-18,
  body .mx-md-18,
  body .m-md-18 {
    margin-right: 11.2rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-18,
  body .my-md-18,
  body .m-md-18 {
    margin-top: 11.2rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-18,
  body .my-md-18,
  body .m-md-18 {
    margin-bottom: 11.2rem !important;
  }
}
@media (min-width: 768px) {
  body .ms-md-19,
  body .mx-md-19,
  body .m-md-19 {
    margin-left: 12rem !important;
  }
}
@media (min-width: 768px) {
  body .me-md-19,
  body .mx-md-19,
  body .m-md-19 {
    margin-right: 12rem !important;
  }
}
@media (min-width: 768px) {
  body .mt-md-19,
  body .my-md-19,
  body .m-md-19 {
    margin-top: 12rem !important;
  }
}
@media (min-width: 768px) {
  body .mb-md-19,
  body .my-md-19,
  body .m-md-19 {
    margin-bottom: 12rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-0,
  body .mx-lg-0,
  body .m-lg-0 {
    margin-left: 0rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-0,
  body .mx-lg-0,
  body .m-lg-0 {
    margin-right: 0rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-0,
  body .my-lg-0,
  body .m-lg-0 {
    margin-top: 0rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-0,
  body .my-lg-0,
  body .m-lg-0 {
    margin-bottom: 0rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-1,
  body .mx-lg-1,
  body .m-lg-1 {
    margin-left: 0.2rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-1,
  body .mx-lg-1,
  body .m-lg-1 {
    margin-right: 0.2rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-1,
  body .my-lg-1,
  body .m-lg-1 {
    margin-top: 0.2rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-1,
  body .my-lg-1,
  body .m-lg-1 {
    margin-bottom: 0.2rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-2,
  body .mx-lg-2,
  body .m-lg-2 {
    margin-left: 0.4rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-2,
  body .mx-lg-2,
  body .m-lg-2 {
    margin-right: 0.4rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-2,
  body .my-lg-2,
  body .m-lg-2 {
    margin-top: 0.4rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-2,
  body .my-lg-2,
  body .m-lg-2 {
    margin-bottom: 0.4rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-3,
  body .mx-lg-3,
  body .m-lg-3 {
    margin-left: 0.8rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-3,
  body .mx-lg-3,
  body .m-lg-3 {
    margin-right: 0.8rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-3,
  body .my-lg-3,
  body .m-lg-3 {
    margin-top: 0.8rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-3,
  body .my-lg-3,
  body .m-lg-3 {
    margin-bottom: 0.8rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-4,
  body .mx-lg-4,
  body .m-lg-4 {
    margin-left: 1.2rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-4,
  body .mx-lg-4,
  body .m-lg-4 {
    margin-right: 1.2rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-4,
  body .my-lg-4,
  body .m-lg-4 {
    margin-top: 1.2rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-4,
  body .my-lg-4,
  body .m-lg-4 {
    margin-bottom: 1.2rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-5,
  body .mx-lg-5,
  body .m-lg-5 {
    margin-left: 1.6rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-5,
  body .mx-lg-5,
  body .m-lg-5 {
    margin-right: 1.6rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-5,
  body .my-lg-5,
  body .m-lg-5 {
    margin-top: 1.6rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-5,
  body .my-lg-5,
  body .m-lg-5 {
    margin-bottom: 1.6rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-6,
  body .mx-lg-6,
  body .m-lg-6 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-6,
  body .mx-lg-6,
  body .m-lg-6 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-6,
  body .my-lg-6,
  body .m-lg-6 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-6,
  body .my-lg-6,
  body .m-lg-6 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-7,
  body .mx-lg-7,
  body .m-lg-7 {
    margin-left: 2.4rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-7,
  body .mx-lg-7,
  body .m-lg-7 {
    margin-right: 2.4rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-7,
  body .my-lg-7,
  body .m-lg-7 {
    margin-top: 2.4rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-7,
  body .my-lg-7,
  body .m-lg-7 {
    margin-bottom: 2.4rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-8,
  body .mx-lg-8,
  body .m-lg-8 {
    margin-left: 3.2rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-8,
  body .mx-lg-8,
  body .m-lg-8 {
    margin-right: 3.2rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-8,
  body .my-lg-8,
  body .m-lg-8 {
    margin-top: 3.2rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-8,
  body .my-lg-8,
  body .m-lg-8 {
    margin-bottom: 3.2rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-9,
  body .mx-lg-9,
  body .m-lg-9 {
    margin-left: 4rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-9,
  body .mx-lg-9,
  body .m-lg-9 {
    margin-right: 4rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-9,
  body .my-lg-9,
  body .m-lg-9 {
    margin-top: 4rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-9,
  body .my-lg-9,
  body .m-lg-9 {
    margin-bottom: 4rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-10,
  body .mx-lg-10,
  body .m-lg-10 {
    margin-left: 4.8rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-10,
  body .mx-lg-10,
  body .m-lg-10 {
    margin-right: 4.8rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-10,
  body .my-lg-10,
  body .m-lg-10 {
    margin-top: 4.8rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-10,
  body .my-lg-10,
  body .m-lg-10 {
    margin-bottom: 4.8rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-11,
  body .mx-lg-11,
  body .m-lg-11 {
    margin-left: 5.6rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-11,
  body .mx-lg-11,
  body .m-lg-11 {
    margin-right: 5.6rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-11,
  body .my-lg-11,
  body .m-lg-11 {
    margin-top: 5.6rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-11,
  body .my-lg-11,
  body .m-lg-11 {
    margin-bottom: 5.6rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-12,
  body .mx-lg-12,
  body .m-lg-12 {
    margin-left: 6.4rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-12,
  body .mx-lg-12,
  body .m-lg-12 {
    margin-right: 6.4rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-12,
  body .my-lg-12,
  body .m-lg-12 {
    margin-top: 6.4rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-12,
  body .my-lg-12,
  body .m-lg-12 {
    margin-bottom: 6.4rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-13,
  body .mx-lg-13,
  body .m-lg-13 {
    margin-left: 7.2rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-13,
  body .mx-lg-13,
  body .m-lg-13 {
    margin-right: 7.2rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-13,
  body .my-lg-13,
  body .m-lg-13 {
    margin-top: 7.2rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-13,
  body .my-lg-13,
  body .m-lg-13 {
    margin-bottom: 7.2rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-14,
  body .mx-lg-14,
  body .m-lg-14 {
    margin-left: 8rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-14,
  body .mx-lg-14,
  body .m-lg-14 {
    margin-right: 8rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-14,
  body .my-lg-14,
  body .m-lg-14 {
    margin-top: 8rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-14,
  body .my-lg-14,
  body .m-lg-14 {
    margin-bottom: 8rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-15,
  body .mx-lg-15,
  body .m-lg-15 {
    margin-left: 8.8rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-15,
  body .mx-lg-15,
  body .m-lg-15 {
    margin-right: 8.8rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-15,
  body .my-lg-15,
  body .m-lg-15 {
    margin-top: 8.8rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-15,
  body .my-lg-15,
  body .m-lg-15 {
    margin-bottom: 8.8rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-16,
  body .mx-lg-16,
  body .m-lg-16 {
    margin-left: 9.6rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-16,
  body .mx-lg-16,
  body .m-lg-16 {
    margin-right: 9.6rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-16,
  body .my-lg-16,
  body .m-lg-16 {
    margin-top: 9.6rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-16,
  body .my-lg-16,
  body .m-lg-16 {
    margin-bottom: 9.6rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-17,
  body .mx-lg-17,
  body .m-lg-17 {
    margin-left: 10.4rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-17,
  body .mx-lg-17,
  body .m-lg-17 {
    margin-right: 10.4rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-17,
  body .my-lg-17,
  body .m-lg-17 {
    margin-top: 10.4rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-17,
  body .my-lg-17,
  body .m-lg-17 {
    margin-bottom: 10.4rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-18,
  body .mx-lg-18,
  body .m-lg-18 {
    margin-left: 11.2rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-18,
  body .mx-lg-18,
  body .m-lg-18 {
    margin-right: 11.2rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-18,
  body .my-lg-18,
  body .m-lg-18 {
    margin-top: 11.2rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-18,
  body .my-lg-18,
  body .m-lg-18 {
    margin-bottom: 11.2rem !important;
  }
}
@media (min-width: 992px) {
  body .ms-lg-19,
  body .mx-lg-19,
  body .m-lg-19 {
    margin-left: 12rem !important;
  }
}
@media (min-width: 992px) {
  body .me-lg-19,
  body .mx-lg-19,
  body .m-lg-19 {
    margin-right: 12rem !important;
  }
}
@media (min-width: 992px) {
  body .mt-lg-19,
  body .my-lg-19,
  body .m-lg-19 {
    margin-top: 12rem !important;
  }
}
@media (min-width: 992px) {
  body .mb-lg-19,
  body .my-lg-19,
  body .m-lg-19 {
    margin-bottom: 12rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-0,
  body .mx-xl-0,
  body .m-xl-0 {
    margin-left: 0rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-0,
  body .mx-xl-0,
  body .m-xl-0 {
    margin-right: 0rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-0,
  body .my-xl-0,
  body .m-xl-0 {
    margin-top: 0rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-0,
  body .my-xl-0,
  body .m-xl-0 {
    margin-bottom: 0rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-1,
  body .mx-xl-1,
  body .m-xl-1 {
    margin-left: 0.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-1,
  body .mx-xl-1,
  body .m-xl-1 {
    margin-right: 0.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-1,
  body .my-xl-1,
  body .m-xl-1 {
    margin-top: 0.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-1,
  body .my-xl-1,
  body .m-xl-1 {
    margin-bottom: 0.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-2,
  body .mx-xl-2,
  body .m-xl-2 {
    margin-left: 0.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-2,
  body .mx-xl-2,
  body .m-xl-2 {
    margin-right: 0.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-2,
  body .my-xl-2,
  body .m-xl-2 {
    margin-top: 0.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-2,
  body .my-xl-2,
  body .m-xl-2 {
    margin-bottom: 0.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-3,
  body .mx-xl-3,
  body .m-xl-3 {
    margin-left: 0.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-3,
  body .mx-xl-3,
  body .m-xl-3 {
    margin-right: 0.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-3,
  body .my-xl-3,
  body .m-xl-3 {
    margin-top: 0.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-3,
  body .my-xl-3,
  body .m-xl-3 {
    margin-bottom: 0.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-4,
  body .mx-xl-4,
  body .m-xl-4 {
    margin-left: 1.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-4,
  body .mx-xl-4,
  body .m-xl-4 {
    margin-right: 1.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-4,
  body .my-xl-4,
  body .m-xl-4 {
    margin-top: 1.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-4,
  body .my-xl-4,
  body .m-xl-4 {
    margin-bottom: 1.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-5,
  body .mx-xl-5,
  body .m-xl-5 {
    margin-left: 1.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-5,
  body .mx-xl-5,
  body .m-xl-5 {
    margin-right: 1.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-5,
  body .my-xl-5,
  body .m-xl-5 {
    margin-top: 1.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-5,
  body .my-xl-5,
  body .m-xl-5 {
    margin-bottom: 1.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-6,
  body .mx-xl-6,
  body .m-xl-6 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-6,
  body .mx-xl-6,
  body .m-xl-6 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-6,
  body .my-xl-6,
  body .m-xl-6 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-6,
  body .my-xl-6,
  body .m-xl-6 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-7,
  body .mx-xl-7,
  body .m-xl-7 {
    margin-left: 2.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-7,
  body .mx-xl-7,
  body .m-xl-7 {
    margin-right: 2.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-7,
  body .my-xl-7,
  body .m-xl-7 {
    margin-top: 2.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-7,
  body .my-xl-7,
  body .m-xl-7 {
    margin-bottom: 2.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-8,
  body .mx-xl-8,
  body .m-xl-8 {
    margin-left: 3.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-8,
  body .mx-xl-8,
  body .m-xl-8 {
    margin-right: 3.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-8,
  body .my-xl-8,
  body .m-xl-8 {
    margin-top: 3.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-8,
  body .my-xl-8,
  body .m-xl-8 {
    margin-bottom: 3.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-9,
  body .mx-xl-9,
  body .m-xl-9 {
    margin-left: 4rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-9,
  body .mx-xl-9,
  body .m-xl-9 {
    margin-right: 4rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-9,
  body .my-xl-9,
  body .m-xl-9 {
    margin-top: 4rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-9,
  body .my-xl-9,
  body .m-xl-9 {
    margin-bottom: 4rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-10,
  body .mx-xl-10,
  body .m-xl-10 {
    margin-left: 4.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-10,
  body .mx-xl-10,
  body .m-xl-10 {
    margin-right: 4.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-10,
  body .my-xl-10,
  body .m-xl-10 {
    margin-top: 4.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-10,
  body .my-xl-10,
  body .m-xl-10 {
    margin-bottom: 4.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-11,
  body .mx-xl-11,
  body .m-xl-11 {
    margin-left: 5.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-11,
  body .mx-xl-11,
  body .m-xl-11 {
    margin-right: 5.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-11,
  body .my-xl-11,
  body .m-xl-11 {
    margin-top: 5.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-11,
  body .my-xl-11,
  body .m-xl-11 {
    margin-bottom: 5.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-12,
  body .mx-xl-12,
  body .m-xl-12 {
    margin-left: 6.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-12,
  body .mx-xl-12,
  body .m-xl-12 {
    margin-right: 6.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-12,
  body .my-xl-12,
  body .m-xl-12 {
    margin-top: 6.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-12,
  body .my-xl-12,
  body .m-xl-12 {
    margin-bottom: 6.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-13,
  body .mx-xl-13,
  body .m-xl-13 {
    margin-left: 7.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-13,
  body .mx-xl-13,
  body .m-xl-13 {
    margin-right: 7.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-13,
  body .my-xl-13,
  body .m-xl-13 {
    margin-top: 7.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-13,
  body .my-xl-13,
  body .m-xl-13 {
    margin-bottom: 7.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-14,
  body .mx-xl-14,
  body .m-xl-14 {
    margin-left: 8rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-14,
  body .mx-xl-14,
  body .m-xl-14 {
    margin-right: 8rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-14,
  body .my-xl-14,
  body .m-xl-14 {
    margin-top: 8rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-14,
  body .my-xl-14,
  body .m-xl-14 {
    margin-bottom: 8rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-15,
  body .mx-xl-15,
  body .m-xl-15 {
    margin-left: 8.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-15,
  body .mx-xl-15,
  body .m-xl-15 {
    margin-right: 8.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-15,
  body .my-xl-15,
  body .m-xl-15 {
    margin-top: 8.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-15,
  body .my-xl-15,
  body .m-xl-15 {
    margin-bottom: 8.8rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-16,
  body .mx-xl-16,
  body .m-xl-16 {
    margin-left: 9.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-16,
  body .mx-xl-16,
  body .m-xl-16 {
    margin-right: 9.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-16,
  body .my-xl-16,
  body .m-xl-16 {
    margin-top: 9.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-16,
  body .my-xl-16,
  body .m-xl-16 {
    margin-bottom: 9.6rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-17,
  body .mx-xl-17,
  body .m-xl-17 {
    margin-left: 10.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-17,
  body .mx-xl-17,
  body .m-xl-17 {
    margin-right: 10.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-17,
  body .my-xl-17,
  body .m-xl-17 {
    margin-top: 10.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-17,
  body .my-xl-17,
  body .m-xl-17 {
    margin-bottom: 10.4rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-18,
  body .mx-xl-18,
  body .m-xl-18 {
    margin-left: 11.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-18,
  body .mx-xl-18,
  body .m-xl-18 {
    margin-right: 11.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-18,
  body .my-xl-18,
  body .m-xl-18 {
    margin-top: 11.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-18,
  body .my-xl-18,
  body .m-xl-18 {
    margin-bottom: 11.2rem !important;
  }
}
@media (min-width: 1200px) {
  body .ms-xl-19,
  body .mx-xl-19,
  body .m-xl-19 {
    margin-left: 12rem !important;
  }
}
@media (min-width: 1200px) {
  body .me-xl-19,
  body .mx-xl-19,
  body .m-xl-19 {
    margin-right: 12rem !important;
  }
}
@media (min-width: 1200px) {
  body .mt-xl-19,
  body .my-xl-19,
  body .m-xl-19 {
    margin-top: 12rem !important;
  }
}
@media (min-width: 1200px) {
  body .mb-xl-19,
  body .my-xl-19,
  body .m-xl-19 {
    margin-bottom: 12rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-0,
  body .mx-xxl-0,
  body .m-xxl-0 {
    margin-left: 0rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-0,
  body .mx-xxl-0,
  body .m-xxl-0 {
    margin-right: 0rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-0,
  body .my-xxl-0,
  body .m-xxl-0 {
    margin-top: 0rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-0,
  body .my-xxl-0,
  body .m-xxl-0 {
    margin-bottom: 0rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-1,
  body .mx-xxl-1,
  body .m-xxl-1 {
    margin-left: 0.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-1,
  body .mx-xxl-1,
  body .m-xxl-1 {
    margin-right: 0.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-1,
  body .my-xxl-1,
  body .m-xxl-1 {
    margin-top: 0.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-1,
  body .my-xxl-1,
  body .m-xxl-1 {
    margin-bottom: 0.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-2,
  body .mx-xxl-2,
  body .m-xxl-2 {
    margin-left: 0.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-2,
  body .mx-xxl-2,
  body .m-xxl-2 {
    margin-right: 0.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-2,
  body .my-xxl-2,
  body .m-xxl-2 {
    margin-top: 0.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-2,
  body .my-xxl-2,
  body .m-xxl-2 {
    margin-bottom: 0.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-3,
  body .mx-xxl-3,
  body .m-xxl-3 {
    margin-left: 0.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-3,
  body .mx-xxl-3,
  body .m-xxl-3 {
    margin-right: 0.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-3,
  body .my-xxl-3,
  body .m-xxl-3 {
    margin-top: 0.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-3,
  body .my-xxl-3,
  body .m-xxl-3 {
    margin-bottom: 0.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-4,
  body .mx-xxl-4,
  body .m-xxl-4 {
    margin-left: 1.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-4,
  body .mx-xxl-4,
  body .m-xxl-4 {
    margin-right: 1.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-4,
  body .my-xxl-4,
  body .m-xxl-4 {
    margin-top: 1.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-4,
  body .my-xxl-4,
  body .m-xxl-4 {
    margin-bottom: 1.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-5,
  body .mx-xxl-5,
  body .m-xxl-5 {
    margin-left: 1.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-5,
  body .mx-xxl-5,
  body .m-xxl-5 {
    margin-right: 1.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-5,
  body .my-xxl-5,
  body .m-xxl-5 {
    margin-top: 1.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-5,
  body .my-xxl-5,
  body .m-xxl-5 {
    margin-bottom: 1.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-6,
  body .mx-xxl-6,
  body .m-xxl-6 {
    margin-left: 2rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-6,
  body .mx-xxl-6,
  body .m-xxl-6 {
    margin-right: 2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-6,
  body .my-xxl-6,
  body .m-xxl-6 {
    margin-top: 2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-6,
  body .my-xxl-6,
  body .m-xxl-6 {
    margin-bottom: 2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-7,
  body .mx-xxl-7,
  body .m-xxl-7 {
    margin-left: 2.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-7,
  body .mx-xxl-7,
  body .m-xxl-7 {
    margin-right: 2.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-7,
  body .my-xxl-7,
  body .m-xxl-7 {
    margin-top: 2.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-7,
  body .my-xxl-7,
  body .m-xxl-7 {
    margin-bottom: 2.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-8,
  body .mx-xxl-8,
  body .m-xxl-8 {
    margin-left: 3.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-8,
  body .mx-xxl-8,
  body .m-xxl-8 {
    margin-right: 3.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-8,
  body .my-xxl-8,
  body .m-xxl-8 {
    margin-top: 3.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-8,
  body .my-xxl-8,
  body .m-xxl-8 {
    margin-bottom: 3.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-9,
  body .mx-xxl-9,
  body .m-xxl-9 {
    margin-left: 4rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-9,
  body .mx-xxl-9,
  body .m-xxl-9 {
    margin-right: 4rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-9,
  body .my-xxl-9,
  body .m-xxl-9 {
    margin-top: 4rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-9,
  body .my-xxl-9,
  body .m-xxl-9 {
    margin-bottom: 4rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-10,
  body .mx-xxl-10,
  body .m-xxl-10 {
    margin-left: 4.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-10,
  body .mx-xxl-10,
  body .m-xxl-10 {
    margin-right: 4.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-10,
  body .my-xxl-10,
  body .m-xxl-10 {
    margin-top: 4.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-10,
  body .my-xxl-10,
  body .m-xxl-10 {
    margin-bottom: 4.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-11,
  body .mx-xxl-11,
  body .m-xxl-11 {
    margin-left: 5.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-11,
  body .mx-xxl-11,
  body .m-xxl-11 {
    margin-right: 5.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-11,
  body .my-xxl-11,
  body .m-xxl-11 {
    margin-top: 5.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-11,
  body .my-xxl-11,
  body .m-xxl-11 {
    margin-bottom: 5.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-12,
  body .mx-xxl-12,
  body .m-xxl-12 {
    margin-left: 6.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-12,
  body .mx-xxl-12,
  body .m-xxl-12 {
    margin-right: 6.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-12,
  body .my-xxl-12,
  body .m-xxl-12 {
    margin-top: 6.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-12,
  body .my-xxl-12,
  body .m-xxl-12 {
    margin-bottom: 6.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-13,
  body .mx-xxl-13,
  body .m-xxl-13 {
    margin-left: 7.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-13,
  body .mx-xxl-13,
  body .m-xxl-13 {
    margin-right: 7.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-13,
  body .my-xxl-13,
  body .m-xxl-13 {
    margin-top: 7.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-13,
  body .my-xxl-13,
  body .m-xxl-13 {
    margin-bottom: 7.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-14,
  body .mx-xxl-14,
  body .m-xxl-14 {
    margin-left: 8rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-14,
  body .mx-xxl-14,
  body .m-xxl-14 {
    margin-right: 8rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-14,
  body .my-xxl-14,
  body .m-xxl-14 {
    margin-top: 8rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-14,
  body .my-xxl-14,
  body .m-xxl-14 {
    margin-bottom: 8rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-15,
  body .mx-xxl-15,
  body .m-xxl-15 {
    margin-left: 8.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-15,
  body .mx-xxl-15,
  body .m-xxl-15 {
    margin-right: 8.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-15,
  body .my-xxl-15,
  body .m-xxl-15 {
    margin-top: 8.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-15,
  body .my-xxl-15,
  body .m-xxl-15 {
    margin-bottom: 8.8rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-16,
  body .mx-xxl-16,
  body .m-xxl-16 {
    margin-left: 9.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-16,
  body .mx-xxl-16,
  body .m-xxl-16 {
    margin-right: 9.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-16,
  body .my-xxl-16,
  body .m-xxl-16 {
    margin-top: 9.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-16,
  body .my-xxl-16,
  body .m-xxl-16 {
    margin-bottom: 9.6rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-17,
  body .mx-xxl-17,
  body .m-xxl-17 {
    margin-left: 10.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-17,
  body .mx-xxl-17,
  body .m-xxl-17 {
    margin-right: 10.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-17,
  body .my-xxl-17,
  body .m-xxl-17 {
    margin-top: 10.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-17,
  body .my-xxl-17,
  body .m-xxl-17 {
    margin-bottom: 10.4rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-18,
  body .mx-xxl-18,
  body .m-xxl-18 {
    margin-left: 11.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-18,
  body .mx-xxl-18,
  body .m-xxl-18 {
    margin-right: 11.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-18,
  body .my-xxl-18,
  body .m-xxl-18 {
    margin-top: 11.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-18,
  body .my-xxl-18,
  body .m-xxl-18 {
    margin-bottom: 11.2rem !important;
  }
}
@media (min-width: 1400px) {
  body .ms-xxl-19,
  body .mx-xxl-19,
  body .m-xxl-19 {
    margin-left: 12rem !important;
  }
}
@media (min-width: 1400px) {
  body .me-xxl-19,
  body .mx-xxl-19,
  body .m-xxl-19 {
    margin-right: 12rem !important;
  }
}
@media (min-width: 1400px) {
  body .mt-xxl-19,
  body .my-xxl-19,
  body .m-xxl-19 {
    margin-top: 12rem !important;
  }
}
@media (min-width: 1400px) {
  body .mb-xxl-19,
  body .my-xxl-19,
  body .m-xxl-19 {
    margin-bottom: 12rem !important;
  }
}
body i[class*='le-icon-'] {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-color: #181818;
  flex-shrink: 0;
}
body i[class*='le-icon-'].rot-180 {
  transform: rotate(180deg);
}
body i[class*='le-icon-'].rot-90 {
  transform: rotate(90deg);
}
body i[class*='le-icon-'].rot-270 {
  transform: rotate(270deg);
}
body i[class*='le-icon-'].spinner {
  margin-left: 8px;
  vertical-align: middle;
  scale: 75%;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
body i.le-icon-search {
  mask: url('../thule/global/icons/search.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/search.svg') no-repeat center;
}
body i.le-icon-shopping-cart {
  mask: url('../thule/global/icons/shopping-cart.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/shopping-cart.svg') no-repeat center;
}
body i.le-icon-menu {
  mask: url('../thule/global/icons/menu.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/menu.svg') no-repeat center;
}
body i.le-icon-globe {
  mask: url('../thule/global/icons/globe.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/globe.svg') no-repeat center;
}
body i.le-icon-x {
  mask: url('../thule/global/icons/x.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/x.svg') no-repeat center;
}
body i.le-icon-user {
  mask: url('../thule/global/icons/user.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/user.svg') no-repeat center;
}
body i.le-icon-map-pin {
  mask: url('../thule/global/icons/map-pin.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/map-pin.svg') no-repeat center;
}
body i.le-icon-filter {
  mask: url('../thule/global/icons/filter.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/filter.svg') no-repeat center;
}
body i.le-icon-plus {
  mask: url('../thule/global/icons/plus.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/plus.svg') no-repeat center;
}
body i.le-icon-minus {
  mask: url('../thule/global/icons/minus.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/minus.svg') no-repeat center;
}
body i.le-icon-chevron {
  mask: url('../thule/global/icons/chevron.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/chevron.svg') no-repeat center;
}
body i.le-icon-plus-square {
  mask: url('../thule/global/icons/plus-square.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/plus-square.svg') no-repeat center;
}
body i.le-icon-fitguide {
  mask: url('../thule/global/icons/fitguide.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/fitguide.svg') no-repeat center;
}
body i.le-icon-arrow {
  mask: url('../thule/global/icons/arrow.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/arrow.svg') no-repeat center;
}
body i.le-icon-info {
  mask: url('../thule/global/icons/info.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/info.svg') no-repeat center;
}
body i.le-icon-file-text {
  mask: url('../thule/global/icons/file-text.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/file-text.svg') no-repeat center;
}
body i.le-icon-package {
  mask: url('../thule/global/icons/package.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/package.svg') no-repeat center;
}
body i.le-icon-reply {
  mask: url('../thule/global/icons/reply.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/reply.svg') no-repeat center;
}
body i.le-icon-shield-check {
  mask: url('../thule/global/icons/shield-check.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/shield-check.svg') no-repeat center;
}
body i.le-icon-warning {
  mask: url('../thule/global/icons/warning.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/warning.svg') no-repeat center;
}
body i.le-icon-corner-up-left {
  mask: url('../thule/global/icons/corner-up-left.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/corner-up-left.svg') no-repeat center;
}
body i.le-icon-check {
  mask: url('../thule/global/icons/check.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/check.svg') no-repeat center;
}
body i.le-icon-check-small {
  mask: url('../thule/global/icons/check-small.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/check-small.svg') no-repeat center;
}
body i.le-icon-dots {
  mask: url('../thule/global/icons/dots.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/dots.svg') no-repeat center;
}
body i.le-icon-edit {
  mask: url('../thule/global/icons/edit.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/edit.svg') no-repeat center;
}
body i.le-icon-trash {
  mask: url('../thule/global/icons/trash.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/trash.svg') no-repeat center;
}
body i.le-icon-undo {
  mask: url('../thule/global/icons/undo.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/undo.svg') no-repeat center;
}
body i.le-icon-file {
  mask: url('../thule/global/icons/file.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/file.svg') no-repeat center;
}
body i.le-icon-excel-spreadsheet {
  mask: url('../thule/global/icons/excel-spreadsheet.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/excel-spreadsheet.svg') no-repeat center;
}
body i.le-icon-publish {
  mask: url('../thule/global/icons/publish.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/publish.svg') no-repeat center;
}
body i.le-icon-copy-to {
  mask: url('../thule/global/icons/copy-to.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/copy-to.svg') no-repeat center;
}
body i.le-icon-calendar {
  mask: url('../thule/global/icons/calendar.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/calendar.svg') no-repeat center;
}
body i.le-icon-minus-circle {
  mask: url('../thule/global/icons/minus-circle.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/minus-circle.svg') no-repeat center;
}
body i.le-icon-rename {
  mask: url('../thule/global/icons/rename.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/rename.svg') no-repeat center;
}
body i.le-icon-download {
  mask: url('../thule/global/icons/download.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/download.svg') no-repeat center;
}
body i.le-icon-preview {
  mask: url('../thule/global/icons/preview.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/preview.svg') no-repeat center;
}
body i.le-icon-party-popper {
  mask: url('../thule/global/icons/party-popper.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/party-popper.svg') no-repeat center;
}
body i.le-icon-baby {
  mask: url('../thule/global/icons/baby.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/baby.svg') no-repeat center;
}
body i.le-icon-home {
  mask: url('../thule/global/icons/home.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/home.svg') no-repeat center;
}
body i.le-icon-star {
  mask: url('../thule/global/icons/star.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/star.svg') no-repeat center;
}
body i.le-icon-timer {
  mask: url('../thule/global/icons/timer.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/timer.svg') no-repeat center;
}
body i.le-icon-eye {
  mask: url('../thule/global/icons/eye.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/eye.svg') no-repeat center;
}
body i.le-icon-eye-off {
  mask: url('../thule/global/icons/eye-off.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/eye-off.svg') no-repeat center;
}
body i.le-icon-radio {
  mask: url('../thule/global/icons/radio.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/radio.svg') no-repeat center;
}
body i.le-icon-radio-selected {
  mask: url('../thule/global/icons/radio-selected.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/radio-selected.svg') no-repeat center;
}
body i.le-icon-bg {
  mask: url('../thule/global/icons/bg.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/bg.svg') no-repeat center;
}
body i.le-icon-external-link {
  mask: url('../thule/global/icons/external-link.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/external-link.svg') no-repeat center;
}
body i.le-icon-cut {
  mask: url('../thule/global/icons/cut.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/cut.svg') no-repeat center;
}
body i.le-icon-play {
  mask: url('../thule/global/icons/play.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/play.svg') no-repeat center;
}
body i.le-icon-car-seat {
  mask: url('../thule/global/icons/car-seat.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/car-seat.svg') no-repeat center;
}
body i.le-icon-dog-crate {
  mask: url('../thule/global/icons/dog-crate.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/dog-crate.svg') no-repeat center;
}
body i.le-icon-rooftop-tent {
  mask: url('../thule/global/icons/rooftop-tent.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/rooftop-tent.svg') no-repeat center;
}
body i.le-icon-vector {
  mask: url('../thule/global/icons/vector.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/vector.svg') no-repeat center;
}
body i.le-icon-upload {
  mask: url('../thule/global/icons/upload.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/upload.svg') no-repeat center;
}
body i.le-icon-image-off {
  mask: url('../thule/global/icons/image-off.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/image-off.svg') no-repeat center;
}
body i.le-icon-copy {
  mask: url('../thule/global/icons/copy.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/copy.svg') no-repeat center;
}
body i.le-icon-chevron-first {
  mask: url('../thule/global/icons/chevron-first.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/chevron-first.svg') no-repeat center;
}
body i.le-icon-chevron-last {
  mask: url('../thule/global/icons/chevron-last.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/chevron-last.svg') no-repeat center;
}
body i.le-icon-arrow-long {
  mask: url('../thule/global/icons/arrow-long.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/arrow-long.svg') no-repeat center;
}
body i.le-icon-heart {
  mask: url('../thule/global/icons/heart.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/heart.svg') no-repeat center;
}
body i.le-icon-heart-fill {
  mask: url('../thule/global/icons/heart-fill.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/heart-fill.svg') no-repeat center;
}
body i.le-icon-alert-circle {
  mask: url('../thule/global/icons/alert-circle.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/alert-circle.svg') no-repeat center;
}
body i.le-icon-check-circle {
  mask: url('../thule/global/icons/check-circle.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/check-circle.svg') no-repeat center;
}
body i.le-icon-plus-circle {
  mask: url('../thule/global/icons/plus-circle.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/plus-circle.svg') no-repeat center;
}
body i.le-icon-x-circle {
  mask: url('../thule/global/icons/x-circle.svg') no-repeat center;
  -webkit-mask: url('../thule/global/icons/x-circle.svg') no-repeat center;
}
.thule-logo::before {
  font-family: 'frg-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  content: '\e924';
  font-size: 2.4rem;
}
.thule-container,
.thule-container--full-width.skip-mobile {
  padding-left: 2rem;
  padding-right: 2rem;
}
.thule-container .row,
.thule-container--full-width.skip-mobile .row {
  margin-left: -2rem;
  margin-right: -2rem;
}
.thule-container .row > *,
.thule-container--full-width.skip-mobile .row > * {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media screen and (max-width: 991px) {
  .thule-container.full-width-in-mobile,
  .thule-container--full-width.skip-mobile.full-width-in-mobile {
    width: 100vw;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
  .thule-container.full-width-in-mobile .row,
  .thule-container--full-width.skip-mobile.full-width-in-mobile .row {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .thule-container.full-width-in-mobile .row > *,
  .thule-container--full-width.skip-mobile.full-width-in-mobile .row > * {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
.thule-container--full-width {
  width: 100%;
}
@media screen and (max-width: 991px) {
  .thule-container--full-width:not(.skip-mobile) .row {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .thule-container--full-width:not(.skip-mobile) .row > * {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .thule-container {
    padding-left: calc(3%);
    padding-right: calc(3%);
  }
  .thule-container .row {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .thule-container .row > * {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .thule-container--full-width {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .thule-container--full-width .row {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .thule-container--full-width .row > * {
    padding-left: 0;
    padding-right: 0;
  }
}
.color-selector-container .color-selector ul {
  font-size: 0;
  margin-top: -0.8rem;
}
.color-selector-container .color-selector li {
  display: inline-block;
  height: 20px;
  width: 20px;
}
@media (max-width: 991px) {
  .color-selector-container .color-selector li {
    margin-right: 0.8rem;
  }
}
@media (min-width: 992px) {
  .color-selector-container .color-selector li {
    height: 40px;
    width: 44px;
  }
}
.color-selector-container .color-selector a {
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 1px solid #fff;
  background-size: cover;
  image-rendering: pixelated;
  background-repeat: no-repeat;
}
.color-selector-container .color-selector a.active {
  box-shadow: 0 0 0 1px #181818;
}
@media (min-width: 992px) {
  .color-selector-container .color-selector a {
    height: 40px;
    width: 40px;
    border: 4px solid #fff;
  }
}
.color-selector-container .selected-swatch-name {
  color: #474747;
}
.space-below-small {
  margin-bottom: 6.4rem;
}
@media (min-width: 992px) {
  .space-below-small {
    margin-bottom: 9.6rem;
  }
}
.space-below {
  margin-bottom: 6.4rem;
}
.new-flag,
.coming-soon-flag {
  z-index: 1;
  position: absolute;
  top: 1.2rem;
  left: 1.2rem;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0.6rem;
  font-size: 1.2rem;
  line-height: 1;
  background-color: white;
  color: #474747;
}
.new-flag::after {
  content: 'New';
}
.coming-soon-flag::after {
  content: 'Coming soon';
}
.caselogic-site .coming-soon-flag,
.extranet-site .coming-soon-flag,
.caselogic-site .new-flag,
.extranet-site .new-flag {
  background-color: #f5f5f5;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-spin {
  animation: spin 1.5s linear infinite;
}
.klarna-loader {
  display: flex;
  margin: 5em auto 5em auto;
  justify-content: center;
}
.ytPlayBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ytPlayBtn .ytPlayIcon {
  height: 5.6rem;
  width: 5.6rem;
  align-items: center;
  justify-content: center;
  border-radius: 5.6rem;
  display: flex;
  background-color: #fff;
  pointer-events: none;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
}
.fit-indicator.rackmounting,
.fit-indicator.confirmed {
  background-color: #148914;
}
.fit-indicator.noselection {
  background-color: #181818;
}
.fit-indicator.unconfirmed {
  background-color: #474747;
}
.fit-indicator.nofit {
  background-color: #af0a0a;
}
.horizontalLine {
  border-bottom: 0.1rem #e0e0e0 solid;
  width: 100%;
}
.aspect-ratio-1x1 {
  aspect-ratio: 1 / 1;
}
.alert-message {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert-message.error {
  color: black;
  border-radius: 0;
  border: 1px #d40303 solid;
  background-color: rgba(255, 0, 0, 0.1);
}
.alert-message.warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.alert-message.success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert-message.fadeout {
  animation: fadeout 1s forwards;
}
.alert-message .msg-close {
  cursor: pointer;
}
@keyframes fadeout {
  0% {
    max-height: 1200px;
    font-size: 1em;
    line-height: "100%";
  }
  100% {
    max-height: 0;
    opacity: 0;
    border-width: 0;
    font-size: 1em;
    line-height: "100%";
  }
}
.input-item.global {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.input-item.global.error label input {
  border: 0.1rem solid #d40303;
  background: rgba(255, 0, 0, 0.1);
}
.input-item.global .control-label {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.input-item.global label input:disabled {
  background-color: #ebebe4;
}
.input-item.global label.error {
  color: #d40303;
}
.box-shadow--red {
  box-shadow: 0 0 8px 3px red;
}
.imageSlider {
  position: relative;
}
.imageSlider .wrapper {
  border: 0.1rem solid #e0e0e0;
}
.imageSlider .imageSlider__zoom {
  background-color: #fff;
  height: 100vh;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1100;
}
@media screen and (max-width: 991px) {
  .imageSlider .imageSlider__zoom {
    scrollbar-width: none;
  }
  .imageSlider .imageSlider__zoom img {
    max-width: unset;
    width: 200vw;
  }
}
.imageSlider .imageSlider__zoom .zoom__close {
  justify-content: center;
  right: 7rem;
  top: 6.4rem;
  width: 3.6rem;
  position: fixed;
}
.imageSlider .zoomText {
  position: absolute;
  top: 2rem;
  right: 0;
  color: #474747;
}
.imageSlider .imageSlider__count {
  background-color: #f5f5f5;
  position: absolute;
  bottom: 1.2rem;
  padding: 0.4rem;
}
.imageSlider .splide .splide__slide {
  cursor: pointer;
}
.imageSlider .splide .splide__arrows {
  position: absolute;
  bottom: 2.6rem;
  right: 0;
}
.imageSlider .splide .splide__arrows .splide__arrow {
  border-radius: 0;
  background: #f5f5f5;
}
.imageSlider .splide .splide__arrows .splide__arrow--prev {
  left: -6.4rem;
}
.imageSlider .splide .splide__arrows .splide__arrow--next {
  right: 0;
}
.imageSlider .progress-container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  left: 0;
  bottom: 0;
}
.imageSlider .progress-container .progress-bar {
  background-color: #f5f5f5;
  height: 4px;
  position: relative;
  width: 100%;
}
.imageSlider .progress-container .progress-bar .progress-fill {
  width: 100%;
  background-color: #474747;
  height: 4px;
  position: absolute;
}
.notification__inline,
.dropship-restriction {
  border-width: 0.1rem;
  border-style: solid;
}
.notification__toast {
  position: fixed;
  right: 4rem;
  bottom: 3.2rem;
  width: 381px;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}
@media (max-width: 991px) {
  .notification__toast {
    width: calc(100% - 2rem*2);
    right: 2rem;
  }
}
@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}
.notification__inline,
.notification__toast,
.dropship-restriction {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.notification__inline .notification__message.disabled,
.notification__toast .notification__message.disabled,
.dropship-restriction .notification__message.disabled,
.notification__inline .notification__title.disabled,
.notification__toast .notification__title.disabled,
.dropship-restriction .notification__title.disabled {
  text-decoration: line-through;
}
.notification__inline.size--sm,
.notification__toast.size--sm,
.dropship-restriction.size--sm {
  padding: 0.8rem 1.2rem;
}
.notification__inline.size--sm *,
.notification__toast.size--sm *,
.dropship-restriction.size--sm * {
  font-size: 12px;
}
.notification__inline.size--sm .show-icon .notification__message,
.notification__toast.size--sm .show-icon .notification__message,
.dropship-restriction.size--sm .show-icon .notification__message,
.notification__inline.size--sm .show-icon .notification__title,
.notification__toast.size--sm .show-icon .notification__title,
.dropship-restriction.size--sm .show-icon .notification__title {
  padding-left: 1.2rem;
}
.notification__inline.size--sm.neutral .icon:first-of-type,
.notification__toast.size--sm.neutral .icon:first-of-type,
.dropship-restriction.size--sm.neutral .icon:first-of-type {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.neutral .icon:first-of-type > .le-icon-bg,
.notification__toast.size--sm.neutral .icon:first-of-type > .le-icon-bg,
.dropship-restriction.size--sm.neutral .icon:first-of-type > .le-icon-bg {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.neutral .icon:first-of-type > :not(.le-icon-bg),
.notification__toast.size--sm.neutral .icon:first-of-type > :not(.le-icon-bg),
.dropship-restriction.size--sm.neutral .icon:first-of-type > :not(.le-icon-bg) {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.information .icon:first-of-type,
.notification__toast.size--sm.information .icon:first-of-type,
.dropship-restriction.size--sm.information .icon:first-of-type {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.information .icon:first-of-type > .le-icon-bg,
.notification__toast.size--sm.information .icon:first-of-type > .le-icon-bg,
.dropship-restriction.size--sm.information .icon:first-of-type > .le-icon-bg {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.information .icon:first-of-type > :not(.le-icon-bg),
.notification__toast.size--sm.information .icon:first-of-type > :not(.le-icon-bg),
.dropship-restriction.size--sm.information .icon:first-of-type > :not(.le-icon-bg) {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.success .icon:first-of-type,
.notification__toast.size--sm.success .icon:first-of-type,
.dropship-restriction.size--sm.success .icon:first-of-type {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.success .icon:first-of-type > .le-icon-bg,
.notification__toast.size--sm.success .icon:first-of-type > .le-icon-bg,
.dropship-restriction.size--sm.success .icon:first-of-type > .le-icon-bg {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.success .icon:first-of-type > :not(.le-icon-bg),
.notification__toast.size--sm.success .icon:first-of-type > :not(.le-icon-bg),
.dropship-restriction.size--sm.success .icon:first-of-type > :not(.le-icon-bg) {
  height: 20px;
  width: 20px;
  scale: 0.9;
}
.notification__inline.size--sm.warning .icon:first-of-type,
.notification__toast.size--sm.warning .icon:first-of-type,
.dropship-restriction.size--sm.warning .icon:first-of-type {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.warning .icon:first-of-type > .le-icon-bg,
.notification__toast.size--sm.warning .icon:first-of-type > .le-icon-bg,
.dropship-restriction.size--sm.warning .icon:first-of-type > .le-icon-bg {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.warning .icon:first-of-type > :not(.le-icon-bg),
.notification__toast.size--sm.warning .icon:first-of-type > :not(.le-icon-bg),
.dropship-restriction.size--sm.warning .icon:first-of-type > :not(.le-icon-bg) {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.error .icon:first-of-type,
.notification__toast.size--sm.error .icon:first-of-type,
.dropship-restriction.size--sm.error .icon:first-of-type {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.error .icon:first-of-type > .le-icon-bg,
.notification__toast.size--sm.error .icon:first-of-type > .le-icon-bg,
.dropship-restriction.size--sm.error .icon:first-of-type > .le-icon-bg {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm.error .icon:first-of-type > :not(.le-icon-bg),
.notification__toast.size--sm.error .icon:first-of-type > :not(.le-icon-bg),
.dropship-restriction.size--sm.error .icon:first-of-type > :not(.le-icon-bg) {
  height: 20px;
  width: 20px;
}
.notification__inline.size--sm .notification__close,
.notification__toast.size--sm .notification__close,
.dropship-restriction.size--sm .notification__close {
  width: 20px;
  height: 20px;
}
.notification__inline.size--sm .notification__close i,
.notification__toast.size--sm .notification__close i,
.dropship-restriction.size--sm .notification__close i {
  width: 20px;
  height: 20px;
  scale: 0.9;
}
.notification__inline.size--sm .spinner,
.notification__toast.size--sm .spinner,
.dropship-restriction.size--sm .spinner {
  width: 20px;
  height: 20px;
}
.notification__inline.size--lg,
.notification__toast.size--lg,
.dropship-restriction.size--lg {
  padding: 2rem;
}
.notification__inline.size--lg .notification__message,
.notification__toast.size--lg .notification__message,
.dropship-restriction.size--lg .notification__message,
.notification__inline.size--lg .notification__title,
.notification__toast.size--lg .notification__title,
.dropship-restriction.size--lg .notification__title {
  line-height: 24px;
}
.notification__inline.size--lg .show-icon .notification__message,
.notification__toast.size--lg .show-icon .notification__message,
.dropship-restriction.size--lg .show-icon .notification__message,
.notification__inline.size--lg .show-icon .notification__title,
.notification__toast.size--lg .show-icon .notification__title,
.dropship-restriction.size--lg .show-icon .notification__title {
  padding-left: 1.6rem;
}
.notification__inline .icon,
.notification__toast .icon,
.dropship-restriction .icon {
  height: 2.4rem;
}
.notification__inline .icon:first-of-type,
.notification__toast .icon:first-of-type,
.dropship-restriction .icon:first-of-type {
  position: relative;
}
.notification__inline .icon:first-of-type > :not(.le-icon-bg),
.notification__toast .icon:first-of-type > :not(.le-icon-bg),
.dropship-restriction .icon:first-of-type > :not(.le-icon-bg) {
  position: absolute;
  left: 0;
}
.notification__inline.neutral,
.notification__toast.neutral,
.dropship-restriction.neutral {
  background-color: #f5f5f5;
  border-color: #e0e0e0;
}
.notification__inline.neutral .icon:first-of-type > .le-icon-bg,
.notification__toast.neutral .icon:first-of-type > .le-icon-bg,
.dropship-restriction.neutral .icon:first-of-type > .le-icon-bg {
  background: #474747;
}
.notification__inline.neutral .icon:first-of-type > :not(.le-icon-bg),
.notification__toast.neutral .icon:first-of-type > :not(.le-icon-bg),
.dropship-restriction.neutral .icon:first-of-type > :not(.le-icon-bg) {
  background: #f5f5f5;
}
.notification__inline.information,
.notification__toast.information,
.dropship-restriction.information {
  background-color: #eaf1fb;
  border-color: #97b7ed;
}
.notification__inline.information .icon:first-of-type > .le-icon-bg,
.notification__toast.information .icon:first-of-type > .le-icon-bg,
.dropship-restriction.information .icon:first-of-type > .le-icon-bg {
  background: #306fdb;
}
.notification__inline.information .icon:first-of-type > :not(.le-icon-bg),
.notification__toast.information .icon:first-of-type > :not(.le-icon-bg),
.dropship-restriction.information .icon:first-of-type > :not(.le-icon-bg) {
  background: #eaf1fb;
}
.notification__inline.success,
.notification__toast.success,
.dropship-restriction.success {
  background-color: #f3f9f3;
  border-color: #89c489;
}
.notification__inline.success .icon:first-of-type > .le-icon-bg,
.notification__toast.success .icon:first-of-type > .le-icon-bg,
.dropship-restriction.success .icon:first-of-type > .le-icon-bg {
  background: #f3f9f3;
}
.notification__inline.success .icon:first-of-type > :not(.le-icon-bg),
.notification__toast.success .icon:first-of-type > :not(.le-icon-bg),
.dropship-restriction.success .icon:first-of-type > :not(.le-icon-bg) {
  background: #148914;
}
.notification__inline.warning,
.notification__toast.warning,
.dropship-restriction.warning {
  background-color: #fdf5dc;
  border-color: #fbe5a7;
}
.notification__inline.warning i.notification__iconType,
.notification__toast.warning i.notification__iconType,
.dropship-restriction.warning i.notification__iconType {
  background-color: #f7cc4f;
}
.notification__inline.error,
.notification__toast.error,
.dropship-restriction.error {
  background-color: #fbe6e6;
  border-color: #ea8181;
}
.notification__inline.error .icon:first-of-type > .le-icon-bg,
.notification__toast.error .icon:first-of-type > .le-icon-bg,
.dropship-restriction.error .icon:first-of-type > .le-icon-bg {
  background: #af0a0a;
}
.notification__inline.error .icon:first-of-type > :not(.le-icon-bg),
.notification__toast.error .icon:first-of-type > :not(.le-icon-bg),
.dropship-restriction.error .icon:first-of-type > :not(.le-icon-bg) {
  background: #fbe6e6;
}
.notification__inline a,
.notification__toast a,
.dropship-restriction a {
  text-decoration: underline !important;
}
.common__progressBar {
  position: relative;
  height: 4px;
  width: 100%;
}
.common__progressBar .line {
  position: absolute;
  height: 100%;
  background-color: #f5f5f5;
}
.common__progressBar .line.fill {
  background-color: #474747;
}
.modalOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 1050;
}
.modalMenu .modalMenu__item {
  padding: 0;
  border-bottom: 0.1rem #e0e0e0 solid;
  display: flex;
  justify-content: space-between;
}
.modalMenu .modalMenu__item:active {
  border-color: #e0e0e0 !important;
}
.modalMenu .modalMenu__item.selected {
  background-color: rgba(48, 111, 219, 0.1);
  color: #306fdb !important;
}
.modalMenu .modalMenu__item.selected .check {
  display: inline-block !important;
  background-color: #306fdb;
}
.modalMenu .thule-input {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1.6rem;
}
.modalMenu .thule-input .trailing-icon {
  right: 0;
}
.modalMenu .thule-input .input {
  padding: 0 4rem 0 0;
  border: none !important;
  height: 2.6rem;
}
.right-slide-in-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100%;
  transition: transform ease-in-out 0.3s;
  z-index: 1100;
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  max-height: 100vh;
  border-left: 1px solid #e0e0e0;
}
@media screen and (min-width: 992px) {
  .right-slide-in-menu {
    min-width: 480px;
  }
}
.right-slide-in-menu.wrapper-visible {
  transform: translate(-100%, 0);
}
.right-slide-in-menu .menu-header {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
}
.right-slide-in-menu .menu-header .close-btn {
  min-height: 0;
  padding: 0;
}
@media screen and (min-width: 992px) {
  .right-slide-in-menu .menu-header .close-btn {
    margin-right: -2rem;
  }
}
.right-slide-in-menu .menu-content-section {
  overflow: auto !important;
  padding: 1.6rem 0 2rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 992px) {
  .right-slide-in-menu .menu-content-section {
    padding: 1.6rem 0;
  }
}
.right-slide-in-menu .product-section {
  display: flex;
  flex-wrap: wrap;
}
.right-slide-in-menu .confirmation {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 992px) {
  .right-slide-in-menu .confirmation {
    padding: 4rem;
  }
}
.modalContent {
  padding: 5.6rem 4rem;
  overflow: auto;
  transform: translateX(0);
  transition: transform 0.3s ease;
}
.modalContent.wrapper-visible {
  transition: transform 0.3s ease;
}
.modalContent .link a {
  text-decoration: underline;
}
.modalContent .closeBtn {
  position: absolute;
  right: 2rem;
  top: 2rem;
}
.thule-input {
  display: block;
  flex: 1 0 0;
}
.thule-input label,
.thule-input legend {
  margin-bottom: 0.4rem;
}
.thule-input .input {
  width: 100%;
  height: 4.8rem;
  padding: 1.2rem;
  border: 1px solid #e0e0e0;
  flex: 1;
  font-size: 1.6rem;
  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
}
.thule-input .input[type="password"]::-ms-reveal {
  display: none;
}
@media (prefers-reduced-motion: reduce) {
  .thule-input .input {
    transition: none;
  }
}
.thule-input .input:focus-visible {
  outline: none;
  outline-offset: unset;
  border: 1px solid #a3a3a3;
}
.thule-input .leading-icon {
  position: absolute;
  top: calc(50% - 12px);
  left: 1.2rem;
}
.thule-input .trailing-icon {
  position: absolute;
  top: calc(50% - 1.2rem);
  right: 1.2rem;
}
.thule-input .trailing-icon.error {
  background-color: #af0a0a;
}
.thule-input .trailing-icon.clear-button {
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.thule-input .trailing-icon.clear-button i {
  scale: 0.8;
}
.thule-input .supporting-text {
  padding: 0.4rem 0.8rem;
  color: #474747;
  font-size: 1.2rem;
}
.thule-input.disabled label,
.thule-input.disabled .supporting-text,
.thule-input.disabled legend {
  color: #757575;
}
.thule-input.disabled .input:disabled {
  background-color: #f5f5f5;
  border: 1px solid #bababa;
}
.thule-input.disabled .input::placeholder {
  color: #a3a3a3;
}
.thule-input.error .input {
  border: 1px solid #af0a0a;
}
.thule-input.error .supporting-text {
  color: #181818;
  background-color: #fbe6e6;
}
.thule-input.error .supporting-text a {
  text-decoration: underline;
}
.thule-input .forgot-password-link {
  color: #757575;
  text-decoration: underline;
}
.cloudflareVideo iframe {
  width: 100%;
}
.cloudflareVideo .playButton {
  position: absolute;
  top: 0;
  z-index: 2;
}
.cloudflareVideo .playButton.hide {
  visibility: hidden;
}
.cloudflareVideo .playButton .playIcon {
  background-color: #fff;
  border-radius: 5.6rem;
  filter: drop-shadow(0 4px 16px rgba(0, 0, 0, 0.1));
  height: 5.6rem;
  pointer-events: none;
  width: 5.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
/* blocks */
.layout__header {
  position: relative;
}
.layout__content {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  min-height: 10vh;
}
.layout__content img {
  max-width: 100%;
  display: block;
  height: auto;
  border: 0 none;
}
footer {
  color: white;
  box-shadow: 0 50vh 0 50vh #303030;
  position: relative;
}
footer .layout__content {
  background-color: inherit;
}
@media screen and (min-width: 992px) {
  footer .layout__content .footer-links {
    width: 180px;
  }
  footer .layout__content .footer-links:first-child {
    margin-left: 0 !important;
  }
}
footer .layout__content .footer-links .footer-links-item {
  border-bottom: 0.1rem #e0e0e0 solid;
  font-size: 1.6rem;
}
footer .layout__content .footer-links .footer-links-item .paragraph-lead {
  color: white;
}
footer .layout__content .footer-links .footer-links-item ul li {
  list-style-type: none;
}
footer .layout__content .footer-links .footer-links-item ul li a {
  color: #a3a3a3;
}
footer .layout__content .footer-links .footer-links-item button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
footer .layout__content .footer-links .footer-links-item button i {
  background-color: #fff;
}
footer .layout__content .footer-links .footer-links-item button i.le-icon-minus {
  display: none;
}
footer .layout__content .footer-links .footer-links-item button[aria-expanded='true'] > i.le-icon-minus {
  display: block;
}
footer .layout__content .footer-links .footer-links-item button[aria-expanded='true'] > i.le-icon-plus {
  display: none;
}
@media screen and (min-width: 992px) {
  footer .layout__content .footer-links .footer-links-item {
    border-bottom: none;
  }
  footer .layout__content .footer-links .footer-links-item .footer-links-item__links.collapse {
    display: block;
  }
}
@media screen and (min-width: 992px) {
  footer .layout__content .footer-social-links {
    display: flex;
    justify-content: end;
  }
}
footer .layout__content .footer-social-links .footer-social-links__icon {
  font-size: 2rem;
  color: #e0e0e0;
}
footer .layout__content .footer-social-links .footer-social-links__icon:not(:first-child) {
  margin-left: 2rem;
}
footer .layout__content .payment-method-logos-container p {
  color: #a3a3a3;
}
footer .layout__content .payment-method-logos-container .payment-method-logos {
  flex-wrap: wrap;
  column-gap: 1.2rem;
  row-gap: 1.6rem;
}
footer .layout__content .payment-method-logos-container .payment-method-logos img {
  max-height: 2rem;
}
footer .layout__content hr {
  color: #757575;
}
footer .layout__content .bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .layout__content .bottomRow * {
  color: #a3a3a3;
}
footer .layout__content .bottomRow i {
  background-color: #a3a3a3;
}
footer .layout__content .bottomRow .footer-market-selector__btn {
  display: flex;
  align-items: center;
  color: #a3a3a3;
}
@media screen and (max-width: 991px) {
  footer .layout__content .bottom-links {
    display: flex;
    flex-direction: column;
  }
  footer .layout__content .bottom-links a,
  footer .layout__content .bottom-links .cookie-settings-link {
    color: #a3a3a3;
  }
  footer .layout__content .bottom-links a:not(:last-child),
  footer .layout__content .bottom-links .cookie-settings-link:not(:last-child) {
    margin-bottom: 2rem;
  }
}
/*global*/
*::-webkit-scrollbar {
  width: 0.6rem;
}
*::-webkit-scrollbar-thumb {
  background-color: #2b2b2b;
  border-radius: 0.6rem;
}
*::-webkit-scrollbar-track {
  background-color: #eeeeee;
}
.scrollBar__1::-webkit-scrollbar {
  height: 0.4rem;
}
.scrollBar__1::-webkit-scrollbar-thumb {
  background-color: #474747;
  border-radius: 0;
}
.scrollBar__1::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollBar__2::-webkit-scrollbar {
  height: 0.3rem;
}
.scrollBar__2::-webkit-scrollbar-thumb {
  background-color: #bababa;
  border-radius: 0.6rem;
}
.scrollBar__2::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
